import CardStyle from "../_style";

const LevelRow = ({ data }) => {
  return (
    <CardStyle.HeaderContent>
      <CardStyle.HeaderText>{`Level ${data?.difficulty_level}`}</CardStyle.HeaderText>
    </CardStyle.HeaderContent>
  );
};

export default LevelRow;
