import { useCallback, useEffect, useMemo } from "react";
import moment from "moment";
import {
  PublicClientApplication,
  EventType,
  InteractionType,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Client } from "@microsoft/microsoft-graph-client";
import { microsoftClientId, microsoftTenantId } from "../../../constants";

const formatTime = (date) => {
  let formattedDate = moment.utc(date).format("YYYY-MM-DDTHH:mm:ssZ");
  return formattedDate.replace("+00:00", "");
};

export const useMicrosoftLogin = () => {
  const msal = useMsal();

  return useMemo(
    () => async () => {
      await msal.instance.loginPopup({
        scopes: ["https://graph.microsoft.com/Calendars.ReadWrite"],
      });
    },
    [msal]
  );
};

export const useMicrosoftCalendarAPI = () => {
  const msal = useMsal();

  return useCallback(
    (event, successCallback) => {
      const msalAuthProvider = new AuthCodeMSALBrowserAuthenticationProvider(
        msal.instance,
        {
          account: msal.instance.getActiveAccount(),
          scopes: ["https://graph.microsoft.com/Calendars.ReadWrite"],
          interactionType: InteractionType.Popup,
        }
      );

      const client = Client.initWithMiddleware({
        authProvider: msalAuthProvider,
      });

      const _event = {
        subject: event.summary,
        body: {
          contentType: "HTML",
          content: event.description,
        },
        start: {
          dateTime: formatTime(event.start.dateTime),
          timeZone: "Etc/GMT",
        },
        end: {
          dateTime: formatTime(event.end.dateTime),
          timeZone: "Etc/GMT",
        },
        location: {
          displayName: event.location,
        },
      };

      client
        .api("/me/calendar/events")
        .post(_event)
        .then(() => {
          successCallback?.();
        });
    },
    [msal.instance]
  );
};

export const useMsalInstance = () => {
  const msalInstance = useMemo(
    () =>
      new PublicClientApplication({
        auth: {
          authority: `https://login.microsoftonline.com/${microsoftTenantId}`,
          clientId: microsoftClientId,
          redirectUri: "http://localhost:3000",
        },
        cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: true,
        },
      }),
    []
  );

  useEffect(() => {
    const accounts = msalInstance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      msalInstance.setActiveAccount(accounts[0]);
    }

    const callbackId = msalInstance.addEventCallback((event) => {
      if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const authResult = event.payload;
        msalInstance.setActiveAccount(authResult.account);
      }
    });

    return () => {
      msalInstance.removeEventCallback(callbackId);
    };
  }, [msalInstance]);

  return msalInstance;
};
