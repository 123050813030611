import { authAxios } from "../../_utils";

const updateHomework = async ({ course_id, module_type = "courses" }) => {
  const checkHomeworkRes = await authAxios.get(
    `/homeworks/check?course_id=${course_id}`
  );

  const { data } = checkHomeworkRes;
  const { isHomework } = data;

  if (isHomework) {
    const submitData = {
      module: module_type,
      module_id: course_id,
      status: "complete",
    };

    await authAxios.post(`/homeworks/update`, submitData);
    return true;
  }
  return false;
};

export default updateHomework;
