import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";
import { Separator } from "../../_components";
import NavLink from "./NavLink";
import { doLogoutAsync } from "../../_redux/actions/auth/doLogoutAsync";
import { useGetMenuItems } from "./_utils";

const UserMenuMobile = ({ show, setShow, height, doLogout }) => {
  const [showClasses, setShowClasses] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [showProgram, setShowProgram] = useState(false);
  const classItems = useGetMenuItems("classes");
  const mainItems = useGetMenuItems("main");
  const profileItems = useGetMenuItems("profile");
  const programItems = useGetMenuItems("program");

  useEffect(() => {
    if (!show) {
      setShowClasses(false);
    }
  }, [show]);

  const menuItemClick = (item) => {
    if (!["classes", "user-outline", "program"].includes(item.icon))
      return handleLeavingNav();
    if (item.icon === "classes") return setShowClasses(true);
    if (item.icon === "program") return setShowProgram(true);
    if (item.icon === "user-outline") return setShowProfile(true);
  };

  const backButtonClick = () => {
    setShowClasses(false);
    setShowProfile(false);
    setShowProgram(false);
  };

  const handleLeavingNav = () => {
    setShow(false);
    setShowClasses(false);
    setShowProfile(false);
    setShowProgram(false);
  };
  return (
    <MenuWrapper $show={show} $height={height}>
      <NavWrapper>
        {!showClasses &&
          !showProfile &&
          !showProgram &&
          mainItems?.map((item, index) => {
            if (item.hide) return null;
            return (
              <Fragment key={`profile-menu-mobile-${index}`}>
                <NavLink
                  mobile
                  key={item.icon}
                  name={item.icon}
                  label={item.label}
                  linkTo={item.link}
                  showMore={item.showMore}
                  type={item.link ? "link" : null}
                  onClick={() => menuItemClick(item)}
                  parentFrom={"user_mobile_dropdown"}
                />
              </Fragment>
            );
          })}
        {(showClasses || showProfile || showProgram) && (
          <BackButtonWrapper>
            <NavLink
              mobile
              key={"back"}
              name="chevron-left-circle"
              label="Back"
              type={null}
              onClick={backButtonClick}
            />
          </BackButtonWrapper>
        )}
        {showClasses &&
          classItems?.map((classItem, index) => {
            return (
              <NavLink
                key={classItem.link || index}
                mobile
                label={classItem.label}
                linkTo={classItem.link}
                type="link"
                onClick={handleLeavingNav}
                parentFrom={"user_mobile_dropdown"}
              />
            );
          })}
        {showProgram &&
          programItems?.map((programItem, index) => {
            return (
              <NavLink
                key={programItem.link || index}
                mobile
                label={programItem.label}
                linkTo={programItem.link}
                type="link"
                onClick={handleLeavingNav}
                parentFrom={"user_mobile_dropdown"}
              />
            );
          })}
        {showProfile && (
          <>
            {profileItems?.map((item, index) => {
              return item?.hide ? null : item?.line ? (
                <Separator
                  centered
                  $width={"100%"}
                  $my={1}
                  key={`profile-mobile-menu-${index}`}
                />
              ) : (
                <NavLink
                  key={item.link || index}
                  mobile
                  label={item.label}
                  linkTo={item.link}
                  type="link"
                  onClick={handleLeavingNav}
                  parentFrom={"user_mobile_dropdown"}
                />
              );
            })}
            <NavLink key={"logout"} mobile label="Logout" onClick={doLogout} />
          </>
        )}
      </NavWrapper>
    </MenuWrapper>
  );
};

const MenuWrapper = styled.div`
  position: fixed;
  top: ${({ $height }) => $height}px;
  right: ${({ $show }) => ($show ? "0" : "-105vw")};
  bottom: 0;
  width: 100vw;
  background-color: ${(props) => props.theme.palette.neutralWhite};
  z-index: 1045;
  overflow: auto;
  opacity: ${({ $show }) => ($show ? 1 : 0)};
  padding: 10px 30px;
  transition: all 0.2s, width 0.2s;
  transition-timing-function: ease;
`;

const NavWrapper = styled(Nav)`
  background-color: ${(props) => props.theme.palette.neutralWhite};
  padding: 20px;
  display: flex;
  list-style-type: none;
  flex-direction: column;
  overflow-y: auto;
`;

const BackButtonWrapper = styled.div`
  margin-left: -10px !important;
`;

export default connect(null, { doLogout: doLogoutAsync })(UserMenuMobile);
