import CardBase from "./CardBase";
import { Heading5, Paragraph1 } from "../Typography";
import { Col, Row } from "../Layout";

const LocationCard = ({ location, ...rest }) => {
  return (
    <CardBase forceRadius image={"None"} {...rest}>
      <Row $px={4} $py={4} justify={"center"} $align="center">
        <Col xs={12}>
          <Heading5 weight={700}>{location.title}</Heading5>
          {location.address1 && (
            <Paragraph1>
              {location.address1} {location.address2}
            </Paragraph1>
          )}
          <Paragraph1>
            {location.city}, {location.state} {location.zip}
          </Paragraph1>
        </Col>
      </Row>
    </CardBase>
  );
};

export default LocationCard;
