import { Row, Paragraph2 } from "../..";
import { ROUTES } from "../../../_routes";
import { history } from "../../../_utils";

const LeaveReview = ({ data }) => {
  const handleReviewRedirect = (e) => {
    history.push(`${ROUTES.COURSE}/${data.id}${ROUTES.LEAVE_REVIEW}`);
    e?.stopPropagation();
  };

  return (
    <Row $justify="space-between" $align="center" $mt={2}>
      {data?.type !== "live" && parseInt(data?.rating_stars) === 0 && (
        <Paragraph2 onClick={handleReviewRedirect} color="grey3">
          Be the first to leave a review!
        </Paragraph2>
      )}
    </Row>
  );
};

export default LeaveReview;
