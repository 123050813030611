import { memo, useContext, useMemo } from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import { Redirect } from "react-router-dom";

import { ROUTES } from "../_routes";
import Sidenav from "../PSS/Shell/Sidenav";
import Footer from "./Shell/Footer";
import useIsMounted from "../_hooks/useIsMounted";
import { Container, PageLoader, Row } from "../_components";
import { UserContext } from "../_context/UserContext";

export const AuthPssLayout = ({ children }) => {
  const isMounted = useIsMounted();
  const { userData, loading } = useContext(UserContext);
  const {
    isOwner,
    isAdmin,
    isInstructor,
    isManager,
    isDirectUser,
    is_active: isActive,
  } = userData;

  const isAllowedInPss = useMemo(() => {
    return (
      !isDirectUser &&
      (isAdmin || isOwner || isManager || isInstructor) &&
      isActive
    );
  }, [isActive, isAdmin, isDirectUser, isInstructor, isManager, isOwner]);
  const sendToUserDashboard = useMemo(() => isDirectUser && isActive, [
    isDirectUser,
    isActive,
  ]);

  if (!isMounted) return null;
  return loading ? (
    <PageLoader height="100vh" />
  ) : isAllowedInPss ? (
    <>
      <Helmet titleTemplate="%s | Burnalong Partner Self Service" />
      <Container fluid>
        <MainSectionWrapper>
          <Sidenav />
          <Main fluid>{children}</Main>
        </MainSectionWrapper>
        <Footer />
      </Container>
    </>
  ) : sendToUserDashboard ? (
    <Redirect to={ROUTES.DASHBOARD} />
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

const MainSectionWrapper = styled(Row)`
  height: 100% !important;
`;

const Main = styled(Container)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.neutralGrey};
  flex: 1 1;
  @media (max-width: 768px) {
    padding-left: 3rem;
  }
`;

export default memo(AuthPssLayout);
