import { Heading2, Heading5 } from "../../Typography";

const CardTitle = ({ title, type, mb, onClick }) => {
  if (!title) return null;
  if (type === "left-right") {
    return (
      <Heading2
        weight={"700"}
        $mt={2}
        $mb={mb}
        $ellipsis
        $amt={2}
        $clampHeight="2.6em"
        title={title}
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onClick && onClick();
          }
        }}
      >
        {title}
      </Heading2>
    );
  }
  return (
    <Heading5
      weight={"700"}
      $mt={2}
      $mb={mb}
      $ellipsis
      $amt={2}
      $clampHeight="2.6em"
      title={title}
      tabIndex={0}
      onKeyPress={(event) => {
        if (event.key === "Enter") {
          onClick && onClick();
        }
      }}
    >
      {title}
    </Heading5>
  );
};

export default CardTitle;
