import { useCallback, useState, useMemo } from "react";
import styled from "styled-components";
import Modal from "../../_components/Modal";
import Assessment from "./Assessment";

const StyledAssessmentContainer = styled.div`
  max-height: 70vh;
  overflow: auto;
`;

const AssessmentModal = ({
  open,
  isLast,
  assessment = {},
  onComplete,
  submitting,
}) => {
  const { questions = [] } = assessment;
  const [selectedValues, onChangeSelectedValues] = useState([]);
  const [questionsAnswers, setQuestionAnswers] = useState({});

  const onUpdate = useCallback((questionId, values) => {
    const responses = values.map((v) => ({ answer_id: v.id || v }));
    setQuestionAnswers((v) => ({
      ...v,
      [questionId]: values,
    }));
    onChangeSelectedValues((v) => ({
      ...v,
      ...responses,
    }));
  }, []);

  const skippable = useMemo(() => {
    return assessment?.skippable;
    // if (assessment?.skippable) {
    //   return true;
    // }
    // return assessment?.questions?.every((q) => !q?.required);
  }, [assessment]);

  const answeredAllRequired = useMemo(() => {
    if (assessment?.skippable) {
      return true;
    }
    return questions?.every((q) => {
      return (questionsAnswers[q.id] || []).length > 0;
    });
  }, [assessment, questions, questionsAnswers]);

  const skipProps = skippable
    ? {
        onCancel: () => onComplete([]),
        cancelText: "Skip",
      }
    : {};

  return (
    <Modal
      show={open}
      loading={submitting}
      acceptText={isLast ? "Submit" : "Continue"}
      disabled={!answeredAllRequired}
      onAccept={() => onComplete(selectedValues)}
      large
      {...skipProps}
    >
      <StyledAssessmentContainer>
        {questions.map((question) => (
          <Assessment
            key={question.id}
            assessment={assessment}
            question={question}
            onChangeSelectedValues={onUpdate}
          />
        ))}
      </StyledAssessmentContainer>
    </Modal>
  );
};

export default AssessmentModal;
