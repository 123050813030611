import styled from "styled-components";
import { Button, Col, Paragraph1 } from "../../_components";

const GroupedQuestion = ({
  layout = "horizontal_scale",
  values = [],
  selectedValues = [],
  onClickValue = () => {},
}) => {
  const subtext = values.find((v) => !!v.subtext)?.subtext;
  const commonTitle = values.find((v) => !!v.title)?.title;

  return (
    <StyledGroupedQuestionsContainer layout={layout}>
      <StyledQuestions layout={layout}>
        {values.map((v) => {
          const title = v.title;
          return (
            <StyledQuestion key={v.id} layout={layout}>
              {layout === "horizontal_scale" && (
                <StyledQuestionTitle layout={layout} textAlign="center">
                  {title}
                </StyledQuestionTitle>
              )}
              <StyledQuestionButton
                bType={
                  selectedValues.find((i) => i === v.id) ? "filled" : "outline"
                }
                layout={layout}
                onClick={() => onClickValue(v.id)}
              >
                {v.value}
              </StyledQuestionButton>
            </StyledQuestion>
          );
        })}
      </StyledQuestions>
      <Col
        align={layout === "horizontal_scale" ? "center" : "left"}
        justify="center"
      >
        {layout === "vertical_scale" && (
          <StyledQuestionTitle layout={layout}>
            {commonTitle}
          </StyledQuestionTitle>
        )}
        <StyledQuestionSubtext>{subtext}</StyledQuestionSubtext>
      </Col>
    </StyledGroupedQuestionsContainer>
  );
};

const StyledQuestionTitle = styled(Paragraph1)`
  font-size: 14px;
  ${({ layout }) => {
    if (layout === "horizontal_scale") {
      return `
        overflow: hidden;
        min-height: 48px;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      `;
    } else {
      return `
        font-weight: 700;
        margin-bottom: 8px;
      `;
    }
  }}
`;

const StyledQuestionSubtext = styled(Paragraph1)`
  font-size: 12px;
`;

const StyledQuestionButton = styled(Button)`
  ${({ layout }) => {
    if (layout === "vertical_scale") {
      return `
        width: 230px;
      `;
    }
  }}
`;

const StyledQuestion = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledQuestions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ layout }) => {
    if (layout === "horizontal_scale") {
      return `
        flex-direction: row;
        gap: 12px;
      `;
    } else if (layout === "vertical_scale") {
      return `
        flex-direction: column;
        gap: 8px;
      `;
    } else {
      return `
        flex-direction: row;
        gap: 12px;
      `;
    }
  }}
`;

const StyledGroupedQuestionsContainer = styled.div`
  display: flex;
  ${({ layout }) => {
    if (layout === "horizontal_scale") {
      return `
        flex-direction: column;
        gap: 12px;
      `;
    } else if (layout === "vertical_scale") {
      return `
        flex-direction: row;
        gap: 18px;
        padding-bottom: 8px;
        border-bottom: 1px solid;
      `;
    } else {
      return `
        flex-direction: row;
      `;
    }
  }}
`;

export { GroupedQuestion };
