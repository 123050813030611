import axios from "axios";
import history from "../../../_utils/history";
import { ROUTES } from "../../../_routes";
import { LOGOUT } from "../../reducers/auth/login";
import { apiUrl, headers } from "../../../constants";
import getToken from "../../../_utils/bearerToken";

const logout = () => ({ type: LOGOUT });

export const doLogoutAsync = () => {
  const bearerToken = getToken();
  return (dispatch) => {
    axios
      .get(`${apiUrl}/users/logout`, {
        headers: { ...headers, ...bearerToken },
      })
      .finally(() => {
        dispatch(logout());
        history.push(ROUTES.LOGIN);
      });
  };
};
export default doLogoutAsync;
