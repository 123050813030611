import React, { useState, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import Button from "../Buttons";
import { Heading5 } from "../Typography";
import getID from "../../_utils/getID";

const StyledCard = styled.div`
  background: ${({ theme }) => theme.palette.neutralWhite};
  filter: drop-shadow(0px 8px 12px rgba(0, 0, 0, 0.12));
  border-radius: ${({ theme }) => theme.components.card.borderRadius}px;
  width: ${({ width }) => width};
  flex-direction:${({ type }) => (type === "left-right" ? "row" : "column")};
  display: flex;

  /* transform-style: preserve-3d;
  transition: transform 1s;
  transform: ${({ flipped }) => (flipped ? "rotateY(180deg)" : "")}; */
`;

const ImageContent = styled.div`
  position: relative;
`;

const ImageOverlay = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.components.card.spacing}px;
  right: ${({ theme }) => theme.components.card.spacing}px;
  /* width: calc(100% - 16px); */
`;

const Image = styled.img`
  width: ${({ width, type }) => (type === "top-down" ? width : "auto")};
  height: ${({ height, type }) => (type === "left-right" ? height : "auto")};
  border-top-left-radius: ${({ theme, type, imageLocation }) =>
    imageLocation !== "right" ? theme.components.card.borderRadius : 0}px;
  border-top-right-radius: ${({ theme, type, imageLocation }) =>
    type === "top-down" || imageLocation === "right"
      ? theme.components.card.borderRadius
      : 0}px;
  border-bottom-left-radius: ${({ theme, type, imageLocation }) =>
    type === "left-right" && imageLocation === "left"
      ? theme.components.card.borderRadius
      : 0}px;
  border-bottom-right-radius: ${({ theme, type, imageLocation }) =>
    imageLocation === "right" ? theme.components.card.borderRadius : 0}px;
  object-fit: cover;
  object-position: top;
`;

const TitleContent = styled(Heading5)`
  color: ${({ theme }) => theme.palette.primaryBlue};
  font-weight: 700;
  margin-top: ${({ theme }) => theme.components.card.spacing}px;
  margin-bottom: ${({ theme }) => theme.components.card.spacing}px;
  // limit to two lines
  overflow: hidden;
  text-overflow: ellipsis;
  /* display: -webkit-box;
  -webkit-line-clamp: 2; 
  -webkit-box-orient: vertical; */
`;

const BodyContent = styled.div`
  padding: ${({ theme }) => theme.components.card.padding}px;
  width: ${({ width }) => width};
`;

const Body = styled.div``;

const HeaderContent = styled.div``;

const BookmarkContent = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const Card = ({
  children,
  type = "top-down",
  imageLocation = "left",
  image = process.env.PUBLIC_URL + "/images/card-image.png",
  width = "18rem",
  height = "18rem",
  title = "Title",
  flipped = false,
  overlay = false,
  overlayContent = null,
  headerContent = null,
  bookmark = false,
  showBookmark = false,
  onClickBookmark = () => {},
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const [isFlipped, setFlipeed] = useState(flipped);

  const handleClick = () => {
    setFlipeed(!isFlipped);
  };

  const handleBookmark = () => {
    onClickBookmark(!bookmark);
  };

  return (
    <StyledCard flipped={isFlipped} type={type} onClick={handleClick}>
      {image && imageLocation === "left" && (
        <ImageContent>
          <Image
            type={type}
            src={image}
            width={width}
            height={height}
            alt="Course Image"
          />
          {overlay && <ImageOverlay>{overlayContent}</ImageOverlay>}
          {showBookmark && (
            <BookmarkContent>
              <Button
                onClick={handleBookmark}
                margin={0}
                bType={"text"}
                dark
                iconColor={themeContext.palette.neutralWhite}
                leftIcon={bookmark ? "bookmark" : "bookmark-outline"}
              />
            </BookmarkContent>
          )}
        </ImageContent>
      )}
      <BodyContent type={type} width={width}>
        {headerContent && <HeaderContent>{headerContent}</HeaderContent>}
        {title && (
          <TitleContent id={`card-title-${getID(title)}`}>{title}</TitleContent>
        )}
        <Body>{children}</Body>
      </BodyContent>
      {image && imageLocation === "right" && (
        <ImageContent>
          <Image
            type={type}
            src={image}
            alt="Image"
            width={width}
            height={height}
            imageLocation={imageLocation}
          />
          {overlay && <ImageOverlay>{overlayContent}</ImageOverlay>}
          {showBookmark && (
            <BookmarkContent>
              <Button
                onClick={handleBookmark}
                margin={0}
                type={"text"}
                dark
                iconColor={themeContext.palette.neutralWhite}
                leftIcon={bookmark ? "bookmark" : "bookmark-outline"}
              />
            </BookmarkContent>
          )}
        </ImageContent>
      )}
    </StyledCard>
  );
};

export const CardWrapper = ({ width = "auto", children, ...props }) => {
  return <StyledCard {...props}>{children}</StyledCard>;
};

export default Card;
