import { useEffect, useState } from "react";
import { apiUrl, headers } from "../constants";
import axios from "axios";

const useConsents = (code) => {
  const [consents, setConsents] = useState();
  const [isFetchingConsents, setIsFetchingConsents] = useState(false);

  useEffect(() => {
    setIsFetchingConsents(true);
    axios
      .get(`${apiUrl}/consents/organization/${code}/`, {
        headers: { ...headers },
      })
      .then((response) => {
        setConsents(response?.data || []);
      })
      .finally(() => {
        setIsFetchingConsents(false);
      });
  }, [code]);

  return [consents, isFetchingConsents];
};

export default useConsents;
