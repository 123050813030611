import styled from "styled-components";
import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Link as LK } from "react-router-dom";
import {
  noMargin,
  noPadding,
  ellipsisOptional,
  lineClampOptional,
} from "../_theme/general";
import { baColor } from "../_utils/color";

const Text = styled.div`
  ${noMargin}
  ${noPadding}
  ${ellipsisOptional}
  ${lineClampOptional}
  text-decoration: ${({ $textDecoration }) =>
    $textDecoration ? $textDecoration : "none"};
  font-size: ${(props) => props.$fontSize}px;
  font-weight: ${(props) => props.$fontWeight};
  line-height: ${(props) => props.$lineHeight}px;
  text-transform: ${({ $textTransform }) => $textTransform};
  color: ${(props) => props.$color};
  text-align: ${(props) => props.$textAlign};
  font-family: "DM Sans", sans-serif;
  text-transform:  ${(props) =>
    props.$textTransform && `${props.$textTransform} !important`};
  overflow-wrap: ${({ $overFlow }) => $overFlow};
  white-space: ${({ $whiteSpace }) => $whiteSpace};
  cursor: ${({ $cursor }) => $cursor};
  &:hover {
    color: ${({ onClick, theme }) => onClick && theme.palette.hoverPrimary};
    cursor: ${({ onClick }) => onClick && "pointer"};
  }
`;

export const BAText = ({
  children,
  variant = "paragraph",
  weight = 400,
  level = 1,
  color = "primary",
  textAlign = "left",
  cursor = "default",
  overFlow,
  lineHeight,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const fontMap = theme.fonts[variant + level];
  return (
    <Text
      $fontSize={fontMap.fontSize}
      $fontWeight={weight}
      $lineHeight={lineHeight || fontMap.lineHeight}
      $color={baColor(color, theme)}
      $textAlign={textAlign}
      $overFlow={overFlow}
      $cursor={cursor}
      {...props}
    >
      {children}
    </Text>
  );
};

// Displays
export const Display1 = (props) => (
  <BAText variant="display" level={1} {...props} />
);

export const Display2 = (props) => (
  <BAText variant="display" level={2} {...props} />
);

// Headings
export const Heading1 = (props) => (
  <BAText variant="heading" level={1} {...props} />
);

export const Heading2 = (props) => (
  <BAText variant="heading" level={2} {...props} />
);

export const Heading3 = (props) => (
  <BAText variant="heading" level={3} {...props} />
);

export const Heading4 = (props) => (
  <BAText variant="heading" level={4} {...props} />
);

export const Heading5 = (props) => (
  <BAText variant="heading" level={5} {...props} />
);

export const Heading6 = (props) => (
  <BAText variant="heading" level={6} {...props} />
);

// Paragraphs
export const Paragraph1 = (props) => (
  <BAText variant="paragraph" level={1} {...props} />
);

export const Paragraph2 = (props) => (
  <BAText variant="paragraph" level={2} {...props} />
);

export const Paragraph3 = (props) => (
  <BAText variant="paragraph" level={3} {...props} />
);

export const Paragraph4 = (props) => (
  <BAText variant="paragraph" level={4} {...props} />
);

// Links
const LinkBase = styled(LK)`
  text-decoration: ${({ $textDecoration }) =>
    $textDecoration ? $textDecoration : "none"};
  font-size: ${({ $size }) => $size || "16px"};
  font-weight: ${({ $weight }) => $weight || "400"};
  line-height: 22px;
  color: ${({ theme, $color }) => $color || theme.palette.primaryBlue};
  text-align: ${(props) => props.textAlign};
  ${noMargin}
  ${noPadding}
  &:hover {
    text-decoration: ${({ $textDecoration }) =>
      $textDecoration ? $textDecoration : "none"};
    color: ${({ theme }) => theme.palette.hoverPrimary};
  }
`;

export const Link = (props) => <LinkBase {...props} />;
