import { useEffect, useState } from "react";
import useAuthAxios from "./useAuthAxios";

const useGetUserContacts = (
  page = 1,
  term = "",
  pageSize = 12,
  activeOnly = true
) => {
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(true);

  const url = activeOnly
    ? `contacts/user/search?page=${page}&page_size=${pageSize}&term=${encodeURIComponent(
        term
      )}`
    : `contacts/user?page=${page}&page_size=${pageSize}`;

  const [{ data, loading: loadingPayload }] = useAuthAxios(url, {
    useCache: false,
  });

  useEffect(() => {
    if (data && data?.items) {
      const cleanContacts = activeOnly
        ? data.items.reduce((acc, current) => {
            if (current?.contact?.is_active) acc.push(current.contact);
            return acc;
          }, [])
        : data.items.map((contact) => contact?.contact || contact);
      setContacts(cleanContacts);
      setPageCount(data?.meta?.page_count);
    }
    setLoading(false);
  }, [data, activeOnly]);

  return { contacts, loading: loadingPayload || loading, pageCount };
};

export default useGetUserContacts;
