export const setFormDynamicFields = (dynamicFields, formData, customPlanID) => {
  let data = {};
  dynamicFields.map((field) => {
    if (field.field_type === "select") {
      let fieldName = field.field_name;
      data[fieldName] = formData[fieldName]["value"];
    } else if (
      field.field_type === "hidden" &&
      field.field_name === "plan_id" &&
      customPlanID
    ) {
      let fieldName = field.field_name;
      data[fieldName] = customPlanID;
    } else if (field.field_type === "checkbox") {
      let value =
        formData[field.field_name] || formData[field.field_name] === "true"
          ? true
          : false;
      data[field.field_name] = value;
    }
    return data;
  });
  return data;
};

export const handleQueryDefaultValue = (
  defaultValues,
  valueForBreakIndex = "-"
) => {
  return defaultValues?.map((answer) => {
    let breakIndex = answer.indexOf(valueForBreakIndex);
    return {
      field_name: answer.substring(0, breakIndex),
      defaultValue: answer.substring(breakIndex + 1),
    };
  });
};

export const setDynamicFieldSelectOptions = (field) => {
  if (field.field_values) {
    let options = field.field_values.replaceAll(", ", ",");
    options = options.split(",");
    let array_options = options.map((option) => ({
      value: option,
      label: option,
    }));
    return array_options;
  }
};
