import { Col, Row } from "../Layout";
import { ClassCard } from "../_cards";
import BaseSkeleton from "../Skeletons/BaseSkeleton";
import Pagination from "../../_components/Pagination";
import { useMemo, useState } from "react";
import { playlist_pagesize } from "../../Playlist/_constants";

const CourseList = ({
  courses = [],
  loading,
  page = null,
  setPage,
  pageCount,
  parentPage,
  noSchedule = false,
  hideFavorite = false,
  ...rest
}) => {
  const [pageSize, setPageSize] = useState(Number(playlist_pagesize));
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(courses.length / pageSize);
  const pageContent = useMemo(
    () => courses?.slice(pageSize * (currentPage - 1), pageSize * currentPage),
    [courses, currentPage, pageSize]
  );

  return (
    <>
      <Row $mb={4}>
        {loading ? (
          [...Array(6)].map((_, i) => {
            return (
              <Col key={i} xs={12} sm={6} lg={4}>
                <BaseSkeleton card overlay height="24em" $my={4} />
              </Col>
            );
          })
        ) : pageContent?.length > 0 ? (
          pageContent.map((course) => {
            return (
              <Col
                key={course.id}
                xs={12}
                sm={6}
                lg={4}
                $mb={10}
                $justify={"space-between"}
              >
                <ClassCard
                  data={course}
                  parentPage={parentPage}
                  noSchedule={noSchedule}
                  hideFavorite={hideFavorite}
                  playlistTitle={rest.playlistTitle}
                />
              </Col>
            );
          })
        ) : (
          <Col $px={6}>Couldn't find anything to show you here.</Col>
        )}
      </Row>

      {!loading && totalPages > 1 && (
        <Pagination
          activePage={page}
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={2}
        />
      )}
    </>
  );
};

export default CourseList;
