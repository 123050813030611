import { useState, useEffect, useMemo } from "react";

// Check if ref is visible on screen
// reference: https://stackoverflow.com/questions/45514676/react-check-if-element-is-visible-in-dom
const useOnScreen = (ref) => {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIntersecting(entry.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    if (ref && ref?.current) {
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [observer, ref]);
  return isIntersecting;
};

export default useOnScreen;
