export const categories = {
  11: {
    icon: "strength",
    title: "Strength",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  12: {
    icon: "barre",
    title: "Barre",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  13: {
    icon: "yoga",
    title: "Yoga",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  14: {
    icon: "bootcamp",
    title: "Bootcamp",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  15: {
    icon: "educational",
    title: "Educational",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  17: {
    icon: "cardio",
    title: "Cardio",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  19: {
    icon: "",
    title: "Other",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  28: {
    icon: "boxing",
    title: "Boxing",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  30: {
    icon: "stretch",
    title: "Stretch and Restore",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  31: {
    icon: "bodyweight",
    title: "Body Weight",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  37: {
    icon: "prenatal",
    title: "Prenatal/Postnatal",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  38: {
    icon: "arms",
    title: "Arms",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  39: {
    icon: "meditation",
    title: "Meditation",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  46: {
    icon: "abs",
    title: "Abs",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  47: {
    icon: "dance",
    title: "Dance",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  48: {
    icon: "nutrition",
    title: "Nutrition",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  49: {
    icon: "cycle",
    title: "Cycle",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  50: {
    icon: "pilates",
    title: "Pilates",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  51: {
    icon: "fit-over",
    title: "Fit Over 50",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  52: {
    icon: "youth",
    title: "Youth",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  53: {
    icon: "intro-videos",
    title: "Intro Videos",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  54: {
    icon: "glutes",
    title: "Glutes",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  55: {
    icon: "starters",
    title: "Starters",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  56: {
    icon: "mindfulness",
    title: "Mindfullness",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  57: {
    icon: "pt",
    title: "Physical Therapy",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  58: {
    icon: "parkinsons",
    title: "Parkinsons",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  59: {
    icon: "parenting",
    title: "Parenting",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  60: {
    icon: "self-defense",
    title: "Self Defense",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  61: {
    icon: "office",
    title: "Office Workout",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  62: {
    icon: "life-coaching",
    title: "Life Coaching",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  63: {
    icon: "financial",
    title: "Financial Wellbeing",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  64: {
    icon: "sleep",
    title: "Sleep",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  65: {
    icon: "stress",
    title: "Stress Management",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  66: {
    icon: "dog",
    title: "Dog Workouts",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  67: {
    icon: "burnalong",
    title: "BurnAlong",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  68: {
    icon: "mental-health",
    title: "Mental Health",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  69: {
    icon: "travel",
    title: "Travel",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  70: {
    icon: "arthritis",
    title: "Arthritis",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  71: {
    icon: "adaptive",
    title: "Adaptive Workouts",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  72: {
    icon: "diabetes",
    title: "Diabetes",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  73: {
    icon: "sports-performance",
    title: "Sports Performance",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  74: {
    icon: "cancer",
    title: "Cancer Wellness",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  75: {
    icon: "seniors",
    title: "Seniors",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  76: {
    icon: "summer-camp",
    title: "Summer Camp",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  77: {
    icon: "tai-chi",
    title: "Tai Chi & QiGong",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  78: {
    icon: "wellness-view",
    title: "Wellness With a View",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  79: {
    icon: "take-5",
    title: "Take 5",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  87: {
    icon: "running",
    title: "Running",
    heading: "Traditional",
    bgColor: "#002A68",
    bgColorHover: "#002357",
    iconColor: "#00DDFF",
    iconColorHover: "#00ceed",
  },
  88: {
    icon: "walking",
    title: "Walking",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
  89: {
    icon: "ms",
    title: "Multiple Sclerosis",
    heading: "Medical",
    bgColor: "#004039",
    bgColorHover: "#00332D",
    iconColor: "#51FF00",
    iconColorHover: "#4CED00",
  },
  90: {
    icon: "",
    title: "Spanish",
    heading: "Speciality",
    bgColor: "#6A0040",
    bgColorHover: "#5C0037",
    iconColor: "#FFB800",
    iconColorHover: "#EDAB00",
  },
};

export const categoryLevels = {
  sm: 1,
  md: 2,
  lg: 3,
  xlg: 4,
};

export const zones = {
  2: {
    title: "Abs",
  },
  3: {
    title: "Arms",
  },
  4: {
    title: "Legs",
  },
  5: {
    title: "Full Body",
  },
  6: {
    title: "Neck and Shoulder",
  },
  7: {
    title: "Glutes",
  },
  9: {
    title: "Back",
  },
  10: {
    title: "Chest",
  },
  11: {
    title: "Knees",
  },
  12: {
    title: "Wrist",
  },
  13: {
    title: "Upper Body",
  },
  14: {
    title: "Lower Body",
  },
};

export const wellnessHabits = {
  1: {
    title: "Be grateful",
    heading: "Healthy mind",
    iconColor: "#004039",
    completedColor: "#51FF00",
  },
  2: {
    title: "Bring lunch to work",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  3: {
    title: "Cook at home",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  4: {
    title: "Cycle",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  5: {
    title: "Drink water",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  6: {
    title: "Eat fish",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  7: {
    title: "Eat fruits & vegetables",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  8: {
    title: "Eat salad",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  9: {
    title: "Floss",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  10: {
    title: "Go outside",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  11: {
    title: "Journal",
    heading: "Healthy mind",
    iconColor: "#004039",
    completedColor: "#51FF00",
  },
  12: {
    title: "No fast food",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  13: {
    title: "No meat",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  14: {
    title: "No screen time before bed",
    heading: "Healthy mind",
    iconColor: "#004039",
    completedColor: "#51FF00",
  },
  15: {
    title: "Read",
    heading: "Healthy mind",
    iconColor: "#004039",
    completedColor: "#51FF00",
  },
  16: {
    title: "Run",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  17: {
    title: "Say hello to strangers",
    heading: "Social habits",
    iconColor: "#6A0040",
    completedColor: "#5F006A",
  },
  18: {
    title: "Skip desserts",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  19: {
    title: "Skip soda",
    heading: "Food habits",
    iconColor: "#5A0058",
    completedColor: "#EEFF41",
  },
  20: {
    title: "Sleep 7-8 hours",
    heading: "Healthy mind",
    iconColor: "#004039",
    completedColor: "#51FF00",
  },
  21: {
    title: "Spend time with family",
    heading: "Social habits",
    iconColor: "#6A0040",
    completedColor: "#5F006A",
  },
  22: {
    title: "Spend time with kids",
    heading: "Social habits",
    iconColor: "#6A0040",
    completedColor: "#5F006A",
  },
  23: {
    title: "Stand up & stretch",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  24: {
    title: "Swim",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  25: {
    title: "Take the stairs",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  26: {
    title: "Walk",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
  27: {
    title: "Workout",
    heading: "Physical activities",
    iconColor: "#002A68",
    completedColor: "#00DDFF",
  },
};

export const sortOrderOptions = [
  { value: "most_popular", label: "Most Popular" },
  { value: "alphabetical_az", label: "Alphabetical A-Z" },
  { value: "alphabetical_za", label: "Alphabetical Z-A" },
  { value: "newest", label: "Newest" },
  { value: "oldest", label: "Oldest" },
];
