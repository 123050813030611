export const daysPerWeek = (data) => {
  const freqArray = data?.frequency ? data?.frequency?.split(",") : [];
  const days = `${freqArray.length} ${freqArray.length > 1 ? "days" : "day"}`;
  const wAmt = freqArray.length
    ? Math.ceil(data?.duration / freqArray.length)
    : 0;
  const weeks = `${wAmt} ${wAmt > 1 ? "weeks" : "week"}`;
  return `${days} / ${weeks}`;
};

export const programCardDetails = {
  id: 46,
  title: "The BurnAlong Challenge!",
  body:
    "Welcome to the BurnAlong Challenge! We are happy you are here! \r\n\r\nThis challenge will give you a taste for all BurnAlong has to offer and most classes are short, under 20 minutes. The challenge is designed for all fitness levels and modifications are included. \r\n\r\nHOW TO EARN POINT:\r\n\r\n- Complete the Challenge - \r\n- Fill out your profile - \r\n- Enroll in wellness habits - \r\n- Take group classes - \r\n\r\nIf you have any questions please email info@burnalong.com\r\n\r\nGet started today! ",
  duration: 91,
  difficulty_level: 2,
  frequency: "1,3,5,7",
  file_poster:
    "https://bapy-dev-storage.s3.amazonaws.com/media/programs/Generic-Program-Banner.jpg",
  instructor: {
    id: 22444,
    first_name: "Alex",
    last_name: "Haschen",
    profile: {
      nickname: "Alex H. 7",
      file_avatar:
        "https://bapy-dev-storage.s3.amazonaws.com/media/avatar/Qu1p8a.png",
      organization: {
        id: 3089,
        title: "Alex Haschen Wellness",
        show_banner: false,
        features: "programs, communities, challenges",
        subuser_limit: 4,
      },
      status: "active",
    },
  },
  category: [
    {
      id: 46,
      title: "Abs",
      file_image:
        "https://bapy-dev-storage.s3.amazonaws.com/media/programs/Generic-Program-Banner.jpg",
      enable: "disable",
      status: "active",
    },
  ],
  equipment: [
    {
      id: 27,
      title: "Axion Trainer",
      body: "",
      file: null,
      status: "active",
    },
  ],
  status: "active",
  complete: 10,
};

export const vodCardDetails = {
  id: 11716,
  instructor: {
    id: 120667,
    first_name: "Monte",
    last_name: "Sanders",
  },
  categories: [
    {
      id: 55,
      title: "Starters",
      file_image:
        "https://bapy-dev-storage.s3.amazonaws.com/media/programs/Generic-Program-Banner.jpg",
      enable: "disable",
      status: "active",
    },
    {
      id: 46,
      title: "Abs",
      file_image:
        "https://bapy-dev-storage.s3.amazonaws.com/media/programs/Generic-Program-Banner.jpg",
      enable: "disable",
      status: "active",
    },
  ],
  equipment: [],
  zones: [],
  type: "video",
  highlight: "none",
  publish: "yes",
  status: "active",
  privacy: "public",
  title: "Standing Abs",
  description:
    "The dreaded Muffin Top! You know the part that spills over the top of your jeans no matter how much you try to suck in and squeeze.  Blast the fat with 15-minutes of Standing Abs! With Standing Abs, you’re working several core muscles at once and because you’re moving your entire body, you’ll burn more fat and calories. ",
  file_poster: null,
  video_source: "vimeo",
  video_id: "178636540",
  video_url: null,
  video_media_url: null,
  duration_mins: 15,
  transcoder_job_id: null,
  duration_estimate: 0,
  views: 23689,
  rating_stars: 4,
  rating_votes: 0,
  date_created: "2020-09-17T19:21:58.231178Z",
  date_updated: "2021-09-21T15:03:08.694604Z",
  date_live: null,
  date_publish: null,
  date_live_published: null,
  date_vod_uploaded: null,
  approved_at: null,
  certified: false,
  legacy_class_id: 57,
  review_count: 1751,
  likes: 1389,
  viewed_only: 4685,
  duplicate: false,
  legacy_billing_id: 57,
  legacy_gym_id: null,
  current_twilio_room: null,
  partner: 2706,
  level: 3,
  optional_equipment: [],
  goals: [15],
  organizations: [],
};
