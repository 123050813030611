import { lazy } from "react";
import { featureFlags } from "../../constants";
import PostAssessmentForm from "../../Courses/Assessment/PostAssessmentForm";
import { ROUTES } from "../constants";

const AccessDenied = lazy(() => import("../../Auth/AccessDenied"));

// Challenges
const Challenge = lazy(() => import("../../Challenges/Challenge"));
const Challenges = lazy(() => import("../../Challenges"));
const CreateChallenge = lazy(() => import("../../Challenges/CreateChallenge"));
const InviteChallengers = lazy(() =>
  import("../../Challenges/InviteChallengers")
);

// Team Challenges
const TeamChallenges = lazy(() => import("../../TeamChallenges"));
const CreateChallengeTeams = lazy(() =>
  import("../../TeamChallenges/CreateChallengeTeams")
);
const CreateTeamChallengeDetails = lazy(() =>
  import("../../TeamChallenges/CreateTeamChallengeDetails")
);
const ManageChallengeTeams = lazy(() =>
  import("../../TeamChallenges/ManageChallengeTeams")
);
const TeamChallengeDetails = lazy(() =>
  import("../../TeamChallenges/TeamChallengeDetails")
);

// Champion
const Champion = lazy(() => import("../../Champion"));

// Communities
const Communities = lazy(() => import("../../Communities"));
const Community = lazy(() => import("../../Communities/Community"));

// Courses
const Courses = lazy(() => import("../../Courses/Courses"));
const PrivateCourses = lazy(() => import("../../Courses/PrivateCourses"));
const CoursesCategories = lazy(() => import("../../Courses/Categories"));
const Course = lazy(() => import("../../Courses/Course"));
const LeaveCourseReview = lazy(() => import("../../Courses/LeaveCourseReview"));
const ScheduleCourse = lazy(() => import("../../Courses/ScheduleCourse"));
const RecommendedCourses = lazy(() => import("../../Courses/Recommended"));
const SetupGroupVodCourse = lazy(() =>
  import("../../Courses/SetupGroupVodCourse.js")
);
const CreateLiveRoom = lazy(() => import("../../Courses/CreateLiveRoom.js"));
const CourseVideo = lazy(() =>
  import("../../Courses/CourseVideo").then((module) => ({
    default: module.CourseVideo,
  }))
);
const CourseLive = lazy(() =>
  import("../../Courses/CourseVideo").then((module) => ({
    default: module.CourseLive,
  }))
);

const LiveRecordings = lazy(() => import("../../Courses/LiveRecordings"));

// Design Guide
const DesignGuide = lazy(() => import("../../DesignGuide"));
const Showcase = lazy(() => import("../../DesignGuide/Showcase"));

// Home
const Home = lazy(() => import("../../Home"));

// Partner Registration
const PartnerRegistration = lazy(() =>
  import("../../Auth/PartnerRegistration")
);

// Instructors
const Instructors = lazy(() => import("../../Instructors"));
const Instructor = lazy(() => import("../../Instructors/Instructor"));

// Interests
const Interests = lazy(() => import("../../Interests"));

// Partners
const Partners = lazy(() => import("../../Partners"));
const Partner = lazy(() => import("../../Partners/Partner"));

// Playlists
const MyPlaylist = lazy(() => import("../../Playlist"));
const Playlist = lazy(() => import("../../Playlist/Playlist"));
const AddPlaylistForm = lazy(() => import("../../Playlist/AddPlaylistForm"));
const EditPlaylistCourses = lazy(() =>
  import("../../Playlist/EditPlaylistCourses")
);
const BrowsePlaylists = lazy(() => import("../../Playlist/BrowsePlaylists"));
const InviteUserRole = lazy(() => import("../../Playlist/InviteUserRole"));

// Profile (User)
const Favorites = lazy(() => import("../../Favorites"));
const Profile = lazy(() => import("../../Profile"));
const EditProfile = lazy(() => import("../../Profile/EditProfile"));
const EditDynamicFields = lazy(() =>
  import("../../Profile/EditProfile/EditDynamicFields")
);
const ProfileMembershipPlan = lazy(() =>
  import("../../Profile/PlanSubscription/ProfileMembershipPlan")
);
const MembershipConfirmationPage = lazy(() =>
  import("../../PlnNetwork/PlnMembership/MembershipConfirmationPage")
);

// Homework
const HomeworkList = lazy(() => import("../../Profile/Homeworks/HomeworkList"));

const PlnLanding = lazy(() => import("../../PlnNetwork/PlnLanding/PlnLanding"));

const WellnessLevel = lazy(() =>
  import("../../Profile/EditProfile/WellnessLevel")
);
const WellnessHabits = lazy(() => import("../../WellnessHabits"));
const WellnessGoals = lazy(() => import("../../WellnessGoals"));
const Conditions = lazy(() => import("../../Conditions"));
const Contacts = lazy(() => import("../../Profile/Contacts"));
const Settings = lazy(() => import("../../Profile/Settings"));
const Schedule = lazy(() => import("../../Profile/Schedule"));
const Calendar = lazy(() => import("../../Profile/Calendar"));
const History = lazy(() => import("../../Profile/History"));
const SubAccounts = lazy(() => import("../../Profile/SubAccounts"));
const MyContacts = lazy(() => import("../../Profile/Contacts/MyContacts"));
const AddContact = lazy(() => import("../../Profile/Contacts/AddContact"));

// Onboarding
const Subscription = lazy(() => import("../../Subscription"));
const SubscriptionNew = lazy(() => import("../../SubscriptionNew"));
const SubscriptionUpgrade = lazy(() => import("../../SubscriptionUpgrade"));
const SubscriptionDowngrade = lazy(() => import("../../SubscriptionDowngrade"));
const ConfirmationPage = lazy(() =>
  import("../../SubscriptionNew/ConfirmationPage")
);
const Onboarding = lazy(() => import("../../Onboarding"));
// Programs
const Programs = lazy(() => import("../../Programs"));
const ProgramCategoryLanding = lazy(() =>
  import("../../Programs/ProgramCategoryLanding")
);
const Program = lazy(() => import("../../Programs/ProgramPage"));
const InviteToProgram = lazy(() => import("../../Programs/InviteToProgram"));
const EnrollInProgram = lazy(() => import("../../Programs/EnrollInProgram"));

// Search
const Search = lazy(() => import("../../Search/Search"));
const CourseFinderResults = lazy(() =>
  import("../../Search/CourseFinderResults")
);
const SearchResultsDetails = lazy(() =>
  import("../../Search/SearchResultsDetails")
);

// Webinar/Events
const WebinarListPage = lazy(() => import("../../Webinar/WebinarListPage"));
const WebinarDetailsPage = lazy(() =>
  import("../../Webinar/WebinarDetailsPage")
);

export const auth_user_route_group = (isBeta, features) => {
  return [
    {
      path: ROUTES.DASHBOARD,
      component: Home,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.SUBSCRIPTIONS,
      component: Subscription,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: ROUTES.SUBSCRIPTIONS_NEW,
      component: SubscriptionNew,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: ROUTES.SUBSCRIPTIONS_UPGRADE,
      component: SubscriptionUpgrade,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: ROUTES.SUBSCRIPTIONS_DOWNGRADE,
      component: SubscriptionDowngrade,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.MEMBERSHIP}${ROUTES.CONFIRMATION}`,
      component: ConfirmationPage,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: ROUTES.ONBOARDING,
      component: Onboarding,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.PARTNER}${ROUTES.NEW}`,
      component: PartnerRegistration,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: ROUTES.CHALLENGES,
      component: Challenges,
      exact: true,
      noShell: false,
      show: featureFlags.challenges && (isBeta || features.challenges),
    },
    {
      path: `${ROUTES.CHALLENGES}${ROUTES.CREATE}`,
      component: CreateChallenge,
      exact: true,
      noShell: false,
      show: featureFlags.challenges && (isBeta || features.challenges),
    },
    {
      path: `${ROUTES.CHALLENGE}${ROUTES.INVITE}/:id`,
      component: InviteChallengers,
      exact: true,
      noShell: false,
      show: featureFlags.challenges && (isBeta || features.challenges),
    },
    {
      path: `${ROUTES.CHALLENGE}${ROUTES.EDIT}/:id`,
      component: CreateChallenge,
      exact: true,
      noShell: false,
      show: featureFlags.challenges && (isBeta || features.challenges),
    },
    {
      path: `${ROUTES.CHALLENGE}/:id`,
      component: Challenge,
      exact: false,
      noShell: false,
      show: featureFlags.challenges && (isBeta || features.challenges),
    },
    {
      path: ROUTES.TEAM_CHALLENGES,
      component: TeamChallenges,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.CREATE}`,
      component: CreateTeamChallengeDetails,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id`,
      component: CreateTeamChallengeDetails,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}`,
      component: ManageChallengeTeams,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}/:teamID`,
      component: CreateChallengeTeams,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}${ROUTES.EDIT}/:id${ROUTES.TEAMS}${ROUTES.CREATE}`,
      component: CreateChallengeTeams,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}/show/:tab`,
      component: TeamChallenges,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: `${ROUTES.TEAM_CHALLENGES}/:id`,
      component: TeamChallengeDetails,
      exact: true,
      noShell: false,
      show: featureFlags.teamChallenges && (isBeta || features.teamChallenges),
    },
    {
      path: ROUTES.CHAMPION,
      component: Champion,
      exact: true,
      noShell: false,
      show: featureFlags.champion,
    },
    {
      path: ROUTES.COMMUNITIES,
      component: Communities,
      exact: true,
      noShell: false,
      show: featureFlags.communities && features.communities,
    },
    {
      path: `${ROUTES.COMMUNITY}/:id`,
      component: Community,
      exact: true,
      noShell: false,
      show: featureFlags.communities && features.communities,
    },
    {
      path: `${ROUTES.COMMUNITY}/:id/:tab`,
      component: Community,
      exact: true,
      noShell: false,
      show: featureFlags.communities && features.communities,
    },
    {
      path: `${ROUTES.COURSE}${ROUTES.VIDEO}/:roomId`,
      component: SetupGroupVodCourse,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.VIDEO}`,
      component: CourseVideo,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.LIVESTREAM}`,
      component: CourseLive,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}${ROUTES.RECORDINGS}/:id`,
      component: LiveRecordings,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.CREATEROOM}`,
      component: CreateLiveRoom,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.PARTNERSHIPS}`,
      component: PrivateCourses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.PRIVATE}/:type`,
      component: PrivateCourses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
      component: CoursesCategories,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.ZONES}`,
      component: CoursesCategories,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.RECOMMENDATIONS}`,
      component: RecommendedCourses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}/:filter`,
      component: Courses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.CATEGORY}`,
      component: Courses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.ZONE}`,
      component: Courses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.CATEGORY}/:id`,
      component: Courses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSES}${ROUTES.ZONE}/:id`,
      component: Courses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id`,
      component: Course,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.LIVE}/:eventId`,
      component: Course,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.OPEN_DOOR}/:eventId`,
      component: Course,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.LEAVE_REVIEW}`,
      component: LeaveCourseReview,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.POST_ASSESSMENT}/:historyId`,
      component: PostAssessmentForm,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.SCHEDULE}/:eventId`,
      component: ScheduleCourse,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id${ROUTES.SCHEDULE}`,
      component: ScheduleCourse,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.COURSE}/:id/:tab`,
      component: Course,
      exact: false,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.COURSES,
      component: CoursesCategories,
      exact: false,
      noShell: false,
      show: true,
    },

    {
      path: ROUTES.DESIGN,
      component: DesignGuide,
      exact: true,
      noShell: false,
      show: isBeta,
    },
    {
      path: `${ROUTES.DESIGN}/:type`,
      component: Showcase,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.INSTRUCTORS,
      component: Instructors,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.INSTRUCTOR}/:id`,
      component: Instructor,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.INSTRUCTOR}/:id/:tab`,
      component: Instructor,
      exact: false,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.INTERESTS,
      component: Interests,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.PARTNERS,
      component: Partners,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PARTNER}/:id`,
      component: Partner,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PARTNER}/:id/:tab`,
      component: Partner,
      exact: false,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.FAVORITES,
      component: Favorites,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}/:id${ROUTES.EDIT}${ROUTES.COURSES}`,
      component: EditPlaylistCourses,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}/:id${ROUTES.INVITE}`,
      component: InviteUserRole,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}/:id${ROUTES.EDIT}`,
      component: AddPlaylistForm,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}${ROUTES.NEW}`,
      component: AddPlaylistForm,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}/all`,
      component: BrowsePlaylists,
      exact: true,
      noShell: false,
      show: true,
    },

    {
      path: `${ROUTES.PLAYLIST}/:id`,
      component: Playlist,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLAYLIST}`,
      component: MyPlaylist,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.PROFILE,
      component: Profile,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.EDIT}`,
      component: EditProfile,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.EDIT}${ROUTES.DETAIL}`,
      component: EditDynamicFields,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.HOMEWORK}`,
      component: HomeworkList,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PLN}`,
      component: PlnLanding,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}`,
      component: ProfileMembershipPlan,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}${ROUTES.EDIT}`,
      component: ProfileMembershipPlan,
      exact: true,
      noShell: false,
      show: true,
    },
    // TODO REMOVE AFTER NEW PAYMENT REPLACES
    {
      path: `${ROUTES.PROFILE}${ROUTES.MEMBERSHIP}${ROUTES.CONFIRMATION}`,
      component: MembershipConfirmationPage,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.WELLNESS_LEVEL}`,
      component: WellnessLevel,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.CONDITIONS,
      component: Conditions,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.WELLNESS_HABITS,
      component: WellnessHabits,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.WELLNESS_GOALS,
      component: WellnessGoals,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.SETTINGS,
      component: Settings,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.SUB_ACCOUNTS,
      component: SubAccounts,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.CALENDAR,
      component: Calendar,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.SCHEDULE,
      component: Schedule,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.HISTORY,
      component: History,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}${ROUTES.MY_CONTACTS}`,
      component: MyContacts,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}`,
      component: Contacts,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.CONTACTS,
      component: Contacts,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROFILE}${ROUTES.CONTACTS}${ROUTES.ADD_CONTACTS}`,
      component: AddContact,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.PROGRAMS,
      component: Programs,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROGRAMS}${ROUTES.CATEGORY}/:type`,
      component: ProgramCategoryLanding,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROGRAM}${ROUTES.ENROLL}/:id`,
      component: EnrollInProgram,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROGRAM}${ROUTES.INVITE}/:id`,
      component: InviteToProgram,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROGRAM}/:id`,
      component: Program,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.PROGRAM}/:id/:tab`,
      component: Program,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.COURSE_FINDER,
      component: CourseFinderResults,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.SEARCH}${ROUTES.RESULTS}/:q/:type`,
      component: SearchResultsDetails,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.SEARCH}${ROUTES.RESULTS}${ROUTES.COURSE_FINDER}`,
      component: SearchResultsDetails,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.SEARCH}/:q`,
      component: Search,
      exact: true,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.SEARCH}`,
      component: Search,
      exact: false,
      noShell: true,
      show: true,
    },
    {
      path: `${ROUTES.EVENTS}`,
      component: WebinarListPage,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: `${ROUTES.EVENT}/:id`,
      component: WebinarDetailsPage,
      exact: true,
      noShell: false,
      show: true,
    },
    {
      path: ROUTES.ACCESS_DENIED,
      component: AccessDenied,
      exact: false,
      noShell: false,
      show: true,
    },
  ];
};

export default auth_user_route_group;
