import React, { useState, useRef, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Nav from "react-bootstrap/Nav";

import { ROUTES } from "../../_routes";
import { useMedia, useDimensions, useAuthAxios } from "../../_hooks";
import { history } from "../../_utils";
import { UserContext } from "../../_context/UserContext";

import {
  IconButton,
  Icon,
  Row,
  Alert,
  Heading5,
  Paragraph2,
} from "../../_components";
import NavLink from "../_components/NavLink";
import UserMenuMobile from "../_components/UserMenuMobile";
import { useGetMenuItems } from "../_components/_utils";
import UserDropdown from "../_components/UserDropdown";
import Button from "../../_components/Buttons/Button";
import { DropdownBasic } from "../../_components/Dropdown";

const Header = ({ setMenuOpen }) => {
  const [show, setShow] = useState(false);
  const isMobile = useMedia("(max-width: 922px)");
  const mainItems = useGetMenuItems("main");
  const divRef = useRef(null);
  const { height } = useDimensions(divRef);

  // Homeworks
  const { userData } = useContext(UserContext);
  const hasHomeworksFeature = userData?.features?.homeworks;
  const [{ data, loading }] = useAuthAxios("/homeworks/get?status=open", {
    useCache: false,
  });
  const homeworkCount = data?.data?.meta?.item_count || 0;
  const laterDate = localStorage.getItem("homeworksLaterDate");
  const [remindLater, setRemindLater] = useState(false);

  const today = new Date();
  const isLater = laterDate && new Date(laterDate) < today;

  const historySplit = history?.location?.pathname?.split("/");
  const isLeaveReviewPage =
    historySplit[historySplit.length - 1] === "leave-review";
  const hideHomeworkBanner =
    history?.location?.pathname === `${ROUTES.PROFILE}${ROUTES.HOMEWORK}` ||
    isLeaveReviewPage;

  const handleRemindLater = (value) => {
    const date = today;
    date.setHours(date.getHours() + value);
    localStorage.setItem("homeworksLaterDate", date);
    setRemindLater(true);
  };

  useEffect(() => {
    if (!isLater && typeof isLater === "boolean") setRemindLater(true);
  }, [isLater]);

  const RemindMeOptions = [
    {
      title: "Remind me in 1 hour",
      value: 1,
      key: "1h",
      onClick: () => handleRemindLater(1),
    },
    {
      title: "Remind me in 3 hours",
      value: 3,
      key: "3h",
      onClick: () => handleRemindLater(3),
    },
    {
      title: "Remind me in 1 day",
      value: 24,
      key: "1d",
      onClick: () => handleRemindLater(24),
    },
    {
      title: "Remind me in 3 day",
      value: 72,
      key: "3d",
      onClick: () => handleRemindLater(72),
    },
    {
      title: "Remind me in 1 week",
      value: 168,
      key: "1w",
      onClick: () => handleRemindLater(168),
    },
  ];

  const handleClickSearch = () => {
    let pathname = ROUTES.SEARCH;

    history.push({
      pathname: pathname,
    });
  };

  const handleOpenMenu = (e) => {
    if (!e) return;
    e.preventDefault();
    setMenuOpen(true);
  };

  const handleCloseMenu = (e) => {
    if (!e) return;
    e.preventDefault();
    setMenuOpen(false);
  };

  const handleViewHomeworks = () => {
    history.push(`${ROUTES.PROFILE}${ROUTES.HOMEWORK}`);
  };

  return (
    <>
      <Wrapper as="ul" id="topNav" ref={divRef} show={show}>
        <Row lg="auto" md={5}>
          <Nav.Item as="li">
            <Link
              to={ROUTES?.DASHBOARD}
              aria-label={"Burnalong Dashboard link"}
            >
              <Icon name="logo-full" width="11em" />
            </Link>
          </Nav.Item>
        </Row>
        <MainNav justify={"center"} className="d-lg-flex d-none">
          {mainItems?.map((item) => {
            if (item.hideOnHeader || item.hide) return null;
            return (
              <NavLink
                key={item.icon}
                name={item.icon}
                label={item.label}
                linkTo={item.link}
                type={item?.link ? "link" : "dropdown"}
              />
            );
          })}
        </MainNav>
        <Row
          lg="auto"
          md={7}
          justify={"flex-end"}
          $align={"center"}
          className="ml-auto"
        >
          <IconButton
            tabIndex={0}
            aria-label={"link, search"}
            id={`link-search-magnify-glass`}
            name="search"
            size="24px"
            $mx={3}
            onClick={handleClickSearch}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                handleClickSearch();
              }
            }}
          />
          <UserDropdown
            hide={isMobile}
            handleOpenMenu={handleOpenMenu}
            handleCloseMenu={handleCloseMenu}
          />
          {isMobile ? (
            <IconButton
              name={show ? "times-circle" : "bars"}
              size="24px"
              $ml={3}
              onClick={() => setShow(!show)}
            />
          ) : (
            <IconPlaceHolder />
          )}
        </Row>
      </Wrapper>
      {!hideHomeworkBanner && (
        <Alert
          show={
            homeworkCount > 0 &&
            !loading &&
            hasHomeworksFeature &&
            ((laterDate && isLater) || !remindLater)
          }
          bg="primary"
        >
          <Heading5 weight={700} color="white">
            Homeworks
          </Heading5>
          <Paragraph2 color="white">
            You currently have {homeworkCount} assigned homework
            {homeworkCount > 1 ? "s" : ""} that need
            {homeworkCount > 1 ? "" : "s"} your attention.
          </Paragraph2>
          <Row $mt={3} justify="center" $align="center">
            <Button
              $mx={3}
              $my={2}
              dark={false}
              bType="outline"
              onClick={handleViewHomeworks}
            >
              View Homeworks
            </Button>
            <DropdownBasic
              id="homeworksRemindMeLater"
              toggle="Remind me later"
              options={RemindMeOptions}
              background-color="#00172f"
            />
          </Row>
        </Alert>
      )}
      <UserMenuMobile show={show} setShow={setShow} height={height + 40} />
    </>
  );
};

const IconPlaceHolder = styled.div`
  width: 66px;
  height: 56px;
`;

const Wrapper = styled(Nav)`
  background-color: ${(props) => props.theme.palette.neutralWhite};
  padding: 20px;
  display: flex;
  list-style-type: none;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  width: 100%;
  position: ${(props) => (props.show ? "fixed" : "sticky")};
  top: ${(props) => props.show && 0};
  z-index: ${(props) => props.show && 1045};
  margin-bottom: 0;
  top: 0;
  z-index: 9998;
`;

const MainNav = styled(Row)`
  margin-left: auto;
  flex: 1;
  justify-content: space-between;
  max-width: 900px;
  @media screen and (max-width: 1090px) and (min-width: 922px) {
    margin-left: 30px;
  }
`;

export default Header;
