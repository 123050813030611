import { lazy } from "react";
import { ROUTES } from "../constants";

// Dashboard
const Dashboard = lazy(() => import("../../PSS/Dashboard"));

// Coursees
const Courses = lazy(() => import("../../PSS/Courses"));
const AddCourseForm = lazy(() => import("../../PSS/Courses/AddCourseForm"));
const CourseDetail = lazy(() => import("../../PSS/Courses/CourseDetail"));

//Reviews
const Review = lazy(() => import("../../PSS/Reviews/Review"));
const Reviews = lazy(() => import("../../PSS/Reviews"));

//Instructor and Owner
const Instructors = lazy(() => import("../../PSS/Instructors"));
const AddInstructorForm = lazy(() =>
  import("../../PSS/Instructors/AddInstructorForm")
);
const InviteInstructor = lazy(() =>
  import("../../PSS/Instructors/InviteInstructor")
);
const InstructorDetail = lazy(() =>
  import("../../PSS/Instructors/InstructorDetail")
);
const Owners = lazy(() => import("../../PSS/Owners"));
const AddOwnerForm = lazy(() => import("../../PSS/Owners/AddOwnerForm"));
const OwnerDetail = lazy(() => import("../../PSS/Owners/OwnerDetail"));
const InviteOwner = lazy(() => import("../../PSS/Owners/InviteOwner"));

// Locations
const Locations = lazy(() => import("../../PSS/Locations"));
const LocationDetail = lazy(() => import("../../PSS/Locations/LocationDetail"));
const AddLocationForm = lazy(() =>
  import("../../PSS/Locations/AddLocationForm")
);

// Partners
const Partners = lazy(() => import("../../PSS/Partners"));
const AddPartnerForm = lazy(() => import("../../PSS/Partners/AddPartnerForm"));
const PartnerDetail = lazy(() => import("../../PSS/Partners/PartnerDetail"));

// Profiles
const Profile = lazy(() => import("../../PSS/Profile"));
const EditProfile = lazy(() => import("../../PSS/Profile/EditProfile"));
const BusinessProfile = lazy(() => import("../../PSS/Profile/BusinessProfile"));

// Programs
const Programs = lazy(() => import("../../PSS/Programs"));
const ProgramOverview = lazy(() =>
  import("../../PSS/Programs/ProgramOverview")
);
const ProgramEditDetails = lazy(() =>
  import("../../PSS/Programs/ProgramEditDetails")
);
const ProgramEditCurriculum = lazy(() =>
  import("../../PSS/Programs/ProgramEditCurriculum")
);
const ProgramCurriculumListing = lazy(() =>
  import("../../PSS/Programs/ProgramCurriculumListing")
);

const PageNotFoundPSS = lazy(() =>
  import("../PageNotFound").then((module) => ({
    default: module.PageNotFoundPSS,
  }))
);

const auth_pss_route_group = [
  {
    path: ROUTES.PSS,
    component: Dashboard,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSES}${ROUTES.NEW}`,
    component: AddCourseForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSE}/:id${ROUTES.EDIT}`,
    component: AddCourseForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSE}/:id`,
    component: CourseDetail,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.COURSES}`,
    component: Courses,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.REVIEWS}`,
    component: Reviews,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.REVIEW}/:id`,
    component: Review,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}`,
    component: Instructors,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}${ROUTES.INVITE}`,
    component: InviteInstructor,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}${ROUTES.NEW}`,
    component: AddInstructorForm,
    exact: true,
    noShell: false,
    show: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}/:id${ROUTES.EDIT}`,
    component: AddInstructorForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.INSTRUCTORS}/:id`,
    component: InstructorDetail,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}`,
    component: Locations,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}${ROUTES.NEW}`,
    component: AddLocationForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}/:id${ROUTES.EDIT}`,
    component: AddLocationForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.LOCATIONS}/:id`,
    component: LocationDetail,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}`,
    component: Partners,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}${ROUTES.NEW}`,
    component: AddPartnerForm,
    exact: true,
    noShell: false,
    show: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}/:id${ROUTES.EDIT}`,
    component: AddPartnerForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PARTNERS}/:id`,
    component: PartnerDetail,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}`,
    component: Profile,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}${ROUTES.EDIT}`,
    component: EditProfile,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROFILE}${ROUTES.BUSINESS}`,
    component: BusinessProfile,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAMS}`,
    component: Programs,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}`,
    component: ProgramEditDetails,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}/:id${ROUTES.EDIT}`,
    component: ProgramEditDetails,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.COURSES}/:id`,
    component: ProgramCurriculumListing,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}${ROUTES.CREATE}/:id${ROUTES.COURSE}/:order`,
    component: ProgramEditCurriculum,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.PROGRAM}/:id`,
    component: ProgramOverview,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}`,
    component: Owners,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}${ROUTES.INVITE}`,
    component: InviteOwner,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}${ROUTES.NEW}`,
    component: AddOwnerForm,
    exact: true,
    noShell: false,
    show: false,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}/:id`,
    component: OwnerDetail,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: `${ROUTES.PSS}${ROUTES.OWNERS}/:id${ROUTES.EDIT}`,
    component: AddOwnerForm,
    exact: true,
    noShell: false,
    show: true,
  },
  {
    path: ROUTES.PSS,
    component: PageNotFoundPSS,
    exact: false,
    noShell: false,
    show: true,
  },
];

export default auth_pss_route_group;
