// Get Specific Menu item
export const getSearchItem = (
  menuName,
  menuItems,
  isDropdownData = false,
  filter = (item) => item
) => {
  if (!menuName || !menuItems) {
    return null;
  }
  let selectedMenuItem = menuItems.find((item) => item.field === menuName);
  if (selectedMenuItem) {
    if (isDropdownData) {
      let newItem = { ...selectedMenuItem };
      newItem.options = newItem.options.map((option) => ({
        ...option,
        label: option.title,
        value: option.title?.toLowerCase(),
        disabled: false,
        field: menuName,
      }));

      return filter(newItem);
    }
    return filter(selectedMenuItem);
  } else {
    return null;
  }
};
