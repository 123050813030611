import AuthLayout from "../App/AuthLayout";
import { Col, Heading1 } from "../_components";

const PageNotFound = ({ pss }) => {
  return pss ? (
    <Col $pt={40}>
      <Heading1 weight={700} $textAlign="center">
        Page not found :(
      </Heading1>
    </Col>
  ) : (
    <AuthLayout>
      <Col $justify="center">
        <Heading1 weight={700} $textAlign="center">
          Page not found :(
        </Heading1>
      </Col>
    </AuthLayout>
  );
};

export const PageNotFoundPSS = () => <PageNotFound pss />;

export default PageNotFound;
