import moment from "moment";

const formatTime = (date) => {
  let formattedDate = moment.utc(date).format("YYYYMMDDTHHmmssZ");
  return formattedDate.replace("+00:00", "Z");
};

const buildUrl = (event) => {
  let calendarUrl = [
    "BEGIN:VCALENDAR",
    "VERSION:2.0",
    "BEGIN:VEVENT",
    "URL:" + event.location,
    "DTSTART:" + formatTime(event.start.dateTime),
    "DTEND:" + formatTime(event.end.dateTime),
    "SUMMARY:" + event.summary,
    "DESCRIPTION:" + event.description,
    "END:VEVENT",
    "END:VCALENDAR",
  ].join("\n");

  return calendarUrl;
};

const downloadICS = (event) => {
  let url = buildUrl(event);
  let filename = "download.ics";
  let blob = new Blob([url], { type: "text/calendar;charset=utf-8" });

  if (
    typeof window !== "undefined" &&
    window.navigator.msSaveOrOpenBlob &&
    window.Blob
  ) {
    window.navigator.msSaveOrOpenBlob(blob, filename);
  } else {
    url = encodeURI("data:text/calendar;charset=utf8," + url);

    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export default downloadICS;
