import axios from "axios";
import moment from "moment";

import history from "../../../_utils/history";
import { ROUTES } from "../../../_routes";
import { apiUrl, headers } from "../../../constants";
import {
  TOKEN_REQUEST,
  TOKEN_SUCCESS,
  TOKEN_FAILURE,
} from "../../reducers/auth/token";

const refreshRequest = () => ({ type: TOKEN_REQUEST });
const refreshFailure = (errors) => ({ type: TOKEN_FAILURE, errors });
const refreshSuccess = (token) => ({ type: TOKEN_SUCCESS, token });

export const doRefreshAsync = (refresh) => (dispatch) => {
  const refreshTimestamp = localStorage.getItem("refresh-timestamp");
  const timeElapsed = moment().diff(moment(refreshTimestamp), "seconds");
  const within10Seconds = timeElapsed < 10;

  const strikes = Number(localStorage.getItem("refresh-strikes")) || 0;
  if (strikes >= 3) {
    return history.push(ROUTES.LOGIN);
  }

  if (!within10Seconds && strikes < 3) {
    localStorage.setItem("refresh-timestamp", moment().format());
    localStorage.setItem("refresh-strikes", 0);

    dispatch(refreshRequest());
    axios(`${apiUrl}/token/refresh/`, {
      method: "POST",
      data: JSON.stringify({ refresh }),
      headers,
    })
      .then((response) => {
        switch (response.status) {
          case 200:
            const { access } = response.data;
            return dispatch(refreshSuccess({ access, refresh }));
          // case 401:
          default:
            dispatch(
              refreshFailure({
                status: response.status,
                messages: response.data,
              })
            );
        }
      })
      .then(() => {
        window.location.reload(true);
        // history.push(ROUTES.LOGIN);
      })
      .catch((err) => {
        history.push(ROUTES.LOGIN);
        // return dispatch(refreshFailure(err.errors));
      });
  } else {
    localStorage.setItem("refresh-strikes", strikes + 1);
    return null;
  }
};

export default doRefreshAsync;
