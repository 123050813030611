export { default as authAxios } from "./authAxios";
export { breadcrumbHistory } from "./breadcrumbHistory";
export { canStreamCourse } from "./canStreamCourse";
export { convertDuration } from "./convertDuration";
export { isClient } from "./isClient";
export { isValidEmail } from "./isValidEmail";
export { numberWithCommas } from "./numberWithCommas";
export { pluralize } from "./pluralize";
export { processResponse } from "./processResponse";
export { slugifyString } from "./slugifyString";
export { utcDate, utcDateTime, utcDateToDisplay } from "./utcDate";
export { useQuery } from "./useQuery";
export { scrollToTop } from "./scrollToTop";
export {
  cleanPhoneNumber,
  cleanName,
  cleanCountryCode,
  cleanCountryOfResidence,
  removeCountryCodeFromPhone,
  validateSelectValue,
  isPhoneNumberValid,
  findCountryCode,
} from "./validators";
export {
  setFormDynamicFields,
  handleQueryDefaultValue,
  setDynamicFieldSelectOptions,
} from "./registration";
export {
  categories,
  categoryLevels,
  zones,
  wellnessHabits,
  sortOrderOptions,
} from "./constants";
export { getID } from "./getID";
export { getSearchItem } from "./getSearchItem";
export { history } from "./history";
export { dynamicSortMultiple } from "./dynamicSortMultiple";

export const combineTwoArraysByKeyValue = (array1, array2, key = "id") => {
  let newArray = array1.map((item1) => ({
    ...item1,
    ...array2.find((item2) => {
      if (key === "id") {
        return parseInt(item1[key]) === parseInt(item2[key]);
      } else {
        return item1[key] === item2[key];
      }
    }),
  }));
  return newArray;
};

export { convertHexToRGBA } from "./color";

export { useWindowSize } from "./useWindowSize";
