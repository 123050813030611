export const countryCodes = [
  { value: "+213", label: "+213" }, //  Algeria" },
  { value: "+376", label: "+376" }, //  Andorra" },
  { value: "+244", label: "+244" }, //  Angola" },
  { value: "+1264", label: "+1264" }, //  Anguilla" },
  { value: "+1268", label: "+1268" }, //  Antigua & Barbuda" },
  { value: "+54", label: "+54" }, //  Argentina" },
  { value: "+374", label: "+374" }, //  Armenia" },
  { value: "+297", label: "+297" }, //  Aruba" },
  { value: "+61", label: "+61" }, //  Australia" },
  { value: "+43", label: "+43" }, //  Austria" },
  { value: "+994", label: "+994" }, //  Azerbaijan" },
  { value: "+1242", label: "+1242" }, //  Bahamas" },
  { value: "+973", label: "+973" }, //  Bahrain" },
  { value: "+880", label: "+880" }, //  Bangladesh" },
  { value: "+1246", label: "+1246" }, //  Barbados" },
  { value: "+375", label: "+375" }, //  Belarus" },
  { value: "+32", label: "+32" }, //  Belgium" },
  { value: "+501", label: "+501" }, //  Belize" },
  { value: "+229", label: "+229" }, //  Benin" },
  { value: "+1441", label: "+1441" }, //  Bermuda" },
  { value: "+975", label: "+975" }, //  Bhutan" },
  { value: "+591", label: "+591" }, //  Bolivia" },
  { value: "+387", label: "+387" }, //  Bosnia Herzegovina" },
  { value: "+267", label: "+267" }, //  Botswana" },
  { value: "+55", label: "+55" }, //  Brazil" },
  { value: "+673", label: "+673" }, //  Brunei" },
  { value: "+359", label: "+359" }, //  Bulgaria" },
  { value: "+226", label: "+226" }, //  Burkina Faso" },
  { value: "+257", label: "+257" }, //  Burundi" },
  { value: "+855", label: "+855" }, //  Cambodia" },
  { value: "+237", label: "+237" }, //  Cameroon" },
  { value: "+1", label: "+1" }, //  Canada" },
  { value: "+238", label: "+238" }, //  Cape Verde Islands" },
  { value: "+1345", label: "+1345" }, //  Cayman Islands" },
  { value: "+236", label: "+236" }, //  Central African Republic" },
  { value: "+56", label: "+56" }, //  Chile" },
  { value: "+86", label: "+86" }, //  China" },
  { value: "+57", label: "+57" }, //  Colombia" },
  { value: "+269", label: "+269" }, //  Comoros" },
  { value: "+242", label: "+242" }, //  Congo" },
  { value: "+682", label: "+682" }, //  Cook Islands" },
  { value: "+506", label: "+506" }, //  Costa Rica" },
  { value: "+385", label: "+385" }, //  Croatia" },
  { value: "+53", label: "+53" }, //  Cuba" },
  { value: "+90392", label: "+90392" }, //  Cyprus North" },
  { value: "+357", label: "+357" }, //  Cyprus South" },
  { value: "+42", label: "+42" }, //  Czech Republic" },
  { value: "+45", label: "+45" }, //  Denmark" },
  { value: "+253", label: "+253" }, //  Djibouti" },
  { value: "+1809", label: "+1809" }, //  Dominica" },
  { value: "+1809", label: "+1809" }, //  Dominican Republic" },
  { value: "+593", label: "+593" }, //  Ecuador" },
  { value: "+20", label: "+20" }, //  Egypt" },
  { value: "+503", label: "+503" }, //  El Salvador" },
  { value: "+240", label: "+240" }, //  Equatorial Guinea" },
  { value: "+291", label: "+291" }, //  Eritrea" },
  { value: "+372", label: "+372" }, //  Estonia" },
  { value: "+251", label: "+251" }, //  Ethiopia" },
  { value: "+500", label: "+500" }, //  Falkland Islands" },
  { value: "+298", label: "+298" }, //  Faroe Islands" },
  { value: "+679", label: "+679" }, //  Fiji" },
  { value: "+358", label: "+358" }, //  Finland" },
  { value: "+33", label: "+33" }, //  France" },
  { value: "+594", label: "+594" }, //  French Guiana" },
  { value: "+689", label: "+689" }, //  French Polynesia" },
  { value: "+241", label: "+241" }, //  Gabon" },
  { value: "+220", label: "+220" }, //  Gambia" },
  { value: "+7880", label: "+7880" }, //  Georgia" },
  { value: "+49", label: "+49" }, //  Germany" },
  { value: "+233", label: "+233" }, //  Ghana" },
  { value: "+350", label: "+350" }, //  Gibraltar" },
  { value: "+30", label: "+30" }, //  Greece" },
  { value: "+299", label: "+299" }, //  Greenland" },
  { value: "+1473", label: "+1473" }, //  Grenada" },
  { value: "+590", label: "+590" }, //  Guadeloupe" },
  { value: "+671", label: "+671" }, //  Guam" },
  { value: "+502", label: "+502" }, //  Guatemala" },
  { value: "+224", label: "+224" }, //  Guinea" },
  { value: "+245", label: "+245" }, //  Guinea - Bissau" },
  { value: "+592", label: "+592" }, //  Guyana" },
  { value: "+509", label: "+509" }, //  Haiti" },
  { value: "+504", label: "+504" }, //  Honduras" },
  { value: "+852", label: "+852" }, //  Hong Kong" },
  { value: "+36", label: "+36" }, //  Hungary" },
  { value: "+354", label: "+354" }, //  Iceland" },
  { value: "+91", label: "+91" }, //  India" },
  { value: "+62", label: "+62" }, //  Indonesia" },
  { value: "+98", label: "+98" }, //  Iran" },
  { value: "+964", label: "+964" }, //  Iraq" },
  { value: "+353", label: "+353" }, //  Ireland" },
  { value: "+972", label: "+972" }, //  Israel" },
  { value: "+39", label: "+39" }, //  Italy" },
  { value: "+1876", label: "+1876" }, //  Jamaica" },
  { value: "+81", label: "+81" }, //  Japan" },
  { value: "+962", label: "+962" }, //  Jordan" },
  { value: "+7", label: "+7" }, //  Kazakhstan" },
  { value: "+254", label: "+254" }, //  Kenya" },
  { value: "+686", label: "+686" }, //  Kiribati" },
  { value: "+850", label: "+850" }, //  Korea North" },
  { value: "+82", label: "+82" }, //  Korea South" },
  { value: "+965", label: "+965" }, //  Kuwait" },
  { value: "+996", label: "+996" }, //  Kyrgyzstan" },
  { value: "+856", label: "+856" }, //  Laos" },
  { value: "+371", label: "+371" }, //  Latvia" },
  { value: "+961", label: "+961" }, //  Lebanon" },
  { value: "+266", label: "+266" }, //  Lesotho" },
  { value: "+231", label: "+231" }, //  Liberia" },
  { value: "+218", label: "+218" }, //  Libya" },
  { value: "+417", label: "+417" }, //  Liechtenstein" },
  { value: "+370", label: "+370" }, //  Lithuania" },
  { value: "+352", label: "+352" }, //  Luxembourg" },
  { value: "+853", label: "+853" }, //  Macao" },
  { value: "+389", label: "+389" }, //  Macedonia" },
  { value: "+261", label: "+261" }, //  Madagascar" },
  { value: "+265", label: "+265" }, //  Malawi" },
  { value: "+60", label: "+60" }, //  Malaysia" },
  { value: "+960", label: "+960" }, //  Maldives" },
  { value: "+223", label: "+223" }, //  Mali" },
  { value: "+356", label: "+356" }, //  Malta" },
  { value: "+692", label: "+692" }, //  Marshall Islands" },
  { value: "+596", label: "+596" }, //  Martinique" },
  { value: "+222", label: "+222" }, //  Mauritania" },
  { value: "+269", label: "+269" }, //  Mayotte" },
  { value: "+52", label: "+52" }, //  Mexico" },
  { value: "+691", label: "+691" }, //  Micronesia" },
  { value: "+373", label: "+373" }, //  Moldova" },
  { value: "+377", label: "+377" }, //  Monaco" },
  { value: "+976", label: "+976" }, //  Mongolia" },
  { value: "+1664", label: "+1664" }, //  Montserrat" },
  { value: "+212", label: "+212" }, //  Morocco" },
  { value: "+258", label: "+258" }, //  Mozambique" },
  { value: "+95", label: "+95" }, //  Myanmar" },
  { value: "+264", label: "+264" }, //  Namibia" },
  { value: "+674", label: "+674" }, //  Nauru" },
  { value: "+977", label: "+977" }, //  Nepal" },
  { value: "+31", label: "+31" }, //  Netherlands" },
  { value: "+687", label: "+687" }, //  New Caledonia" },
  { value: "+64", label: "+64" }, //  New Zealand" },
  { value: "+505", label: "+505" }, //  Nicaragua" },
  { value: "+227", label: "+227" }, //  Niger" },
  { value: "+234", label: "+234" }, //  Nigeria" },
  { value: "+683", label: "+683" }, //  Niue" },
  { value: "+672", label: "+672" }, //  Norfolk Islands" },
  { value: "+670", label: "+670" }, //  Northern Marianas" },
  { value: "+47", label: "+47" }, //  Norway" },
  { value: "+968", label: "+968" }, //  Oman" },
  { value: "+680", label: "+680" }, //  Palau" },
  { value: "+507", label: "+507" }, //  Panama" },
  { value: "+675", label: "+675" }, //  Papua New Guinea" },
  { value: "+595", label: "+595" }, //  Paraguay" },
  { value: "+51", label: "+51" }, //  Peru" },
  { value: "+63", label: "+63" }, //  Philippines" },
  { value: "+48", label: "+48" }, //  Poland" },
  { value: "+351", label: "+351" }, //  Portugal" },
  { value: "+1787", label: "+1787" }, //  Puerto Rico" },
  { value: "+974", label: "+974" }, //  Qatar" },
  { value: "+262", label: "+262" }, //  Reunion" },
  { value: "+40", label: "+40" }, //  Romania" },
  { value: "+7", label: "+7" }, //  Russia" },
  { value: "+250", label: "+250" }, //  Rwanda" },
  { value: "+378", label: "+378" }, //  San Marino" },
  { value: "+239", label: "+239" }, //  Sao Tome & Principe" },
  { value: "+966", label: "+966" }, //  Saudi Arabia" },
  { value: "+221", label: "+221" }, //  Senegal" },
  { value: "+381", label: "+381" }, //  Serbia" },
  { value: "+248", label: "+248" }, //  Seychelles" },
  { value: "+232", label: "+232" }, //  Sierra Leone" },
  { value: "+65", label: "+65" }, //  Singapore" },
  { value: "+421", label: "+421" }, //  Slovak Republic" },
  { value: "+386", label: "+386" }, //  Slovenia" },
  { value: "+677", label: "+677" }, //  Solomon Islands" },
  { value: "+252", label: "+252" }, //  Somalia" },
  { value: "+27", label: "+27" }, //  South Africa" },
  { value: "+34", label: "+34" }, //  Spain" },
  { value: "+94", label: "+94" }, //  Sri Lanka" },
  { value: "+290", label: "+290" }, //  St. Helena" },
  { value: "+1869", label: "+1869" }, //  St. Kitts" },
  { value: "+1758", label: "+1758" }, //  St. Lucia" },
  { value: "+249", label: "+249" }, //  Sudan" },
  { value: "+597", label: "+597" }, //  Suriname" },
  { value: "+268", label: "+268" }, //  Swaziland" },
  { value: "+46", label: "+46" }, //  Sweden" },
  { value: "+41", label: "+41" }, //  Switzerland" },
  { value: "+963", label: "+963" }, //  Syria" },
  { value: "+886", label: "+886" }, //  Taiwan" },
  { value: "+7", label: "+7" }, //  Tajikstan" },
  { value: "+66", label: "+66" }, //  Thailand" },
  { value: "+228", label: "+228" }, //  Togo" },
  { value: "+676", label: "+676" }, //  Tonga" },
  { value: "+1868", label: "+1868" }, //  Trinidad & Tobago" },
  { value: "+216", label: "+216" }, //  Tunisia" },
  { value: "+90", label: "+90" }, //  Turkey" },
  { value: "+7", label: "+7" }, //  Turkmenistan" },
  { value: "+993", label: "+993" }, //  Turkmenistan" },
  { value: "+1649", label: "+1649" }, //  Turks & Caicos Islands" },
  { value: "+688", label: "+688" }, //  Tuvalu" },
  { value: "+256", label: "+256" }, //  Uganda" },
  { value: "+44", label: "+44" }, //  United Kingdom" },
  { value: "+380", label: "+380" }, //  Ukraine" },
  { value: "+971", label: "+971" }, //  United Arab Emirates" },
  { value: "+598", label: "+598" }, //  Uruguay" },
  { value: "+1", label: "+1" }, //  United States of America" },
  { value: "+7", label: "+7" }, //  Uzbekistan" },
  { value: "+678", label: "+678" }, //  Vanuatu" },
  { value: "+379", label: "+379" }, //  Vatican City" },
  { value: "+58", label: "+58" }, //  Venezuela" },
  { value: "+84", label: "+84" }, //  Vietnam" },
  { value: "+1284", label: "+1284" }, //  Virgin Islands - British" },
  { value: "+1340", label: "+1340" }, //  Virgin Islands - US" },
  { value: "+681", label: "+681" }, //  Wallis & Futuna" },
  { value: "+969", label: "+969" }, //  Yemen (North" },
  { value: "+967", label: "+967" }, //  Yemen (South" },
  { value: "+260", label: "+260" }, //  Zambia" },
  { value: "+263", label: "+263" }, //  Zimbabwe" },
];

export const countryCodesWithCountryLabel = [
  { value: "+1", label: "USA (+1)" },
  { value: "+213", label: "Algeria (+213)" },
  { value: "+376", label: "Andorra (+376)" },
  { value: "+244", label: "Angola (+244)" },
  { value: "+1264", label: "Anguilla (+1264)" },
  { value: "+1268", label: "Antigua & Barbuda (+1268)" },
  { value: "+54", label: "Argentina (+54)" },
  { value: "+374", label: "Armenia (+374)" },
  { value: "+297", label: "Aruba (+297)" },
  { value: "+61", label: "Australia (+61)" },
  { value: "+43", label: "Austria (+43)" },
  { value: "+994", label: "Azerbaijan (+994)" },
  { value: "+1242", label: "Bahamas (+1242)" },
  { value: "+973", label: "Bahrain (+973)" },
  { value: "+880", label: "Bangladesh (+880)" },
  { value: "+1246", label: "Barbados (+1246)" },
  { value: "+375", label: "Belarus (+375)" },
  { value: "+32", label: "Belgium (+32)" },
  { value: "+501", label: "Belize (+501)" },
  { value: "+229", label: "Benin (+229)" },
  { value: "+1441", label: "Bermuda (+1441)" },
  { value: "+975", label: "Bhutan (+975)" },
  { value: "+591", label: "Bolivia (+591)" },
  { value: "+387", label: "Bosnia Herzegovina (+387)" },
  { value: "+267", label: "Botswana (+267)" },
  { value: "+55", label: "Brazil (+55)" },
  { value: "+673", label: "Brunei (+673)" },
  { value: "+359", label: "Bulgaria (+359)" },
  { value: "+226", label: "Burkina Faso (+226)" },
  { value: "+257", label: "Burundi (+257)" },
  { value: "+855", label: "Cambodia (+855)" },
  { value: "+237", label: "Cameroon (+237)" },
  { value: "+1", label: "Canada (+1)" },
  { value: "+238", label: "Cape Verde Islands (+238)" },
  { value: "+1345", label: "Cayman Islands (+1345)" },
  { value: "+236", label: "Central African Republic (+236)" },
  { value: "+56", label: "Chile (+56)" },
  { value: "+86", label: "China (+86)" },
  { value: "+57", label: "Colombia (+57)" },
  { value: "+269", label: "Comoros (+269)" },
  { value: "+242", label: "Congo (+242)" },
  { value: "+682", label: "Cook Islands (+682)" },
  { value: "+506", label: "Costa Rica (+506)" },
  { value: "+385", label: "Croatia (+385)" },
  { value: "+53", label: "Cuba (+53)" },
  { value: "+90392", label: "Cyprus North (+90392)" },
  { value: "+357", label: "Cyprus South (+357)" },
  { value: "+42", label: "Czech Republic (+42)" },
  { value: "+45", label: "Denmark (+45)" },
  { value: "+253", label: "Djibouti (+253)" },
  { value: "+1809", label: "Dominican Republic (+1809)" },
  { value: "+593", label: "Ecuador (+593)" },
  { value: "+20", label: "Egypt (+20)" },
  { value: "+503", label: "El Salvador (+503)" },
  { value: "+240", label: "Equatorial Guinea (+240)" },
  { value: "+291", label: "Eritrea (+291)" },
  { value: "+372", label: "Estonia (+372)" },
  { value: "+251", label: "Ethiopia (+251)" },
  { value: "+500", label: "Falkland Islands (+500)" },
  { value: "+298", label: "Faroe Islands (+298)" },
  { value: "+679", label: "Fiji (+679)" },
  { value: "+358", label: "Finland (+358)" },
  { value: "+33", label: "France (+33)" },
  { value: "+594", label: "French Guiana (+594)" },
  { value: "+689", label: "French Polynesia (+689)" },
  { value: "+241", label: "Gabon (+241)" },
  { value: "+220", label: "Gambia (+220)" },
  { value: "+7880", label: "Georgia (+7880)" },
  { value: "+49", label: "Germany (+49)" },
  { value: "+233", label: "Ghana (+233)" },
  { value: "+350", label: "Gibraltar (+350)" },
  { value: "+30", label: "Greece (+30)" },
  { value: "+299", label: "Greenland (+299)" },
  { value: "+1473", label: "Grenada (+1473)" },
  { value: "+590", label: "Guadeloupe (+590)" },
  { value: "+671", label: "Guam (+671)" },
  { value: "+502", label: "Guatemala (+502)" },
  { value: "+224", label: "Guinea (+224)" },
  { value: "+245", label: "Guinea - Bissau (+245)" },
  { value: "+592", label: "Guyana (+592)" },
  { value: "+509", label: "Haiti (+509)" },
  { value: "+504", label: "Honduras (+504)" },
  { value: "+852", label: "Hong Kong (+852)" },
  { value: "+36", label: "Hungary (+36)" },
  { value: "+354", label: "Iceland (+354)" },
  { value: "+91", label: "India (+91)" },
  { value: "+62", label: "Indonesia (+62)" },
  { value: "+98", label: "Iran (+98)" },
  { value: "+964", label: "Iraq (+964)" },
  { value: "+353", label: "Ireland (+353)" },
  { value: "+972", label: "Israel (+972)" },
  { value: "+39", label: "Italy (+39)" },
  { value: "+1876", label: "Jamaica (+1876)" },
  { value: "+81", label: "Japan (+81)" },
  { value: "+962", label: "Jordan (+962)" },
  { value: "+7", label: "Kazakhstan (+7)" },
  { value: "+254", label: "Kenya (+254)" },
  { value: "+686", label: "Kiribati (+686)" },
  { value: "+850", label: "Korea North (+850)" },
  { value: "+82", label: "Korea South (+82)" },
  { value: "+965", label: "Kuwait (+965)" },
  { value: "+996", label: "Kyrgyzstan (+996)" },
  { value: "+856", label: "Laos (+856)" },
  { value: "+371", label: "Latvia (+371)" },
  { value: "+961", label: "Lebanon (+961)" },
  { value: "+266", label: "Lesotho (+266)" },
  { value: "+231", label: "Liberia (+231)" },
  { value: "+218", label: "Libya (+218)" },
  { value: "+417", label: "Liechtenstein (+417)" },
  { value: "+370", label: "Lithuania (+370)" },
  { value: "+352", label: "Luxembourg (+352)" },
  { value: "+853", label: "Macao (+853)" },
  { value: "+389", label: "Macedonia (+389)" },
  { value: "+261", label: "Madagascar (+261)" },
  { value: "+265", label: "Malawi (+265)" },
  { value: "+60", label: "Malaysia (+60)" },
  { value: "+960", label: "Maldives (+960)" },
  { value: "+223", label: "Mali (+223)" },
  { value: "+356", label: "Malta (+356)" },
  { value: "+692", label: "Marshall Islands (+692)" },
  { value: "+596", label: "Martinique (+596)" },
  { value: "+222", label: "Mauritania (+222)" },
  { value: "+269", label: "Mayotte (+269)" },
  { value: "+52", label: "Mexico (+52)" },
  { value: "+691", label: "Micronesia (+691)" },
  { value: "+373", label: "Moldova (+373)" },
  { value: "+377", label: "Monaco (+377)" },
  { value: "+976", label: "Mongolia (+976)" },
  { value: "+1664", label: "Montserrat (+1664)" },
  { value: "+212", label: "Morocco (+212)" },
  { value: "+258", label: "Mozambique (+258)" },
  { value: "+95", label: "Myanmar (+95)" },
  { value: "+264", label: "Namibia (+264)" },
  { value: "+674", label: "Nauru (+674)" },
  { value: "+977", label: "Nepal (+977)" },
  { value: "+31", label: "Netherlands (+31)" },
  { value: "+687", label: "New Caledonia (+687)" },
  { value: "+64", label: "New Zealand (+64)" },
  { value: "+505", label: "Nicaragua (+505)" },
  { value: "+227", label: "Niger (+227)" },
  { value: "+234", label: "Nigeria (+234)" },
  { value: "+683", label: "Niue (+683)" },
  { value: "+672", label: "Norfolk Islands (+672)" },
  { value: "+670", label: "Northern Marianas (+670)" },
  { value: "+47", label: "Norway (+47)" },
  { value: "+968", label: "Oman (+968)" },
  { value: "+680", label: "Palau (+680)" },
  { value: "+507", label: "Panama (+507)" },
  { value: "+675", label: "Papua New Guinea (+675)" },
  { value: "+595", label: "Paraguay (+595)" },
  { value: "+51", label: "Peru (+51)" },
  { value: "+63", label: "Philippines (+63)" },
  { value: "+48", label: "Poland (+48)" },
  { value: "+351", label: "Portugal (+351)" },
  { value: "+1787", label: "Puerto Rico (+1787)" },
  { value: "+974", label: "Qatar (+974)" },
  { value: "+262", label: "Reunion (+262)" },
  { value: "+40", label: "Romania (+40)" },
  { value: "+7", label: "Russia (+7)" },
  { value: "+250", label: "Rwanda (+250)" },
  { value: "+378", label: "San Marino (+378)" },
  { value: "+239", label: "Sao Tome & Principe (+239)" },
  { value: "+966", label: "Saudi Arabia (+966)" },
  { value: "+221", label: "Senegal (+221)" },
  { value: "+381", label: "Serbia (+381)" },
  { value: "+248", label: "Seychelles (+248)" },
  { value: "+232", label: "Sierra Leone (+232)" },
  { value: "+65", label: "Singapore (+65)" },
  { value: "+421", label: "Slovak Republic (+421)" },
  { value: "+386", label: "Slovenia (+386)" },
  { value: "+677", label: "Solomon Islands (+677)" },
  { value: "+252", label: "Somalia (+252)" },
  { value: "+27", label: "South Africa (+27)" },
  { value: "+34", label: "Spain (+34)" },
  { value: "+94", label: "Sri Lanka (+94)" },
  { value: "+290", label: "St. Helena (+290)" },
  { value: "+1869", label: "St. Kitts (+1869)" },
  { value: "+1758", label: "St. Lucia (+1758)" },
  { value: "+249", label: "Sudan (+249)" },
  { value: "+597", label: "Suriname (+597)" },
  { value: "+268", label: "Swaziland (+268)" },
  { value: "+46", label: "Sweden (+46)" },
  { value: "+41", label: "Switzerland (+41)" },
  { value: "+963", label: "Syria (+963)" },
  { value: "+886", label: "Taiwan (+886)" },
  { value: "+7", label: "Tajikstan (+7)" },
  { value: "+66", label: "Thailand (+66)" },
  { value: "+228", label: "Togo (+228)" },
  { value: "+676", label: "Tonga (+676)" },
  { value: "+1868", label: "Trinidad & Tobago (+1868)" },
  { value: "+216", label: "Tunisia (+216)" },
  { value: "+90", label: "Turkey (+90)" },
  { value: "+993", label: "Turkmenistan (+993)" },
  { value: "+1649", label: "Turks & Caicos Islands (+1649)" },
  { value: "+688", label: "Tuvalu (+688)" },
  { value: "+256", label: "Uganda (+256)" },
  { value: "+44", label: "UK (+44)" },
  { value: "+380", label: "Ukraine (+380)" },
  { value: "+971", label: "United Arab Emirates (+971)" },
  { value: "+598", label: "Uruguay (+598)" },
  { value: "+1", label: "USA (+1)" },
  { value: "+7", label: "Uzbekistan (+7)" },
  { value: "+678", label: "Vanuatu (+678)" },
  { value: "+379", label: "Vatican City (+379)" },
  { value: "+58", label: "Venezuela (+58)" },
  { value: "+84", label: "Vietnam (+84)" },
  { value: "+1284", label: "Virgin Islands - British (+1284)" },
  { value: "+1340", label: "Virgin Islands - US (+1340)" },
  { value: "+681", label: "Wallis & Futuna (+681)" },
  { value: "+969", label: "Yemen (North)(+969)" },
  { value: "+967", label: "Yemen Arab Republic (South)(+967)" },
  { value: "+260", label: "Zambia (+260)" },
  { value: "+263", label: "Zimbabwe (+263)" },
];

export const countryLabel = [
  { countryCode: "+1", value: "USA", label: "USA" },
  { countryCode: "+213", value: "Algeria", label: "Algeria" },
  { countryCode: "+376", value: "Andorra", label: "Andorra" },
  { countryCode: "+244", value: "Angola", label: "Angola" },
  { countryCode: "+1264", value: "Anguilla", label: "Anguilla" },
  {
    countryCode: "+1268",
    value: "Antigua & Barbuda",
    label: "Antigua & Barbuda",
  },
  { countryCode: "+54", value: "Argentina", label: "Argentina" },
  { countryCode: "+374", value: "Armenia", label: "Armenia" },
  { countryCode: "+297", value: "Aruba", label: "Aruba" },
  { countryCode: "+61", value: "Australia", label: "Australia" },
  { countryCode: "+43", value: "Austria", label: "Austria" },
  { countryCode: "+994", value: "Azerbaijan", label: "Azerbaijan" },
  { countryCode: "+1242", value: "Bahamas", label: "Bahamas" },
  { countryCode: "+973", value: "Bahrain", label: "Bahrain" },
  { countryCode: "+880", value: "Bangladesh", label: "Bangladesh" },
  { countryCode: "+1246", value: "Barbados", label: "Barbados" },
  { countryCode: "+375", value: "Belarus", label: "Belarus" },
  { countryCode: "+32", value: "Belgium", label: "Belgium" },
  { countryCode: "+501", value: "Belize", label: "Belize" },
  { countryCode: "+229", value: "Benin", label: "Benin" },
  { countryCode: "+1441", value: "Bermuda", label: "Bermuda" },
  { countryCode: "+975", value: "Bhutan", label: "Bhutan" },
  { countryCode: "+591", value: "Bolivia", label: "Bolivia" },
  {
    countryCode: "+387",
    value: "Bosnia Herzegovina",
    label: "Bosnia Herzegovina",
  },
  { countryCode: "+267", value: "Botswana", label: "Botswana" },
  { countryCode: "+55", value: "Brazil", label: "Brazil" },
  { countryCode: "+673", value: "Brunei", label: "Brunei" },
  { countryCode: "+359", value: "Bulgaria", label: "Bulgaria" },
  { countryCode: "+226", value: "Burkina Faso", label: "Burkina Faso" },
  { countryCode: "+257", value: "Burundi", label: "Burundi" },
  { countryCode: "+855", value: "Cambodia", label: "Cambodia" },
  { countryCode: "+237", value: "Cameroon", label: "Cameroon" },
  { countryCode: "+1", value: "Canada", label: "Canada" },
  {
    countryCode: "+238",
    value: "Cape Verde Islands",
    label: "Cape Verde Islands",
  },
  { countryCode: "+1345", value: "Cayman Islands", label: "Cayman Islands" },
  {
    countryCode: "+236",
    value: "Central African Republic",
    label: "Central African Republic",
  },
  { countryCode: "+56", value: "Chile", label: "Chile" },
  { countryCode: "+86", value: "China", label: "China" },
  { countryCode: "+57", value: "Colombia", label: "Colombia" },
  { countryCode: "+269", value: "Comoros", label: "Comoros" },
  { countryCode: "+242", value: "Congo", label: "Congo" },
  { countryCode: "+682", value: "Cook Islands", label: "Cook Islands" },
  { countryCode: "+506", value: "Costa Rica", label: "Costa Rica" },
  { countryCode: "+385", value: "Croatia", label: "Croatia" },
  { countryCode: "+53", value: "Cuba", label: "Cuba" },
  { countryCode: "+90392", value: "Cyprus North", label: "Cyprus North" },
  { countryCode: "+357", value: "Cyprus South", label: "Cyprus South" },
  { countryCode: "+42", value: "Czech Republic", label: "Czech Republic" },
  { countryCode: "+45", value: "Denmark", label: "Denmark" },
  { countryCode: "+253", value: "Djibouti", label: "Djibouti" },
  {
    countryCode: "+1809",
    value: "Dominican Republic",
    label: "Dominican Republic",
  },
  { countryCode: "+593", value: "Ecuador", label: "Ecuador" },
  { countryCode: "+20", value: "Egypt", label: "Egypt" },
  { countryCode: "+503", value: "El Salvador", label: "El Salvador" },
  {
    countryCode: "+240",
    value: "Equatorial Guinea",
    label: "Equatorial Guinea",
  },
  { countryCode: "+291", value: "Eritrea", label: "Eritrea" },
  { countryCode: "+372", value: "Estonia", label: "Estonia" },
  { countryCode: "+251", value: "Ethiopia", label: "Ethiopia" },
  { countryCode: "+500", value: "Falkland Islands", label: "Falkland Islands" },
  { countryCode: "+298", value: "Faroe Islands", label: "Faroe Islands" },
  { countryCode: "+679", value: "Fiji", label: "Fiji" },
  { countryCode: "+358", value: "Finland", label: "Finland" },
  { countryCode: "+33", value: "France", label: "France" },
  { countryCode: "+594", value: "French Guiana", label: "French Guiana" },
  { countryCode: "+689", value: "French Polynesia", label: "French Polynesia" },
  { countryCode: "+241", value: "Gabon", label: "Gabon" },
  { countryCode: "+220", value: "Gambia", label: "Gambia" },
  { countryCode: "+7880", value: "Georgia", label: "Georgia" },
  { countryCode: "+49", value: "Germany", label: "Germany" },
  { countryCode: "+233", value: "Ghana", label: "Ghana" },
  { countryCode: "+350", value: "Gibraltar", label: "Gibraltar" },
  { countryCode: "+30", value: "Greece", label: "Greece" },
  { countryCode: "+299", value: "Greenland", label: "Greenland" },
  { countryCode: "+1473", value: "Grenada", label: "Grenada" },
  { countryCode: "+590", value: "Guadeloupe", label: "Guadeloupe" },
  { countryCode: "+671", value: "Guam", label: "Guam" },
  { countryCode: "+502", value: "Guatemala", label: "Guatemala" },
  { countryCode: "+224", value: "Guinea", label: "Guinea" },
  { countryCode: "+245", value: "Guinea - Bissau", label: "Guinea - Bissau" },
  { countryCode: "+592", value: "Guyana", label: "Guyana" },
  { countryCode: "+509", value: "Haiti", label: "Haiti" },
  { countryCode: "+504", value: "Honduras", label: "Honduras" },
  { countryCode: "+852", value: "Hong Kong", label: "Hong Kong" },
  { countryCode: "+36", value: "Hungary", label: "Hungary" },
  { countryCode: "+354", value: "Iceland", label: "Iceland" },
  { countryCode: "+91", value: "India", label: "India" },
  { countryCode: "+62", value: "Indonesia", label: "Indonesia" },
  { countryCode: "+98", value: "Iran", label: "Iran" },
  { countryCode: "+964", value: "Iraq", label: "Iraq" },
  { countryCode: "+353", value: "Ireland", label: "Ireland" },
  { countryCode: "+972", value: "Israel", label: "Israel" },
  { countryCode: "+39", value: "Italy", label: "Italy" },
  { countryCode: "+1876", value: "Jamaica", label: "Jamaica" },
  { countryCode: "+81", value: "Japan", label: "Japan" },
  { countryCode: "+962", value: "Jordan", label: "Jordan" },
  { countryCode: "+7", value: "Kazakhstan", label: "Kazakhstan" },
  { countryCode: "+254", value: "Kenya", label: "Kenya" },
  { countryCode: "+686", value: "Kiribati", label: "Kiribati" },
  { countryCode: "+850", value: "Korea North", label: "Korea North" },
  { countryCode: "+82", value: "Korea South", label: "Korea South" },
  { countryCode: "+965", value: "Kuwait", label: "Kuwait" },
  { countryCode: "+996", value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { countryCode: "+856", value: "Laos", label: "Laos" },
  { countryCode: "+371", value: "Latvia", label: "Latvia" },
  { countryCode: "+961", value: "Lebanon", label: "Lebanon" },
  { countryCode: "+266", value: "Lesotho", label: "Lesotho" },
  { countryCode: "+231", value: "Liberia", label: "Liberia" },
  { countryCode: "+218", value: "Libya", label: "Libya" },
  { countryCode: "+417", value: "Liechtenstein", label: "Liechtenstein" },
  { countryCode: "+370", value: "Lithuania", label: "Lithuania" },
  { countryCode: "+352", value: "Luxembourg", label: "Luxembourg" },
  { countryCode: "+853", value: "Macao", label: "Macao" },
  { countryCode: "+389", value: "Macedonia", label: "Macedonia" },
  { countryCode: "+261", value: "Madagascar", label: "Madagascar" },
  { countryCode: "+265", value: "Malawi", label: "Malawi" },
  { countryCode: "+60", value: "Malaysia", label: "Malaysia" },
  { countryCode: "+960", value: "Maldives", label: "Maldives" },
  { countryCode: "+223", value: "Mali", label: "Mali" },
  { countryCode: "+356", value: "Malta", label: "Malta" },
  { countryCode: "+692", value: "Marshall Islands", label: "Marshall Islands" },
  { countryCode: "+596", value: "Martinique", label: "Martinique" },
  { countryCode: "+222", value: "Mauritania", label: "Mauritania" },
  { countryCode: "+269", value: "Mayotte", label: "Mayotte" },
  { countryCode: "+52", value: "Mexico", label: "Mexico" },
  { countryCode: "+691", value: "Micronesia", label: "Micronesia" },
  { countryCode: "+373", value: "Moldova", label: "Moldova" },
  { countryCode: "+377", value: "Monaco", label: "Monaco" },
  { countryCode: "+976", value: "Mongolia", label: "Mongolia" },
  { countryCode: "+1664", value: "Montserrat", label: "Montserrat" },
  { countryCode: "+212", value: "Morocco", label: "Morocco" },
  { countryCode: "+258", value: "Mozambique", label: "Mozambique" },
  { countryCode: "+95", value: "Myanmar", label: "Myanmar" },
  { countryCode: "+264", value: "Namibia", label: "Namibia" },
  { countryCode: "+674", value: "Nauru", label: "Nauru" },
  { countryCode: "+977", value: "Nepal", label: "Nepal" },
  { countryCode: "+31", value: "Netherlands", label: "Netherlands" },
  { countryCode: "+687", value: "New Caledonia", label: "New Caledonia" },
  { countryCode: "+64", value: "New Zealand", label: "New Zealand" },
  { countryCode: "+505", value: "Nicaragua", label: "Nicaragua" },
  { countryCode: "+227", value: "Niger", label: "Niger" },
  { countryCode: "+234", value: "Nigeria", label: "Nigeria" },
  { countryCode: "+683", value: "Niue", label: "Niue" },
  { countryCode: "+672", value: "Norfolk Islands", label: "Norfolk Islands" },
  {
    countryCode: "+670",
    value: "Northern Marianas",
    label: "Northern Marianas",
  },
  { countryCode: "+47", value: "Norway", label: "Norway" },
  { countryCode: "+968", value: "Oman", label: "Oman" },
  { countryCode: "+680", value: "Palau", label: "Palau" },
  { countryCode: "+507", value: "Panama", label: "Panama" },
  { countryCode: "+675", value: "Papua New Guinea", label: "Papua New Guinea" },
  { countryCode: "+595", value: "Paraguay", label: "Paraguay" },
  { countryCode: "+51", value: "Peru", label: "Peru" },
  { countryCode: "+63", value: "Philippines", label: "Philippines" },
  { countryCode: "+48", value: "Poland", label: "Poland" },
  { countryCode: "+351", value: "Portugal", label: "Portugal" },
  { countryCode: "+1787", value: "Puerto Rico", label: "Puerto Rico" },
  { countryCode: "+974", value: "Qatar", label: "Qatar" },
  { countryCode: "+262", value: "Reunion", label: "Reunion" },
  { countryCode: "+40", value: "Romania", label: "Romania" },
  { countryCode: "+7", value: "Russia", label: "Russia" },
  { countryCode: "+250", value: "Rwanda", label: "Rwanda" },
  { countryCode: "+378", value: "San Marino", label: "San Marino" },
  {
    countryCode: "+239",
    value: "Sao Tome & Principe",
    label: "Sao Tome & Principe",
  },
  { countryCode: "+966", value: "Saudi Arabia", label: "Saudi Arabia" },
  { countryCode: "+221", value: "Senegal", label: "Senegal" },
  { countryCode: "+381", value: "Serbia", label: "Serbia" },
  { countryCode: "+248", value: "Seychelles", label: "Seychelles" },
  { countryCode: "+232", value: "Sierra Leone", label: "Sierra Leone" },
  { countryCode: "+65", value: "Singapore", label: "Singapore" },
  { countryCode: "+421", value: "Slovak Republic", label: "Slovak Republic" },
  { countryCode: "+386", value: "Slovenia", label: "Slovenia" },
  { countryCode: "+677", value: "Solomon Islands", label: "Solomon Islands" },
  { countryCode: "+252", value: "Somalia", label: "Somalia" },
  { countryCode: "+27", value: "South Africa", label: "South Africa" },
  { countryCode: "+34", value: "Spain", label: "Spain" },
  { countryCode: "+94", value: "Sri Lanka", label: "Sri Lanka" },
  { countryCode: "+290", value: "St. Helena", label: "St. Helena" },
  { countryCode: "+1869", value: "St. Kitts", label: "St. Kitts" },
  { countryCode: "+1758", value: "St. Lucia", label: "St. Lucia" },
  { countryCode: "+249", value: "Sudan", label: "Sudan" },
  { countryCode: "+597", value: "Suriname", label: "Suriname" },
  { countryCode: "+268", value: "Swaziland", label: "Swaziland" },
  { countryCode: "+46", value: "Sweden", label: "Sweden" },
  { countryCode: "+41", value: "Switzerland", label: "Switzerland" },
  { countryCode: "+963", value: "Syria", label: "Syria" },
  { countryCode: "+886", value: "Taiwan", label: "Taiwan" },
  { countryCode: "+7", value: "Tajikstan", label: "Tajikstan" },
  { countryCode: "+66", value: "Thailand", label: "Thailand" },
  { countryCode: "+228", value: "Togo", label: "Togo" },
  { countryCode: "+676", value: "Tonga", label: "Tonga" },
  {
    countryCode: "+1868",
    value: "Trinidad & Tobago",
    label: "Trinidad & Tobago",
  },
  { countryCode: "+216", value: "Tunisia", label: "Tunisia" },
  { countryCode: "+90", value: "Turkey", label: "Turkey" },
  { countryCode: "+993", value: "Turkmenistan", label: "Turkmenistan" },
  {
    countryCode: "+1649",
    value: "Turks & Caicos Islands",
    label: "Turks & Caicos Islands",
  },
  { countryCode: "+688", value: "Tuvalu", label: "Tuvalu" },
  { countryCode: "+256", value: "Uganda", label: "Uganda" },
  { countryCode: "+44", value: "UK", label: "UK" },
  { countryCode: "+380", value: "Ukraine", label: "Ukraine" },
  {
    countryCode: "+971",
    value: "United Arab Emirates",
    label: "United Arab Emirates",
  },
  { countryCode: "+598", value: "Uruguay", label: "Uruguay" },
  { countryCode: "+7", value: "Uzbekistan", label: "Uzbekistan" },
  { countryCode: "+678", value: "Vanuatu", label: "Vanuatu" },
  { countryCode: "+379", value: "Vatican City", label: "Vatican City" },
  { countryCode: "+58", value: "Venezuela", label: "Venezuela" },
  { countryCode: "+84", value: "Vietnam", label: "Vietnam" },
  {
    countryCode: "+1284",
    value: "Virgin Islands - British",
    label: "Virgin Islands - British",
  },
  {
    countryCode: "+1340",
    value: "Virgin Islands - US",
    label: "Virgin Islands - US",
  },
  { countryCode: "+681", value: "Wallis & Futuna", label: "Wallis & Futuna" },
  { countryCode: "+969", value: "Yemen (North)", label: "Yemen (North)" },
  {
    countryCode: "+967",
    value: "Yemen Arab Republic (South)",
    label: "Yemen Arab Republic (South)",
  },
  { countryCode: "+260", value: "Zambia", label: "Zambia" },
  { countryCode: "+263", value: "Zimbabwe", label: "Zimbabwe" },
];

export const sortedUniqueCountryCodes = [
  { label: "+1", value: "+1" },
  { label: "+7", value: "+7" },
  { label: "+20", value: "+20" },
  { label: "+27", value: "+27" },
  { label: "+30", value: "+30" },
  { label: "+31", value: "+31" },
  { label: "+32", value: "+32" },
  { label: "+33", value: "+33" },
  { label: "+34", value: "+34" },
  { label: "+36", value: "+36" },
  { label: "+39", value: "+39" },
  { label: "+40", value: "+40" },
  { label: "+41", value: "+41" },
  { label: "+42", value: "+42" },
  { label: "+43", value: "+43" },
  { label: "+44", value: "+44" },
  { label: "+45", value: "+45" },
  { label: "+46", value: "+46" },
  { label: "+47", value: "+47" },
  { label: "+48", value: "+48" },
  { label: "+49", value: "+49" },
  { label: "+51", value: "+51" },
  { label: "+52", value: "+52" },
  { label: "+53", value: "+53" },
  { label: "+54", value: "+54" },
  { label: "+55", value: "+55" },
  { label: "+56", value: "+56" },
  { label: "+57", value: "+57" },
  { label: "+58", value: "+58" },
  { label: "+60", value: "+60" },
  { label: "+61", value: "+61" },
  { label: "+62", value: "+62" },
  { label: "+63", value: "+63" },
  { label: "+64", value: "+64" },
  { label: "+65", value: "+65" },
  { label: "+66", value: "+66" },
  { label: "+81", value: "+81" },
  { label: "+82", value: "+82" },
  { label: "+84", value: "+84" },
  { label: "+86", value: "+86" },
  { label: "+90", value: "+90" },
  { label: "+91", value: "+91" },
  { label: "+94", value: "+94" },
  { label: "+95", value: "+95" },
  { label: "+98", value: "+98" },
  { label: "+212", value: "+212" },
  { label: "+213", value: "+213" },
  { label: "+216", value: "+216" },
  { label: "+218", value: "+218" },
  { label: "+220", value: "+220" },
  { label: "+221", value: "+221" },
  { label: "+222", value: "+222" },
  { label: "+223", value: "+223" },
  { label: "+224", value: "+224" },
  { label: "+226", value: "+226" },
  { label: "+227", value: "+227" },
  { label: "+228", value: "+228" },
  { label: "+229", value: "+229" },
  { label: "+231", value: "+231" },
  { label: "+232", value: "+232" },
  { label: "+233", value: "+233" },
  { label: "+234", value: "+234" },
  { label: "+236", value: "+236" },
  { label: "+237", value: "+237" },
  { label: "+238", value: "+238" },
  { label: "+239", value: "+239" },
  { label: "+240", value: "+240" },
  { label: "+241", value: "+241" },
  { label: "+242", value: "+242" },
  { label: "+244", value: "+244" },
  { label: "+245", value: "+245" },
  { label: "+248", value: "+248" },
  { label: "+249", value: "+249" },
  { label: "+250", value: "+250" },
  { label: "+251", value: "+251" },
  { label: "+252", value: "+252" },
  { label: "+253", value: "+253" },
  { label: "+254", value: "+254" },
  { label: "+256", value: "+256" },
  { label: "+257", value: "+257" },
  { label: "+258", value: "+258" },
  { label: "+260", value: "+260" },
  { label: "+261", value: "+261" },
  { label: "+262", value: "+262" },
  { label: "+263", value: "+263" },
  { label: "+264", value: "+264" },
  { label: "+265", value: "+265" },
  { label: "+266", value: "+266" },
  { label: "+267", value: "+267" },
  { label: "+268", value: "+268" },
  { label: "+269", value: "+269" },
  { label: "+290", value: "+290" },
  { label: "+291", value: "+291" },
  { label: "+297", value: "+297" },
  { label: "+298", value: "+298" },
  { label: "+299", value: "+299" },
  { label: "+350", value: "+350" },
  { label: "+351", value: "+351" },
  { label: "+352", value: "+352" },
  { label: "+353", value: "+353" },
  { label: "+354", value: "+354" },
  { label: "+356", value: "+356" },
  { label: "+357", value: "+357" },
  { label: "+358", value: "+358" },
  { label: "+359", value: "+359" },
  { label: "+370", value: "+370" },
  { label: "+371", value: "+371" },
  { label: "+372", value: "+372" },
  { label: "+373", value: "+373" },
  { label: "+374", value: "+374" },
  { label: "+375", value: "+375" },
  { label: "+376", value: "+376" },
  { label: "+377", value: "+377" },
  { label: "+378", value: "+378" },
  { label: "+379", value: "+379" },
  { label: "+380", value: "+380" },
  { label: "+381", value: "+381" },
  { label: "+385", value: "+385" },
  { label: "+386", value: "+386" },
  { label: "+387", value: "+387" },
  { label: "+389", value: "+389" },
  { label: "+417", value: "+417" },
  { label: "+421", value: "+421" },
  { label: "+500", value: "+500" },
  { label: "+501", value: "+501" },
  { label: "+502", value: "+502" },
  { label: "+503", value: "+503" },
  { label: "+504", value: "+504" },
  { label: "+505", value: "+505" },
  { label: "+506", value: "+506" },
  { label: "+507", value: "+507" },
  { label: "+509", value: "+509" },
  { label: "+590", value: "+590" },
  { label: "+591", value: "+591" },
  { label: "+592", value: "+592" },
  { label: "+593", value: "+593" },
  { label: "+594", value: "+594" },
  { label: "+595", value: "+595" },
  { label: "+596", value: "+596" },
  { label: "+597", value: "+597" },
  { label: "+598", value: "+598" },
  { label: "+670", value: "+670" },
  { label: "+671", value: "+671" },
  { label: "+672", value: "+672" },
  { label: "+673", value: "+673" },
  { label: "+674", value: "+674" },
  { label: "+675", value: "+675" },
  { label: "+676", value: "+676" },
  { label: "+677", value: "+677" },
  { label: "+678", value: "+678" },
  { label: "+679", value: "+679" },
  { label: "+680", value: "+680" },
  { label: "+681", value: "+681" },
  { label: "+682", value: "+682" },
  { label: "+683", value: "+683" },
  { label: "+686", value: "+686" },
  { label: "+687", value: "+687" },
  { label: "+688", value: "+688" },
  { label: "+689", value: "+689" },
  { label: "+691", value: "+691" },
  { label: "+692", value: "+692" },
  { label: "+850", value: "+850" },
  { label: "+852", value: "+852" },
  { label: "+853", value: "+853" },
  { label: "+855", value: "+855" },
  { label: "+856", value: "+856" },
  { label: "+880", value: "+880" },
  { label: "+886", value: "+886" },
  { label: "+960", value: "+960" },
  { label: "+961", value: "+961" },
  { label: "+962", value: "+962" },
  { label: "+963", value: "+963" },
  { label: "+964", value: "+964" },
  { label: "+965", value: "+965" },
  { label: "+966", value: "+966" },
  { label: "+967", value: "+967" },
  { label: "+968", value: "+968" },
  { label: "+969", value: "+969" },
  { label: "+971", value: "+971" },
  { label: "+972", value: "+972" },
  { label: "+973", value: "+973" },
  { label: "+974", value: "+974" },
  { label: "+975", value: "+975" },
  { label: "+976", value: "+976" },
  { label: "+977", value: "+977" },
  { label: "+993", value: "+993" },
  { label: "+994", value: "+994" },
  { label: "+996", value: "+996" },
  { label: "+1242", value: "+1242" },
  { label: "+1246", value: "+1246" },
  { label: "+1264", value: "+1264" },
  { label: "+1268", value: "+1268" },
  { label: "+1284", value: "+1284" },
  { label: "+1340", value: "+1340" },
  { label: "+1345", value: "+1345" },
  { label: "+1441", value: "+1441" },
  { label: "+1473", value: "+1473" },
  { label: "+1649", value: "+1649" },
  { label: "+1664", value: "+1664" },
  { label: "+1758", value: "+1758" },
  { label: "+1787", value: "+1787" },
  { label: "+1809", value: "+1809" },
  { label: "+1868", value: "+1868" },
  { label: "+1869", value: "+1869" },
  { label: "+1876", value: "+1876" },
  { label: "+7880", value: "+7880" },
  { label: "+90392", value: "+90392" },
];

export const selectedCountryCode = { value: "+1", label: "+1" };

export default countryCodes;
