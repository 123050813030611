import React, { useState, useEffect } from "react";
import Dropdown from "../Dropdown";

// minWidth is a shorterm fix until we can fix the dropdown width issue
export const OptionsDropdown = ({
  defaultValue,
  options,
  callback,
  page,
  reviews,
  minWidth,
  ...props
}) => {
  const [optionsShown, setOptionsShown] = useState();
  const [currentOption, setCurrentOption] = useState();
  const [loading, setLoading] = useState(true);
  const [width, setWidth] = useState();

  useEffect(() => {
    setLoading(true);
    setOptionsShown(
      options || (page && pageSizeOptions) || (reviews && sortReviewsOptions)
    );
    setCurrentOption(
      defaultValue ??
        (options && options[0]) ??
        (page && pageSizeOptions[0]) ??
        (reviews && sortReviewsOptions[0])
    );
    setWidth(minWidth || (page && "100px") || (reviews && "200px"));
    setLoading(false);
  }, [options, defaultValue, page, reviews, minWidth]);

  const handleSelectOption = (selected) => {
    setCurrentOption(selected);
    if (typeof callback === "function") {
      callback(selected?.value || selected);
    }
  };

  if (loading) return null;

  return (
    <Dropdown
      minWidth={width}
      defaultValue={currentOption}
      options={optionsShown}
      onChange={handleSelectOption}
      ariaLabel="options dropdown"
      {...props}
    />
  );
};

const pageSizeOptions = [
  { label: 15, value: 15 },
  { label: 30, value: 30 },
  { label: 60, value: 60 },
];

const sortReviewsOptions = [
  {
    value: "newest",
    label: "Date: Newest",
  },
  {
    value: "oldest",
    label: "Date: Oldest",
  },
  {
    value: "unreplied",
    label: "Status: Unreplied",
  },
  {
    value: "replied",
    label: "Status: Replied",
  },
  {
    value: "highest",
    label: "Rating: Highest",
  },
  {
    value: "lowest",
    label: "Rating: Lowest",
  },
];

export default OptionsDropdown;
