import { countryLabel } from "../constants/countryCodes";

const cleanPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return null;
  let num = phoneNumber.match(/\d+/g);
  if (num) return num.join("");
};

const removeCountryCodeFromPhone = (country, phoneNumber) => {
  if (!phoneNumber || !country) return null;
  const orgCountryValues = countryLabel.find(
    (option) => option.value === country || option.value === country?.value
  );
  const countryCode = orgCountryValues?.countryCode;
  const phone = countryCode?.length
    ? phoneNumber?.substring(countryCode?.length)
    : phoneNumber;
  return phone;
};

const isPhoneNumberValid = (phone) => {
  if (!phone) return true;
  let phoneCleaned = cleanPhoneNumber(phone);
  if (!phoneCleaned) return false;
  return phoneCleaned.length <= 15 && phoneCleaned.length >= 6;
};

const cleanName = (name) => {
  return name.trim().replaceAll(/\s/g, "-");
};

const findCountryCode = (countryCode) => {
  if (!countryCode || countryCode === "") return null;
  return countryLabel.find((option) => option?.countryCode === countryCode);
};

const cleanCountryCode = (countryCode) => {
  if (!countryCode || countryCode === "") return null;
  let value = countryLabel.find((option) => option === countryCode);
  return value?.countryCode;
};

const cleanCountryOfResidence = (countryCode) => {
  if (!countryCode || countryCode === "") return null;
  let value = countryLabel.find((option) => option === countryCode);
  return value?.value;
};

const validateSelectValue = (value, options, required) => {
  let dfValue = "";
  if (value && options.filter((option) => option.value === value).length) {
    dfValue = options.filter((option) => option.value === value)[0];
  } else if (required) {
    dfValue = options[0];
  }
  return dfValue;
};

const isStateRequired = (country) => {
  const countryValue = country?.value;

  // With current rules, USA is only country we require a state to be entered
  // Will need revisiting when expanding beyond USA / France / Spain
  return countryValue === "USA";
};

export {
  cleanPhoneNumber,
  removeCountryCodeFromPhone,
  cleanName,
  cleanCountryCode,
  cleanCountryOfResidence,
  validateSelectValue,
  isPhoneNumberValid,
  findCountryCode,
  isStateRequired,
};
