import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Page, PageLoader } from "../../_components";
import { useAuthAxios } from "../../_hooks";
import { ROUTES } from "../../_routes";
import { history } from "../../_utils";
import useAssessments from "./useAssessments";

const PostAssessmentForm = () => {
  const { id, historyId } = useParams();
  const [{ data, loading, error }] = useAuthAxios(
    {
      url: `courses/view/${id}`,
      method: "GET",
    },
    { useCache: false }
  );

  const course = data?.data;
  const { assessment = [] } = course || {};
  const crumbs = [
    {
      title: "Classes",
      link: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
    },
    {
      title: course?.title || "Loading...",
      link: `${ROUTES.COURSE}/${course?.id}`,
    },
    {
      title: "Post assessments",
      link: null,
    },
  ];

  const goToNextPage = useCallback(() => {
    history.push(`${ROUTES.COURSE}/${id}${ROUTES.LEAVE_REVIEW}`);
  }, [id]);

  const {
    Modal: PostAssessmentModal,
    isCompleted: isCompletedAssessments,
    submitting,
  } = useAssessments({
    assessments: assessment,
    historyId,
    assessmentarea: "POST",
    courseId: id,
  });

  useEffect(() => {
    if (isCompletedAssessments && !loading) {
      goToNextPage();
    }
  }, [isCompletedAssessments, goToNextPage, loading]);

  if (loading) {
    return <PageLoader />;
  }

  if (error) return null;
  return (
    <Page crumbs={crumbs} docTitle={`Post assessments | ${course?.title}`}>
      {!isCompletedAssessments && (
        <PostAssessmentModal submitting={submitting} />
      )}
    </Page>
  );
};

export default PostAssessmentForm;
