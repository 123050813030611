import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { googleApiKey } from "../../../constants";

const GoogleScopes =
  "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.events";

export const useGoogleCalendarAPI = (
  event,
  loginSuccessCallback,
  apiSuccessCallback,
  apiFailureCallback
) => {
  const addEventToGoogleCalendar = ({ access_token }) => {
    loginSuccessCallback?.();
    const url = `https://www.googleapis.com/calendar/v3/calendars/primary/events?key=${googleApiKey}`;
    axios
      .post(url, event, {
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      })
      .then(() => {
        apiSuccessCallback?.();
      })
      .catch((err) => {
        apiFailureCallback?.();
      });
  };

  return useGoogleLogin({
    scope: GoogleScopes,
    onSuccess: addEventToGoogleCalendar,
    onError: apiFailureCallback,
  });
};
