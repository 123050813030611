import { lazy } from "react";
import { ROUTES } from "../constants";

// Non Auth
const ForgotPassword = lazy(() => import("../../Auth/ForgotPassword"));
const Login = lazy(() => import("../../Auth/Login"));
const CorporateLogin = lazy(() => import("../../Auth/CorporateLogin"));
const Register = lazy(() => import("../../Auth/Register"));
const ResetPassword = lazy(() => import("../../Auth/ResetPassword"));
const SingleSignOn = lazy(() => import("../../Auth/SingleSignOn"));
const CustomLanding = lazy(() => import("../../Auth/CustomLanding"));

const non_auth_route_group = [
  {
    path: `${ROUTES.LOGIN}${ROUTES.CODE}/:code`,
    component: CorporateLogin,
  },
  {
    path: ROUTES.LOGIN,
    component: Login,
  },
  {
    path: `${ROUTES.LANDING}/:code`,
    component: CustomLanding,
  },
  {
    path: ROUTES.REGISTER_CORPORATE,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.SUBUSER}/:subUserCode`,
    component: Register,
  },
  {
    path: `${ROUTES.SSO_LOGIN}`,
    component: SingleSignOn,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CODE}/:code`,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CODE}`,
    component: Register,
  },
  {
    path: `${ROUTES.REGISTER}${ROUTES.CUSTOM}/:custom`,
    component: Register,
  },
  {
    path: ROUTES.REGISTER,
    component: Register,
  },

  {
    path: `${ROUTES.RESET_PASSWORD}/:code`,
    component: ResetPassword,
  },
  {
    path: `${ROUTES.PASSWORD_RESET}/:code`,
    component: ResetPassword,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    component: ForgotPassword,
  },
  {
    path: ROUTES.PASSWORD,
    component: ForgotPassword,
  },
];

export default non_auth_route_group;
