import { useEffect, useState, useContext } from "react";
import useAuthAxios from "./useAuthAxios";
import { UserContext } from "../_context/UserContext";

const useGetOrgContacts = (page = 1, term = "", pageSize = 12) => {
  const { userData } = useContext(UserContext);
  const isSubuser = userData?.isSubuser;
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(true);

  const [
    { data, loading: loadingPayload },
  ] = useAuthAxios(
    `contacts/organization/search?page=${page}&page_size=${pageSize}&term=${encodeURIComponent(
      term
    )}`,
    { useCache: false }
  );

  useEffect(() => {
    if (!isSubuser && data && data?.items) {
      const orgContacts = data?.items || [];
      const workingCleanOrgContacts = orgContacts.reduce((acc, current) => {
        if (current?.user?.is_active && current?.user?.id)
          acc.push({
            ...current.user,
            profile: {
              file_avatar: current.file_avatar,
              nickname: current.nickname,
            },
          });
        return acc;
      }, []);
      setContacts(workingCleanOrgContacts);
      setPageCount(data?.meta?.page_count);
    }
    setLoading(false);
  }, [isSubuser, data]);

  return { contacts, loading: loading || loadingPayload, pageCount };
};

export default useGetOrgContacts;
