import { useCallback, useEffect, useMemo, useState } from "react";

import { Col, Row, Heading5 } from "../..";
import Modal from "..";
import Button from "../../Buttons/Button";

import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";

import { googleClientId } from "../../../constants";
import { ROUTES } from "../../../_routes";
import {
  useMicrosoftCalendarAPI,
  useMicrosoftLogin,
  useMsalInstance,
} from "./microsoftHooks";
import { useGoogleCalendarAPI } from "./googleHooks";
import downloadICS from "./appleHooks";
import { useAuthAxios } from "../../../_hooks";
import { authAxios } from "../../../_utils";

const useEvent = (course) => {
  const [{ data: courseData }] = useAuthAxios(
    {
      url: `courses/view/${course?.id}`,
      method: "GET",
    },
    { useCache: false }
  );

  return useMemo(() => {
    const location =
      course?.type === "live" || course?.type === "live_open_door"
        ? `${ROUTES.COURSE}/${course.id}${ROUTES.LIVE}/${course?.schedule?.id}`
        : course?.type === "solo" || course?.type === "group"
        ? `${ROUTES.SCHEDULE}`
        : course?.type === "open_door"
        ? `${ROUTES.COURSE}/${course?.id}${ROUTES.OPEN_DOOR}/${course?.schedule?.id}`
        : `${ROUTES.COURSE}${ROUTES.VIDEO}/${course?.url_slug}`;

    const event = course &&
      course.schedule && {
        description: courseData?.data.description,
        start: {
          dateTime: course.schedule.date_schedule,
        },
        end: {
          dateTime: course.schedule.date_eventends,
        },
        summary: course.title,
        status: "confirmed",
        location: `https://${window.location.host}${location}`,
      };

    return event;
  }, [course, courseData]);
};

const CalendarsModal = ({
  visible,
  event: course,
  closeModal,
  onAddedToBurnAlongCalendar,
}) => {
  const event = useEvent(course);
  const [isAddingToGoogle, setAddingToGoogle] = useState(false);
  const [isAddingToMicrosoft, setAddingToMicrosoft] = useState(false);

  useEffect(() => {
    setAddingToGoogle(false);
    setAddingToMicrosoft(false);
  }, [course]);

  const googleLogin = useGoogleCalendarAPI(
    event,
    () => {
      setAddingToGoogle(true);
    },
    () => {
      handleSuccessToAddCalendar();
    },
    () => {
      setAddingToGoogle(false);
    }
  );
  const handleAddToGoogleCalendar = () => {
    googleLogin();
  };

  const microsoftLogin = useMicrosoftLogin();
  const addToMSCalendar = useMicrosoftCalendarAPI();
  const handleAddToMSCalendar = async () => {
    try {
      await microsoftLogin();
      setAddingToMicrosoft(true);
      addToMSCalendar(event, () => {
        handleSuccessToAddCalendar();
      });
    } catch {
      setAddingToMicrosoft(false);
    }
  };

  const handleDownloadICS = () => {
    downloadICS(event);
    handleSuccessToAddCalendar();
  };

  const closeAndClearLoadingStatus = useCallback(() => {
    setAddingToGoogle(false);
    setAddingToMicrosoft(false);
    closeModal();
  }, [setAddingToGoogle, setAddingToMicrosoft, closeModal]);

  const handleSuccessToAddCalendar = useCallback(() => {
    if (!course.schedule_status && course?.schedule?.id) {
      authAxios
        .post(`courses/liveschedule/${course?.schedule?.id}?type=enroll`)
        .finally(() => {
          closeAndClearLoadingStatus();
          onAddedToBurnAlongCalendar?.();
        });
    } else {
      closeAndClearLoadingStatus();
    }
  }, [closeAndClearLoadingStatus, course, onAddedToBurnAlongCalendar]);

  return (
    <Modal show={visible} onHide={closeModal}>
      <Heading5>Add to my...</Heading5>
      <Row justify="center">
        <Col xs={8} $align="center">
          <Button
            disabled={isAddingToGoogle || isAddingToMicrosoft}
            onClick={handleAddToGoogleCalendar}
            fullWidth
            $mt={4}
          >
            Google Calendar
          </Button>
          {/* <Button disabled onClick={handleAddToMSCalendar} fullWidth $mt={4}>
            Microsoft Calendar
          </Button> */}
          <Button
            disabled={isAddingToGoogle || isAddingToMicrosoft}
            onClick={handleDownloadICS}
            fullWidth
            $width={250}
            $mt={4}
          >
            Apple Calendar
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export const useCalendarsModal = () => {
  const [visible, setVisible] = useState(false);
  const [event, setEvent] = useState();

  const closeModal = () => setVisible(false);

  const openModal = useCallback((event) => {
    setVisible(true);
    setEvent(event);
  }, []);

  const msalInstance = useMsalInstance();

  const Modal = useCallback(
    ({ onAddedToBurnAlongCalendar }) => (
      <MsalProvider instance={msalInstance}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <CalendarsModal
            visible={visible}
            event={event}
            closeModal={closeModal}
            onAddedToBurnAlongCalendar={onAddedToBurnAlongCalendar}
          />
        </GoogleOAuthProvider>
      </MsalProvider>
    ),
    [visible, event, msalInstance]
  );

  return { Modal, openModal };
};
