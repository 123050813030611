import { Row } from "../../Layout";
import { Paragraph2 } from "../../Typography";

import { Icon } from "../../Icon";
import { CircleButton } from "../_style";
import authAxios from "../../../_utils/authAxios";
import history from "../../../_utils/history";
import { ROUTES } from "../../../_routes";
import OptionMenu from "../../OptionMenu";

const ProgramHeroOption = ({ id }) => {
  const handleQuitProgram = () => {
    authAxios
      .post(`programusers/set/${id}?type=unenroll`, {})
      .then(() => history.push(ROUTES.PROGRAMS));
  };

  return (
    <OptionMenu label="program-hero-option">
      <Row
        $align={"center"}
        onClick={handleQuitProgram}
        cursor="pointer"
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            handleQuitProgram();
          }
        }}
      >
        <Paragraph2 weight={"700"}>Quit Program</Paragraph2>
        <CircleButton>
          <Icon name="exit" fill={"white"} />
        </CircleButton>
      </Row>
    </OptionMenu>
  );
};

export default ProgramHeroOption;
