import { Suspense, useContext, useEffect, useState } from "react";
import { Switch } from "react-router-dom";

import { UserContext } from "../_context/UserContext";
import WearableContextProvider from "../_context/WearableContext";
import { auth_pss_route_group, auth_user_route_group } from "./paths";

import ContractAgreement from "../App/_components/ContractAgreement";
import PrivateRoute from "./PrivateRoute";
import PageNotFound from "./PageNotFound";
import { PageLoader } from "../_components";

const AuthRoutes = ({ braze }) => {
  const { userData, loading, fetch } = useContext(UserContext);
  const [authUserRoutes, setAuthUserRoutes] = useState(null);
  const [authPSSRoutes, setAuthPSSRoutes] = useState(null);
  const [loadingRoutes, setLoadingRoutes] = useState(true);

  useEffect(() => {
    if (!userData && !loading) fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (braze && userData?.id) {
      braze.changeUser(userData?.id);
    }
  }, [braze, userData?.id]);

  useEffect(() => {
    if (userData?.id) {
      const isBeta = userData?.isBeta ?? false;
      const features = userData?.features ?? {};
      const auth_user_routes = auth_user_route_group(isBeta, features);
      const userRoutes = auth_user_routes?.filter((route) => route.show);
      const pssRoutes = auth_pss_route_group?.filter((route) => route.show);
      setAuthUserRoutes(
        userRoutes?.map((rt, i) => (
          <PrivateRoute
            key={i}
            exact={rt.exact}
            noShell={rt.noShell}
            path={rt.path}
            component={rt.component}
          />
        ))
      );
      setAuthPSSRoutes(
        pssRoutes?.map((rt, i) => (
          <PrivateRoute
            key={i}
            exact={rt.exact}
            noShell={rt.noShell}
            path={rt.path}
            component={rt.component}
            pss
          />
        ))
      );
      setLoadingRoutes(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData?.id, userData?.isBeta]);

  return (
    <Suspense fallback={<PageLoader height="100vh" bmascot />}>
      <WearableContextProvider>
        <ContractAgreement
          show={userData?.id && !userData?.partner_agreement_validated}
        />
        {loadingRoutes ? (
          <PageLoader height="100vh" bmascot />
        ) : (
          <Switch>
            {authUserRoutes}
            {authPSSRoutes}
            <PageNotFound />
          </Switch>
        )}
      </WearableContextProvider>
    </Suspense>
  );
};

export default AuthRoutes;
