import styled, { createGlobalStyle } from "styled-components";
import Modal from "react-bootstrap/Modal";
import IconButton from "../Icon/IconButton";
import { Button, Row, Col, Heading4, ButtonLink } from "..";

const ModalWrapper = ({
  show,
  onHide,
  loading,
  onAccept,
  onCancel,
  acceptText = "Accept",
  cancelText = "Cancel",
  hideAccept = false,
  title,
  titleAlign,
  formID,
  large,
  largeButtonOnMobile = false,
  acceptButtonLink,
  disabled,
  children,
  pss,
}) => {
  return (
    <>
      <Modal
        centered
        show={show}
        onHide={onHide}
        contentClassName="ba-modal-wrapper"
      >
        <ModalMain
          onHide={onHide}
          loading={loading}
          onAccept={onAccept}
          onCancel={onCancel}
          acceptText={acceptText}
          cancelText={cancelText}
          hideAccept={hideAccept}
          title={title}
          titleAlign={titleAlign}
          formID={formID}
          acceptButtonLink={acceptButtonLink}
          disabled={disabled}
          children={children}
          largeButtonOnMobile={largeButtonOnMobile}
        />
      </Modal>
      <Wrapper $large={large} $pss={pss} />
    </>
  );
};

export const ModalMain = ({
  onHide,
  loading,
  onAccept,
  onCancel,
  acceptText = "Accept",
  cancelText = "Cancel",
  hideAccept = false,
  title,
  titleAlign,
  formID,
  acceptButtonLink,
  disabled,
  children,
  largeButtonOnMobile = false,
}) => (
  <>
    <Row $justify="flex-end" $pr={3} $pt={3}>
      {title && (
        <Col $px={0}>
          <Heading4 weight="700" $pl={8} $pt={4} $textAlign={titleAlign}>
            {title}
          </Heading4>
        </Col>
      )}
      {onHide && (
        <Col xs="auto" $align="flex-end" $px={0}>
          <IconButton
            aria-label="Close"
            onClick={onHide}
            name="times-circle"
            size="1.5em"
            tabIndex={0}
            onKeyPress={(e) => {
              if (e.key === "Enter") {
                onHide();
              }
            }}
          />
        </Col>
      )}
    </Row>
    <Body>{children}</Body>
    {(onAccept || onCancel) && (
      <Row
        $justify={onCancel ? "space-between" : "flex-end"}
        $px={5}
        $py={5}
        $gap={20}
      >
        {onCancel && (
          <ModalButton
            bType="outline"
            onClick={onCancel}
            disabled={loading}
            largeButtonOnMobile={largeButtonOnMobile}
          >
            {cancelText}
          </ModalButton>
        )}
        {acceptButtonLink ? (
          <ButtonLink to={onAccept}>{acceptText}</ButtonLink>
        ) : !hideAccept ? (
          <ModalButton
            onClick={onAccept}
            disabled={loading || disabled}
            type="submit"
            id={`${formID ? formID : "modal"}-submit-btn`}
            form={formID}
            largeButtonOnMobile={largeButtonOnMobile}
          >
            {acceptText}
          </ModalButton>
        ) : null}
      </Row>
    )}
  </>
);

const Wrapper = createGlobalStyle`
  .ba-modal-wrapper {
    border-radius: 24px;
  }
  .modal-dialog {
    max-width: ${({ $large }) => $large && "1000px"};
    ${"" /* width: ${({ $pss }) => $pss && "calc(80% - 7em)"}; */}
    ${"" /* left: ${({ $pss }) => $pss && "7em"}; */}
  }
`;

const Body = styled.div`
  padding: 2rem;
`;

const ModalButton = styled(Button)`
  @media (max-width: 768px) {
    width: ${({ largeButtonOnMobile }) => largeButtonOnMobile && "100%"};
  }
`;

export default ModalWrapper;
