import { useEffect, useState } from "react";
import authAxios from "../_utils/authAxios";

const useGetCourseFormFields = (partnerID = null) => {
  const [fields, setFields] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let params = partnerID ? `?partner_id=${partnerID}` : "";
    authAxios({
      method: "get",
      url: `/courses/create/form/cert${params}`,
    })
      .then((response) => {
        let cloned = response.data.items;
        let has_cert_bio = cloned?.has_cert_bio;
        let categories = cloned?.categories?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let equipment = cloned?.equipment?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let goals = cloned?.goals?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let level = cloned?.level?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let zones = cloned?.zones?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let languageoptions = cloned?.languageoptions?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let instructor = cloned?.instructor?.map((item) => ({
          ...item,
          label: `${item?.first_name || ""} ${item?.last_name || ""}`,
          value: item.id,
        }));
        let organization = cloned?.organization?.map((item) => ({
          ...item,
          label: item.title,
          value: item.id,
        }));
        let partner = cloned?.partner?.title;
        setFields({
          has_cert_bio,
          categories,
          equipment,
          goals,
          level,
          languageoptions,
          zones,
          instructor,
          organization,
          partner,
        });
      })
      .finally(() => setLoading(false));
  }, [partnerID]);

  return { fields, loading };
};

export default useGetCourseFormFields;
