import React, { useEffect, memo, useContext } from "react";
import { connect, useSelector } from "react-redux";
import { Route, useLocation } from "react-router-dom";
import { doLogoutAsync } from "../_redux/actions/auth/doLogoutAsync";
import { doRefreshAsync } from "../_redux/actions/auth/doRefreshAsync";
import AuthLayout from "../App/AuthLayout";
import AuthPssLayout from "../App/AuthPssLayout";
import { UserContext } from "../_context/UserContext";

const PrivateRoute = ({
  component: Component,
  noShell,
  pss,
  doLogout,
  doRefreshToken,
  ...rest
}) => {
  const { userData, loading, fetch } = useContext(UserContext);
  const { token } = useSelector((state) => state.auth);

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    if (!token) {
      localStorage.setItem("redirect", pathname);
      doLogout();
    }
  }, [token, pathname, doLogout]);

  useEffect(() => {
    if (!userData && !loading) {
      fetch();
    }
  }, [userData, loading, fetch]);

  return (
    <Route
      {...rest}
      render={(props) => {
        return noShell ? (
          <Component {...props} />
        ) : pss ? (
          <AuthPssLayout>
            <Component {...props} />
          </AuthPssLayout>
        ) : (
          <AuthLayout>
            <Component {...props} />
          </AuthLayout>
        );
      }}
    />
  );
};

export default connect(null, {
  doLogout: doLogoutAsync,
  doRefreshToken: doRefreshAsync,
})(memo(PrivateRoute));
