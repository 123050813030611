import {
  createContext,
  useEffect,
  useState,
  useContext,
  useMemo,
  useCallback,
} from "react";
import { authAxios } from "../_utils";

// List of features that require user to go to payment screen
export const directPaidStr = "DP";
export const plnStr = "PLN";
export const baPlusStr = "BA+";

export const burnalongPrimeFeature = [plnStr, baPlusStr];

const OrgProFeaturesContext = createContext([]);

const OrgProFeaturesContextProvider = ({ children }) => {
  const [orgProFeaturesList, setOrgProFeaturesList] = useState([]);
  const [loading, setLoading] = useState(true);

  // Check if either BA+ or PLN exists in orgProFeaturesList
  const orgHasPlusFeature = useMemo(
    () =>
      orgProFeaturesList.some((feature) =>
        burnalongPrimeFeature.includes(feature)
      ),
    [orgProFeaturesList]
  );

  const getOrgProFeatures = useCallback(() => {
    setLoading(true);
    authAxios
      .post("/organizationprofeatures/")
      .then((res) => {
        setOrgProFeaturesList(res.data.data.orgprofeatures);
      })
      .finally(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    // Fetch org pro features
    getOrgProFeatures();
  }, [getOrgProFeatures]);

  const contextValues = useMemo(
    () => ({
      orgProFeaturesList,
      loading,
      orgHasPlusFeature,
      fetch: getOrgProFeatures,
    }),
    [orgProFeaturesList, loading, orgHasPlusFeature, getOrgProFeatures]
  );

  return (
    <OrgProFeaturesContext.Provider value={contextValues}>
      {children}
    </OrgProFeaturesContext.Provider>
  );
};

const useOrgProFeatures = () => {
  const context = useContext(OrgProFeaturesContext);
  if (!context) {
    throw new Error("Context ProFeature must be used within a Provider");
  }
  return context;
};

export {
  useOrgProFeatures,
  OrgProFeaturesContext,
  OrgProFeaturesContextProvider,
};
