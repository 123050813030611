import { useEffect, useState } from "react";

import appboy from "@braze/web-sdk";
import { brazeKeys } from "../constants";

const useBraze = () => {
  const [braze, setBraze] = useState(null);
  useEffect(() => {
    appboy.initialize(brazeKeys.API, {
      baseUrl: brazeKeys.SDK,
    });
    appboy.display.automaticallyShowNewInAppMessages();
    appboy.openSession();
    setBraze(appboy);
  }, []);
  return braze;
};

export default useBraze;
