import React, { useContext, useMemo } from "react";
import { Badge as Bdg } from "react-bootstrap";
import styled, { ThemeContext } from "styled-components";
import Icon from "./Icon";
import { Row } from "./Layout";
import { Heading6, Paragraph1, Paragraph2, Paragraph4 } from "./Typography";
import { history } from "../_utils";
import { ROUTES } from "../_routes";
import { baColor } from "../_utils/color";
import { noMargin, noPadding, lineClamp } from "../_theme/general";

const level = {
  sm: 1,
  md: 2,
  lg: 3,
  xlg: 4,
};

const BadgeSize = ({ size = "sm", color, weight = "700", children }) => {
  switch (size) {
    case "sm":
      return (
        <Paragraph4
          mb={"0"}
          cursor="pointer"
          weight={weight}
          color={color}
          $mx={2}
          lineHeight="12"
        >
          {children}
        </Paragraph4>
      );
    case "md":
      return (
        <Paragraph2
          $mb={"0"}
          cursor="pointer"
          weight={weight}
          color={color}
          $mx={2}
          lineHeight="16"
        >
          {children}
        </Paragraph2>
      );
    case "lg":
      return (
        <Paragraph1
          $mb={"0"}
          cursor="pointer"
          weight={weight}
          color={color}
          $mx={2}
          lineHeight="18"
        >
          {children}
        </Paragraph1>
      );
    case "xlg":
      return (
        <Heading6
          $mb={"0"}
          cursor="pointer"
          weight={weight}
          color={color}
          $mx={2}
          lineHeight="20"
        >
          {children}
        </Heading6>
      );
    default:
      return (
        <Paragraph4
          $mb={"0"}
          cursor="pointer"
          weight={weight}
          color={color}
          $mx={2}
          lineHeight="12"
        >
          {children}
        </Paragraph4>
      );
  }
};

const Badge = ({ children, bg, onClick, ...rest }) => {
  const theme = useContext(ThemeContext);
  const backgroundColor = baColor(bg || theme.palette.primaryBlue, theme);
  return (
    <BasicBadge pill $bg={backgroundColor} onClick={onClick} {...rest}>
      {children}
    </BasicBadge>
  );
};

export const BadgeText = ({
  size = "sm",
  textColor,
  title,
  weight,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const tColor = baColor(textColor || theme.palette.neutralWhite, theme);

  return (
    <Badge {...props} $my={1}>
      <Row
        $nowrap
        $pl={2 * level[size]}
        $pr={2.5 * level[size]}
        $py={0.75 * level[size]}
        $align={"center"}
        $justify={"center"}
      >
        <BadgeSize size={size} color={tColor} weight={weight} {...props}>
          {title}
        </BadgeSize>
      </Row>
    </Badge>
  );
};

export const BadgeTextIcon = ({
  size = "sm",
  textColor,
  iconColor,
  iconRightColor,
  icon,
  title,
  iconLeft,
  iconRight,
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const tColor = baColor(textColor || theme.palette.neutralWhite, theme);
  const iColor = baColor(iconColor || theme.palette.brightBlue, theme);
  const iRColor = baColor(iconRightColor || theme.palette.brightBlue, theme);
  return (
    <Badge {...props}>
      <Row
        $nowrap
        $pl={2 * level[size]}
        $pr={2.5 * level[size]}
        $py={0.75 * level[size]}
        $align={"center"}
        $justify={"center"}
      >
        {(iconLeft || icon) && (
          <Icon name={icon} height={`${1.1 * level[size]}em`} fill={iColor} />
        )}

        <BadgeSize size={size} color={tColor} {...props}>
          {` ${title}`}
        </BadgeSize>
        {iconRight && (
          <Icon
            name={iconRight}
            height={`${1.1 * level[size]}em`}
            fill={iRColor}
          />
        )}
      </Row>
    </Badge>
  );
};

// This allows the id of the category to be passed in to get the icon/title
// routes user to category page
export const BadgeCategory = ({
  id,
  category,
  iconRight,
  onClick,
  noLink = false,
  ...props
}) => {
  const routeToCategoryPage = () => {
    if (!noLink)
      history.push(`${ROUTES.COURSES}${ROUTES.CATEGORY}/${category?.id}`);
  };
  return (
    <BadgeTextIcon
      icon={category?.file_image}
      title={category?.title.toUpperCase()}
      onClick={onClick || routeToCategoryPage}
      iconColor={category?.group?.color_icon}
      bg={category?.group?.color_back}
      iconRight={iconRight?.icon}
      iconRightColor={iconRight?.fill}
      id={id || null}
      {...props}
    />
  );
};

// Badge Category Item for search
export const BadgeSearchCategory = ({
  size = "sm",
  id,
  category = [],
  type = "default",
  selected = false,
  textColor,
  iconColor,
  onClick = () => {},
  ...props
}) => {
  const theme = useContext(ThemeContext);
  const tColor = baColor(textColor || theme.palette.neutralWhite, theme);
  const iColor = baColor(iconColor || theme.palette.brightBlue, theme);
  let icon = category?.file_image;
  let title = category?.title;

  return (
    <Badge
      onClick={onClick}
      {...props}
      tabIndex={0}
      onKeyPress={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
    >
      <Row
        $nowrap
        $pl={2 * level[size]}
        $pr={2.5 * level[size]}
        $py={0.75 * level[size]}
        $align={"center"}
        $justify={"center"}
      >
        {selected && type === "default" && (
          <StyledLeftIcon
            name={"check-circle"}
            height={"1.4rem"}
            width={"1.4rem"}
            fill={theme.palette.neutralWhite}
          />
        )}
        <Icon name={icon} height={`${1.1 * level[size]}em`} fill={iColor} />
        <BadgeSize size={size} color={tColor} {...props}>
          {title}
        </BadgeSize>
        {selected && type === "filtered" && (
          <StyledRightIcon
            name={"times-circle"}
            height={"1.4rem"}
            width={"1.4rem"}
            fill={theme.palette.neutralWhite}
          />
        )}
      </Row>
    </Badge>
  );
};

export const CategoriesMap = ({
  data,
  limit,
  clampHeight,
  noLink = false,
  badgeEntityId,
  ...props
}) => {
  const categories = useMemo(
    () =>
      data?.slice(0, limit || data?.length).map((category, index) => {
        return (
          <BadgeCategory
            key={category.id}
            id={
              `categoryId-${category.id}-badgeEntityId-${badgeEntityId}` || null
            }
            category={category}
            size="sm"
            $mr={(data?.length || limit) !== index && 3}
            $mb={(data?.length || limit) > 1 && 3}
            noLink={noLink}
          />
        );
      }),
    [data, limit, noLink, badgeEntityId]
  );

  if (!categories?.length) return null;
  return (
    <GeneralWrapper $clampHeight={clampHeight} {...props}>
      {categories}
    </GeneralWrapper>
  );
};

const GeneralWrapper = styled.div`
  ${noPadding}
  ${noMargin}
  ${lineClamp}
  overflow: hidden;
`;

const BasicBadge = styled(Bdg)`
  ${noMargin}
  background-color: ${({ $bg }) => $bg};
  width: fit-content;
  &:hover {
    cursor: ${(props) => props.onClick && "pointer"};
  }
`;

const StyledRightIcon = styled(Icon)`
  margin-left: 0.3rem;
`;

const StyledLeftIcon = styled(Icon)`
  margin-right: 0.3rem;
`;

export default Badge;
