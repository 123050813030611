import { useEffect } from "react";
import { initializeGA, trackPage } from "../_utils/analytics";
import history from "../_utils/history";

export function useGoogleAnalytics() {
  useEffect(() => {
    initializeGA();
  }, []);

  useEffect(() => {
    // Track initial url upon page load
    trackPage(window.location.pathname);
    // Whenever the user navigates track the new url
    return history.listen((location) => {
      trackPage(location.pathname);
    });
  }, []);
}
