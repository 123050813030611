import styled from "styled-components";
import { Heading5, Paragraph2, Paragraph3 } from "../Typography";
import CardBase from "./CardBase";
import { LevelRating, LiveClassOverlay } from "./_components";
import { lineClamp, ellipsis } from "../../_theme/general";
import { Col } from "../Layout";
import { history } from "../../_utils";
import { ROUTES } from "../../_routes";
import { Row } from "..";
import { CategoriesMap } from "../Badge";

const FeedCard = ({ feed, parentPage, ...rest }) => {
  const course = feed?.course;
  const srcImage = course?.file_poster;

  const handleOnClick = () => {
    const pathname = `${ROUTES.COURSE}/${course?.id}`;
    const breadcrumbs = history.location.pathname?.split("/") || [];
    history.push({
      pathname: pathname,
      state: { from: parentPage, breadcrumbs },
    });
  };

  return (
    <CardBase
      image={srcImage}
      overlayCentered={<LiveClassOverlay data={course} />}
      onClick={handleOnClick}
      forceRadius
      {...rest}
    >
      <Row justify="center">
        <Col xs={12}>
          <Paragraph3 textAlign="center" $amt={1} $ellipsis $cursor="pointer">
            {feed?.user?.profile?.nickname}
          </Paragraph3>
        </Col>
        <Col xs={12}>
          <Paragraph3 textAlign="center" $cursor="pointer">
            Viewed
          </Paragraph3>
        </Col>
      </Row>
      <LevelRating data={course} />
      <TitleContent
        $cursor="pointer"
        weight="700"
        $amt={2}
        title={course?.title}
        tabIndex={0}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            handleOnClick && handleOnClick();
          }
        }}
      >
        {course?.title}
      </TitleContent>
      <CategoriesMap
        data={course?.categories}
        limit={4}
        $mb={3}
        badgeEntityId={`feed-card-${course?.id || null}`}
      />
      <InstructorContent>
        <Paragraph3 $cursor="pointer" weight="500" color="grey3">
          Instructor
        </Paragraph3>
        <Paragraph2 $cursor="pointer" weight="700" $mt={1}>
          {course?.instructor?.first_name} {course?.instructor?.last_name}
        </Paragraph2>
      </InstructorContent>
    </CardBase>
  );
};

const InstructorContent = styled(Col)`
  margin-top: auto;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
  justify-content: flex-end;
`;

const TitleContent = styled(Heading5)`
  ${lineClamp}
  ${ellipsis}
  height: 2.6em;
  margin-bottom: 1rem;
  font-weight: 700;
  cursor: ${({ $cursor }) => $cursor};
`;

export default FeedCard;
