import moment from "moment";

export const USER_REQUEST = "auth/USER_REQUEST";
export const USER_SUCCESS = "auth/USER_SUCCESS";
export const USER_FAILURE = "auth/USER_FAILURE";
export const LOGOUT = "auth/LOGOUT";

export const PLN_ORG_VIEW_REQUEST = "pln/ORG_VIEW_REQUEST";
export const PLN_ORG_VIEW_SUCCESS = "pln/ORG_VIEW_SUCCESS";
export const PLN_ORG_VIEW_FAILURE = "pln/ORG_VIEW_FAILURE";

export const PLN_USER_VIEW_REQUEST = "pln/USER_VIEW_REQUEST";
export const PLN_USER_VIEW_SUCCESS = "pln/USER_VIEW_SUCCESS";
export const PLN_USER_VIEW_FAILURE = "pln/USER_VIEW_FAILURE";

export const initialState = {
  loading: false,
  userData: {},
  errors: null,
  plnData: {
    org: {
      loading: false,
      data: {},
      errors: null,
    },
    user: {
      loading: false,
      data: {},
      errors: null,
    },
  },
};

export const userContextReducer = (state = initialState, action) => {
  const { data, errors } = action;
  switch (action.type) {
    case USER_REQUEST:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case USER_SUCCESS:
      return {
        ...state,
        loading: false,
        userData: {
          ...data,
          age: data?.birthday
            ? moment(new Date()).diff(new Date(data.birthday), "years")
            : null,
        },
        errors: null,
      };
    case USER_FAILURE:
      return {
        ...state,
        loading: false,
        userData: {},
        errors,
      };
    case PLN_ORG_VIEW_REQUEST:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          org: {
            ...state.plnData.org,
            loading: true,
            errors: null,
          },
        },
      };
    case PLN_ORG_VIEW_SUCCESS:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          org: {
            data,
            loading: false,
            errors: null,
          },
        },
      };
    case PLN_ORG_VIEW_FAILURE:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          org: {
            data: {},
            loading: false,
            errors,
          },
        },
      };
    case PLN_USER_VIEW_REQUEST:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          user: {
            ...state.plnData.user,
            loading: true,
            errors: null,
          },
        },
      };
    case PLN_USER_VIEW_SUCCESS:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          user: {
            data,
            loading: false,
            errors: null,
          },
        },
      };
    case PLN_USER_VIEW_FAILURE:
      return {
        ...state,
        plnData: {
          ...state.plnData,
          user: {
            data: {},
            loading: false,
            errors,
          },
        },
      };
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
};
