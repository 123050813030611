import { useRef, memo } from "react";
import styled from "styled-components";
import Image from "react-bootstrap/Image";

import FALLBACK_COMMUNITY_IMG from "../../_media/images/Burnalong_Icon_MasterBlue.png";
import { ellipsis, lineClamp } from "../../_theme/general";
import { Heading5, Paragraph2, Heading6, Row } from "..";
import CardBase from "./CardBase";
import { history } from "../../_utils";
import { ROUTES } from "../../_routes";
import { useMedia } from "../../_hooks";

const CommunityCard = ({
  community,
  parentPage,
  type = "left-right",
  ...rest
}) => {
  const ref = useRef();
  const smallViewport = useMedia("(min-width: 768px)");

  const memberFormat = (num) => {
    return Math.abs(num) > 999
      ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
      : Math.sign(num) * Math.abs(num);
  };
  const memberCount = `${memberFormat(community?.members_total)} Members`;

  const handleOnClick = () => {
    const linkState = { from: parentPage };
    const communityLink = `${ROUTES.COMMUNITY}/${community.id}`;
    history.push({ pathname: communityLink, state: linkState });
  };

  if (!community) return null;

  return (
    <CardBase
      type={smallViewport ? type : "top-bottom"}
      onClick={handleOnClick}
      image={
        smallViewport && type !== "top-bottom"
          ? "Custom"
          : community.file || FALLBACK_COMMUNITY_IMG
      }
      forceRadius
      {...rest}
    >
      <Row>
        {smallViewport && type !== "top-bottom" && (
          <ImageCover
            src={community.file || FALLBACK_COMMUNITY_IMG}
            ref={ref}
            onError={() => (ref.current.src = FALLBACK_COMMUNITY_IMG)}
            alt=""
          />
        )}
        <CommunityBody
          mode={smallViewport && type !== "top-bottom" ? "large" : "small"}
        >
          <LabelContent>Community</LabelContent>
          <TitleContent
            $amt={2}
            weight={700}
            title={community?.title}
            tabIndex={0}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleOnClick && handleOnClick();
              }
            }}
          >
            {community?.title}
          </TitleContent>
          <MemberList weight={700}>{memberCount}</MemberList>
        </CommunityBody>
      </Row>
    </CardBase>
  );
};

const CommunityBody = styled(Heading5)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: ${({ theme }) => theme.components.card.padding}px;
  max-width: ${({ mode }) => (mode === "small" ? "100" : "50")}%;
  cursor: pointer;
  div {
    cursor: pointer;
  }
`;

const LabelContent = styled(Paragraph2)`
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.palette.blue001};
  margin-bottom: 1rem;
  flex-direction: column-reverse;
`;

const TitleContent = styled(Heading5)`
  ${ellipsis}
  ${lineClamp}
  color: ${({ theme }) => theme.palette.primaryBlue};
  margin-bottom: 1rem;
`;

const MemberList = styled(Heading6)`
  margin-top: auto;
`;

const ImageCover = styled(Image)`
  object-fit: cover;
  width: 224px;
  height: 100%;
  min-height: 226px;
  border-radius: 24px 0 0 24px;
  background-color: ${({ theme }) => theme.palette.baseTeal};
  flex: 0;
`;

export default memo(CommunityCard);
