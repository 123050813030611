import styled from "styled-components";
import CardBase from "./CardBase";
import { Heading5, Paragraph2 } from "../Typography";
import { Col, Row } from "../Layout";
import { Avatar } from "../ui/Avatars";
import { Button } from "../Buttons/Button";
import { lineClamp, ellipsis } from "../../_theme/general";
import { history } from "../../_utils";
import { ROUTES } from "../../_routes";
import { useGetFollows } from "../../_hooks";

const InstructorCard = ({ instructor, showFollow, callback, ...rest }) => {
  const { follow, handleFollowClick } = useGetFollows(instructor);

  const handleOnClick = (e) => {
    history.push(`${ROUTES.INSTRUCTOR}/${instructor.id}`);
  };

  return (
    <CardBase onClick={handleOnClick} forceRadius image={"Custom"} {...rest}>
      <Row $px={4} $py={4} justify={"center"} $align="center">
        <Col xs={4}>
          <Avatar center src={instructor?.profile?.file_avatar} size="md" />
        </Col>
        <Col xs={8}>
          <Name color="primary" aria={follow.aria}>
            {instructor?.first_name} {instructor?.last_name}
          </Name>
          {instructor.profile?.languageoptions && (
            <Paragraph2 color="primary" $mt={1} $mb={2}>
              Language: {instructor.profile?.languageoptions.title}
            </Paragraph2>
          )}
          <Paragraph2 color="primary" $mt={1} $mb={2}>
            {follow?.count} {follow?.text}
          </Paragraph2>
          {showFollow && (
            <Button
              onClick={(e) => {
                e.stopPropagation();
                handleFollowClick();
                if (callback) {
                  setTimeout(() => {
                    callback();
                  }, [500]);
                }
              }}
              dark
              bType="outline"
              disabled={follow?.loading}
              small
              padding={follow?.following ? "6px 12px" : "6px 32px"}
              leftIcon={follow?.following ? "check-circle" : null}
              iconColor="primary"
              aria-label={
                follow?.following
                  ? `Unfollow ${instructor?.first_name || ""} ${
                      instructor?.last_name || ""
                    }`
                  : `Follow ${instructor?.first_name || ""} ${
                      instructor?.last_name || ""
                    }`
              }
            >
              {follow?.following ? "Unfollow" : "Follow"}
            </Button>
          )}
        </Col>
      </Row>
    </CardBase>
  );
};

const Name = styled(Heading5)`
  ${lineClamp}
  ${ellipsis}
  font-weight: bold;
`;

export default InstructorCard;
