import { Suspense, memo } from "react";
import { Router, Switch, Route } from "react-router-dom";

import UserContextProvider from "../_context/UserContext";
import { OrgProFeaturesContextProvider } from "../_context/OrgProFeaturesContext";
import history from "../_utils/history";
import { ROUTES } from "./constants";
import useBraze from "../_hooks/useBraze";
import { useGoogleAnalytics } from "../GoogleAnalytics/useGoogleAnalytics";
import useWatchdog from "../Watchdog/useWatchdog";
import { non_auth_route_group } from "./paths";

import AuthRoutes from "./AuthRoutes";
import PageLoader from "../_components/PageLoader";
import ScrollToTop from "../_components/ScrollToTop";

const Routes = () => {
  const braze = useBraze();

  useWatchdog();
  // this then should be moved elsewhere bc it is rerendering our site every 30 seconds
  useGoogleAnalytics();

  const nonAuthRoutes = non_auth_route_group?.map((rt, i) => (
    <Route
      key={i}
      exact={rt?.exact}
      path={rt?.path}
      component={rt?.component}
    />
  ));

  return (
    <Router history={history}>
      <ScrollToTop />

      <Suspense fallback={<PageLoader height="100vh" />}>
        <Switch>
          {/* Non Authorized Routes */}
          {nonAuthRoutes}
          {/* Authorized Routes */}
          <UserContextProvider>
            <OrgProFeaturesContextProvider>
              <AuthRoutes braze={braze} />
            </OrgProFeaturesContextProvider>
          </UserContextProvider>

          {/* Errors */}
          <Route path="*">
            <div
              className="d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <h1>404 Error - Page not found :(</h1>
            </div>
          </Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export { Routes, ROUTES };

export default memo(Routes);
