export const ICONS = {
  ANGLE_DOWN: "angle-down",
  ANGLE_LEFT: "angle-left",
  ANGLE_RIGHT: "angle-right",
  ANGLE_UP: "angle-up",
  AWARD: "award",
  BACKWARDS: "backwards",
  BARS: "bars",
  BELL: "bell",
  BELL_OUTLINE: "bell-outline",
  BIKE: "bike",
  BLOOD_PRESSURE: "blood-pressure",
  BOOK: "book",
  BROADCAST_TOWER: "broadcast-tower",
  BULLSEYE: "bullseye",
  BAWORDMARK: "burnalong-wordmark",
  CALENDAR: "calendar",
  CALENDAR_ALT: "calendar-alt",
  CALENDAR_TIMES: "calendar-times",
  CARET_DOWN: "caret-down",
  CARET_LEFT: "caret-left",
  CARET_RIGHT: "caret-right",
  CARET_UP: "caret-up",
  CHAMPION: "champion",
  CHART_LINE: "chart-line",
  CHECK: "check",
  CHECK_CIRCLE: "check-circle",
  CHECK_CIRCLE_outline: "check-circle-outline",
  CHECK_OUTLINE_RED: "check-outline-red",
  CHECK_OUTLINE_GREEN: "check-outline-green",
  CHECKLIST: "checklist",
  CLAP: "clap",
  CLAP_OUTLINE: "clap-outline",
  CLOCK: "clock",
  CLOCK_OUTLINE: "clock-outline",
  COG: "cog",
  COMMENT: "comment",
  COMMENT_OUTLINE: "comment-outline",
  COMMUNITY: "community",
  COMPRESS: "compress",
  COOKING_POT: "cooking-pot",
  EDIT: "edit",
  ELLIPSIS_V: "ellipsis-v",
  ELLIPSIS_H: "ellipsis-h",
  ENVELOPE: "envelope",
  ENVELOPE_OUTLINE: "envelope-outline",
  EXCLAMATION: "exclamation",
  EXCLAMATION_TRIANGLE: "exclamation-triangle",
  EXPAND: "expand",
  EYE: "eye",
  DUMBBELL: "dumbbell",
  EXERCISE: "exercise",
  FACEBOOK: "facebook",
  FACEBOOK_SQUARE: "facebook-square",
  FAMILY: "family",
  FILE_MEDICAL_alt: "file-medical-alt",
  FISH: "fish",
  FLAG: "flag",
  FLOSS: "floss",
  FRUITS_VEGGIES: "fruits-veggies",
  GLASS_WATER: "glass-water",
  GLUCOSE: "glucose",
  GLOBE: "globe",
  GOOGLE: "google",
  GROUP: "group",
  HEART: "heart",
  HEART_OUTLINE: "heart-outline",
  HEART_RATE: "heart-rate",
  HOME: "home",
  INFO: "info",
  INSTAGRAM: "instagram",
  INSTAGRAM_SQUARE: "instagram-square",
  INSTRUCTOR: "instructor",
  JOURNAL: "journal",
  KIDS: "kids",
  LEVEL_1: "level-1",
  LEVEL_2: "level-2",
  LEVEL_3: "level-3",
  LEVELS: "levels",
  LINKEDIN: "linkedin",
  LINKEDIN_SQUARE: "linkedin-square",
  LIST: "list",
  LIST_UL: "list-ul",
  LIVE: "live",
  LUNCH: "lunch",
  LUNGS: "lungs",
  MAP_MARKER_ALT: "map-marker-alt",
  MEDAL: "medal",
  MICROPHONE: "microphone",
  MICROPHONE_SLASH: "microphone-slash",
  MINUS: "minus",
  MINUS_CIRCLE: "minus-circle",
  MINUS_SQUARE: "minus-square",
  MINUS_SQUARE_outline: "minus-square-outline",
  NO_DESSERT: "no-dessert",
  NO_JUNK_food: "no-junk-food",
  NO_MEAT: "no-meat",
  NO_PHONE: "no-phone",
  NO_SODA: "no-soda",
  OUTDOORS: "outdoors",
  OXYGEN: "oxygen",
  PAUSE: "pause",
  PLAY: "play",
  PLAY_CIRCLE: "play-circle",
  PLAY_CIRCLE_outline: "play-circle-outline",
  PHONE: "phone",
  PLUS: "plus",
  PLUS_CIRCLE: "plus-circle",
  PLUS_SQUARE: "plus-square",
  PLUS_SQUARE_outline: "plus-square-outline",
  PROGRESS: "progress",
  REGISTERED: "registered",
  RUNNING: "running",
  SALAD: "salad",
  SEARCH: "search",
  SEARCH_EMPTY: "search-empty",
  SHARE: "share",
  SLEEP: "sleep",
  SMILE: "smile",
  SMILE_OUTLINE: "smile-outline",
  SOLO: "solo",
  STAIRS: "stairs",
  STAR: "star",
  STAR_HALF: "star-half",
  STAR_OUTLINE: "star-outline",
  STEPS: "steps",
  STONKS: "stonks",
  STRETCH: "stretch",
  SWIM: "swim",
  TH_LARGE: "th-large",
  THUMBS_UP: "thumbs-up",
  THUMBS_UP_OUTLINE: "thumbs-up-outline",
  TIMES: "times",
  TIMES_CIRCLE: "times-circle",
  TIMES_CIRCLE_OUTLINE: "times-circle-outline",
  TRASH: "trash",
  TROPHY: "trophy",
  TWITTER: "twitter",
  TWITTER_SQUARE: "twitter-square",
  UNIVERSAL_ACCESS: "universal-access",
  USER: "user",
  USERS: "users",
  USER_TIE: "user-tie",
  USER_COG: "user-cog",
  USER_PLUS: "user-plus",
  USER_OUTLINE: "user-outline",
  VIDEO: "video",
  VIDEO_SLASH: "video-slash",
  VOLUME_MUTE: "volume-mute",
  VOLUME: "volume",
  WALK: "walk",
  WAVE: "wave",
};

export default ICONS;
