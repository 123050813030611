import { memo } from "react";

const ChallengeTargetDescription = ({ challenge }) => {
  const method =
    challenge?.challenge?.method ||
    challenge?.teamchallenge?.method ||
    challenge?.method;
  const target =
    challenge?.challenge?.target ||
    challenge?.teamchallenge?.target ||
    challenge?.target;
  const metric =
    challenge?.challenge?.metric ||
    challenge?.teamchallenge?.metric ||
    challenge?.metric;
  const habit = challenge?.challenge?.habit?.title || "";
  const habitsMetric =
    challenge?.challenge?.habit?.group?.title === "Physical activities"
      ? "minutes"
      : "counts";

  if (method === undefined) {
    return `This challenge has no goal yet.`;
  } else {
    switch (method) {
      case "fastest":
        if (metric === "wellness_habit") {
          return `Fastest to ${target} ${habitsMetric} in ${habit}`;
        } else if (metric === "burnalong_points")
          return `Fastest to ${target} points`;
        else return `Fastest to ${target} ${metric}`;
      case "highest":
        if (metric === "wellness_habit") {
          return `Reach the most ${habitsMetric} in ${habit}`;
        } else if (metric === "burnalong_points")
          return `Reach the most points`;
        else return `Reach the most ${metric}`;
      default:
        return null;
    }
  }
};

export default memo(ChallengeTargetDescription);
