import { useMemo } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { authAxios } from "../../_utils";
import updateHomework from "../Homework/updateHomework";
import AssessmentModal from "./AssessmentModal";

const useAssessments = ({
  assessments = [],
  defaultAssessmentIndex = 0,
  historyId = undefined,
  assessmentarea = "PRE",
  courseId = undefined,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  // Homeworks
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);

  const availableAssessments = useMemo(() => {
    return assessments.filter(
      (assessment) =>
        assessment.assessment_area === "BOTH" ||
        assessment.assessment_area === assessmentarea
    );
  }, [assessments, assessmentarea]);

  const [currentAssessmentIndex, setCurrentAssessmentIndex] = useState(
    defaultAssessmentIndex
  );
  const currentAssessment = availableAssessments[currentAssessmentIndex];
  const isCompleted = useMemo(() => {
    if (availableAssessments.length === 0) return true;
    else
      return (
        availableAssessments.length &&
        currentAssessmentIndex >= availableAssessments.length
      );
  }, [availableAssessments, currentAssessmentIndex]);

  const isLast = useMemo(
    () => currentAssessmentIndex === availableAssessments.length - 1,
    [currentAssessmentIndex, availableAssessments.length]
  );

  const hasPreAssessment = useMemo(() => {
    return availableAssessments?.filter(
      (assessment) => assessment?.assessment_area === "PRE"
    );
  }, [availableAssessments]);

  const handleHomeworkComplete = useCallback(() => {
    // isLast - if last assessment
    // courseId - if course id is available
    // hasSubmittedOnce - if homework has not been updated yet

    // Checks conditions and update homework if met
    // If the current section is PRE and there is a PRE assessment or if the current section is POST
    if (
      isLast &&
      courseId &&
      !hasSubmittedOnce &&
      ((hasPreAssessment?.length > 0 && assessmentarea === "PRE") ||
        assessmentarea === "POST")
    ) {
      updateHomework({
        course_id: courseId,
        module_type: "assessment",
      });
      setHasSubmittedOnce(true);
    }
  }, [isLast, courseId, hasSubmittedOnce, hasPreAssessment, assessmentarea]);

  const completeAssessment = useCallback(
    async (selectedValues) => {
      const keys = Object.keys(selectedValues);

      if (keys.length > 0) {
        const data = keys.map((key) => selectedValues[key]);

        const user_resp = {
          assessmentarea,
          assessmentanswer: data,
        };

        setSubmitting(true);
        try {
          await authAxios({
            method: "post",
            url: `reviews/assessment/add/${historyId}`,
            data: user_resp,
          }).then(() => {
            handleHomeworkComplete();
          });
        } catch {
          console.log("An error occurred while submitting assessment");
        }
      }
      setSubmitting(false);
      setShowModal(false);
      setCurrentAssessmentIndex((prev) => prev + 1);
    },
    [historyId, assessmentarea, handleHomeworkComplete]
  );

  useEffect(() => {
    if (currentAssessment && !isCompleted) {
      setTimeout(() => {
        setShowModal(true);
      }, 100);
    }
  }, [currentAssessment, isCompleted]);

  const Modal = useCallback(
    ({ submitting }) => (
      <AssessmentModal
        open={showModal}
        isLast={isLast}
        submitting={submitting}
        assessment={currentAssessment}
        onComplete={completeAssessment}
      />
    ),
    [showModal, currentAssessment, completeAssessment, isLast]
  );

  return {
    Modal,
    isCompleted,
    submitting,
  };
};

export default useAssessments;
