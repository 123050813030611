import Modal from ".";
import { Paragraph2 } from "..";

const AlertModal = ({
  show,
  setShow,
  loading,
  title,
  body,
  onAccept,
  onCancel,
  acceptText,
  cancelText,
}) => {
  const handleClose = (e) => {
    e?.stopPropagation();
    setShow(false);
  };

  if (!show || loading) return null;
  return (
    <Modal
      show={show}
      onHide={handleClose}
      title={title}
      onAccept={onAccept}
      onCancel={onCancel || handleClose}
      acceptText={acceptText || "Accept"}
      cancelText={cancelText || "Cancel"}
    >
      <Paragraph2>{body}</Paragraph2>
    </Modal>
  );
};

export default AlertModal;
