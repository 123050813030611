const { useState, useMemo } = require("react");

const useQueryParams = ({
  pageSize = 12,
  sortOrderOptions = null,
  isSearchable = false,
}) => {
  // Default Query Params
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState(sortOrderOptions?.[0]);

  // PSS Query Params
  const [search, setSearch] = useState("");

  //   Pagination
  const paginationQueryParams = useMemo(
    () => `page=${page}&page_size=${pageSize}`,
    [page, pageSize]
  );
  //   Sort Order
  const sortQueryParams = useMemo(
    () => (sortOrder ? `&sort=${sortOrder.value}` : ""),
    [sortOrder]
  );

  // Search
  const searchQueryParams = useMemo(
    () => (isSearchable ? `&search=${search}` : ""),
    [search, isSearchable]
  );
  //   All Query Params
  const queryParams = `${paginationQueryParams}${sortQueryParams}${searchQueryParams}`;

  return {
    page,
    setPage,
    paginationQueryParams,
    sortOrder,
    setSortOrder,
    sortQueryParams,
    search,
    setSearch,
    searchQueryParams,
    queryParams,
  };
};

export default useQueryParams;
