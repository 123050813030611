import {
  createContext,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  memo,
  useState,
} from "react";
import { datadogRum } from "@datadog/browser-rum";

import { authAxios } from "../_utils";
import {
  userContextReducer,
  initialState,
  USER_REQUEST,
  USER_SUCCESS,
  USER_FAILURE,
  PLN_ORG_VIEW_FAILURE,
  PLN_ORG_VIEW_SUCCESS,
  PLN_ORG_VIEW_REQUEST,
  PLN_USER_VIEW_FAILURE,
  PLN_USER_VIEW_SUCCESS,
  PLN_USER_VIEW_REQUEST,
} from "./reducers/userContextReducer";
import { flagFeatures, GROUP_TYPES, isDatadogEnvironment } from "../constants";
import { useIsMounted } from "../_hooks";

export const UserContext = createContext();

const identifyDatadogSession = (user, organization) => {
  if (isDatadogEnvironment) {
    datadogRum.setUser({
      id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email,
      groups: user.groups,
      org_id: organization.id,
      org_title: organization.title,
    });
  }
};

const UserContextProvider = ({ children }) => {
  const isMounted = useIsMounted();
  const [{ userData, loading, errors, plnData }, dispatch] = useReducer(
    userContextReducer,
    initialState
  );
  const [retryCount, setRetryCount] = useState(0);
  const [hasFailed, setHasFailed] = useState(true);

  const getPlnOrg = useCallback(() => {
    dispatch({
      type: PLN_ORG_VIEW_REQUEST,
    });
    authAxios
      .get("plnorgs/view")
      .then((response) => {
        const { errors: err } = response;
        if (err) {
          throw dispatch({ type: PLN_ORG_VIEW_FAILURE, errors: err });
        }
        dispatch({
          type: PLN_ORG_VIEW_SUCCESS,
          data: response.data,
        });
      })
      .catch((er) => {
        dispatch({ type: PLN_ORG_VIEW_FAILURE, errors: er.errors });
      });
  }, []);

  const getPlnUser = useCallback(() => {
    dispatch({
      type: PLN_USER_VIEW_REQUEST,
    });
    authAxios
      .get("plnusers/view")
      .then((response) => {
        const { errors: err } = response;
        if (err) {
          throw dispatch({ type: PLN_USER_VIEW_FAILURE, errors: err });
        }

        dispatch({
          type: PLN_USER_VIEW_SUCCESS,
          data: response.data,
        });
      })
      .catch((er) => {
        dispatch({ type: PLN_USER_VIEW_FAILURE, errors: er.errors });
      });
  }, []);

  const getUserData = useCallback(() => {
    dispatch({ type: USER_REQUEST });
    setRetryCount(retryCount + 1);
    setHasFailed(false);
    authAxios
      .get("profiles/user")
      .then((response) => {
        const { errors: err } = response;
        if (err) {
          throw dispatch({ type: USER_FAILURE, errors: err });
        }
        let cloned = response.data.data;
        let { user, organization } = response.data.data;
        delete cloned.user;
        cloned.features = getFeaturesIncluded(organization?.features);
        cloned.isDirectUser = organization?.id === 1;
        let groups = getGroupsIncluded(user.groups);
        identifyDatadogSession(user, organization);
        dispatch({
          type: USER_SUCCESS,
          data: { ...cloned, ...user, ...groups },
        });
      })
      .catch((er) => {
        setHasFailed(true);
        dispatch({ type: USER_FAILURE, errors: er.errors });
      });
  }, [retryCount]);

  useEffect(() => {
    // Ensure component is mounted first
    // Will refetch user data a couple times if it has failed to fetch
    if (isMounted && hasFailed && retryCount < 2) getUserData();
  }, [getUserData, isMounted, hasFailed, retryCount]);

  useEffect(() => {
    if (userData?.id) {
      getPlnOrg();
      getPlnUser();
    }
  }, [userData?.id, getPlnOrg, getPlnUser]);

  const contextValue = useMemo(
    () => ({
      userData,
      loading,
      fetch: getUserData,
      errors,

      plnData,
      fetchPln: {
        org: getPlnOrg,
        user: getPlnUser,
      },
    }),
    [userData, loading, getUserData, errors, plnData, getPlnOrg, getPlnUser]
  );

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const mapFeatures = (value, features) => {
  switch (value.trim()) {
    case "branding" || flagFeatures.BRANDING:
      return (features[flagFeatures.BRANDING] = true);
    case "challenges" || flagFeatures.CHALLENGES:
      return (features[flagFeatures.CHALLENGES] = true);
    case "champion" || flagFeatures.CHAMPION:
      return (features[flagFeatures.CHAMPION] = true);
    case "communities" || flagFeatures.COMMUNITIES:
      return (features[flagFeatures.COMMUNITIES] = true);
    case "courseHistory" || flagFeatures.COURSEHISTORY:
      return (features[flagFeatures.COURSEHISTORY] = true);
    case "goals" || flagFeatures.GOALS:
      return (features[flagFeatures.GOALS] = true);
    case "healthMetrics" || flagFeatures.HEALTHMETRICS:
      return (features[flagFeatures.HEALTHMETRICS] = true);
    case "notifications" || flagFeatures.NOTIFICATIONS:
      return (features[flagFeatures.NOTIFICATIONS] = true);
    case "programs" || flagFeatures.PROGRAMS:
      return (features[flagFeatures.PROGRAMS] = true);
    case "pssprograms" || flagFeatures.PSS_PROGRAMS:
      return (features[flagFeatures.PSS_PROGRAMS] = true);
    case "teamchallenges" || flagFeatures.TEAMCHALLENGES:
      return (features[flagFeatures.TEAMCHALLENGES] = true);
    case "playlists" || flagFeatures.PLAYLISTS:
      return (features[flagFeatures.PLAYLISTS] = true);
    case "calendar" || flagFeatures.CALENDAR:
      return (features[flagFeatures.CALENDAR] = true);
    case "livecalendar" || flagFeatures.LIVECALENDAR:
      return (features[flagFeatures.LIVECALENDAR] = true);
    case "assessments" || flagFeatures.ASSESSMENTS:
      return (features[flagFeatures.ASSESSMENTS] = true);
    case "homeworks" || flagFeatures.HOMEWORKS:
      return (features[flagFeatures.HOMEWORKS] = true);
    default:
      return;
  }
};

const getFeaturesIncluded = (orgFeatures) => {
  let features = {
    branding: false,
    challenges: false,
    champion: false,
    communities: false,
    courseHistory: false,
    goals: false,
    healthMetrics: false,
    notifications: false,
    programs: false,
    pssprograms: false,
    teamChallenges: false,
    playlists: false,
    calendar: false,
    livecalendar: false,
    assessments: false,
    homeworks: false,
  };
  const includedFeatures = orgFeatures?.split(",") || [];
  includedFeatures?.forEach((item) => mapFeatures(item, features));
  return features;
};

const mapPassedUserGroups = (value) => {
  switch (value) {
    case GROUP_TYPES.ADMIN:
      return "isAdmin";
    case GROUP_TYPES.BETA:
      return "isBeta";
    case GROUP_TYPES.CHAMPION:
      return "isChampion";
    case GROUP_TYPES.INSTRUCTOR:
      return "isInstructor";
    case GROUP_TYPES.MANAGER:
      return "isManager";
    case GROUP_TYPES.OWNER:
      return "isOwner";
    case GROUP_TYPES.SUBUSER:
      return "isSubuser";
    case GROUP_TYPES.USER:
      return "isUser";
    default:
      break;
  }
};

const getGroupsIncluded = (userGroups) => {
  let groups = {
    isUser: false,
    isAdmin: false,
    isLead: false,
    isTrial: false,
    isInstructor: false,
    isManager: false,
    isDemo: false,
    isOperation: false,
    isChampion: false,
    isOwner: false,
    isSubuser: false,
    isBeta: false,
    isClient: false,
  };

  userGroups.map((group) => {
    let field = mapPassedUserGroups(group);
    if (field) {
      groups[field] = true;
    }
    return group;
  });
  return groups;
};

export default memo(UserContextProvider);
