import { useContext } from "react";
import { ThemeContext } from "styled-components";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";

import CardBase from "./CardBase";
import { HeroBaseCardWrapper } from "../Hero/_cards";
import { Col, Icon, Button, Heading5, Row, Paragraph3 } from "..";
import {
  ProgramHeroOption,
  LevelRow,
  CardTitle,
  InstructorCardRow,
} from "./_components";
import { BarChart } from "../Chart";
import { history } from "../../_utils";
import { ROUTES } from "../../_routes";
import { daysPerWeek } from "../../Programs/_utils";
import CardStyle, { customBuildStyles } from "./_style";

const ProgramCard = ({
  data,
  type,
  chartType = "circle",
  from,
  width,
  onClick,
  enrolled,
  flipped = false,
  ...rest
}) => {
  const themeContext = useContext(ThemeContext);
  const progressBuildStyled = customBuildStyles(themeContext);
  const language = data?.languageoptions?.title || "English";

  const handleOnClick = () => {
    history.push({
      pathname: `${ROUTES.PROGRAM}/${data?.id}`,
      state: { from },
    });
  };
  const valueRemaining = Math.floor(
    data?.remaining >= 0 && data?.courses_total >= 0
      ? Math.abs(data.courses_total - data.remaining)
      : 0
  );

  const value = enrolled
    ? {
        valueRemaining,
        circleText: valueRemaining,
        text: `of ${data?.courses_total} classes complete`,
      }
    : {
        valueRemaining: 0,
        circleText: data?.courses_total,
        text: `Classes`,
      };

  const percentage =
    Math.floor((value.valueRemaining / data?.courses_total) * 100) || 0;

  return (
    <CardBase
      flipped={flipped}
      flippedContent={`${data.title} has been added to your programs`}
      type={type}
      onClick={onClick ? onClick : handleOnClick}
      image={data?.file_poster}
      {...rest}
    >
      <LevelRow data={data} />
      <CardTitle
        type={type}
        title={data?.title}
        onClick={onClick ? onClick : handleOnClick}
      />
      <InstructorCardRow data={data} program />
      {chartType === "circle" && (
        <CardStyle.ProgressContent>
          <CardStyle.ProgressView>
            <CircularProgressbarWithChildren
              value={percentage}
              strokeWidth="10"
              styles={buildStyles(progressBuildStyled)}
            >
              <CardStyle.ProgressText>
                {value?.circleText}
              </CardStyle.ProgressText>
            </CircularProgressbarWithChildren>
          </CardStyle.ProgressView>
          <Col
            style={{ justifyContent: "space-between", flexDirection: "row" }}
          >
            <CardStyle.ProgressText>{value?.text}</CardStyle.ProgressText>
            {language !== "English" && <Paragraph3>{language}</Paragraph3>}
          </Col>
        </CardStyle.ProgressContent>
      )}
      {chartType === "bar" && (
        <BarChart
          data={data}
          label={`${value.valueRemaining} ${value.text}`}
          lineValue={percentage}
        />
      )}
      <CardStyle.BottomContent>
        <Row>
          <Icon name={"calendar"} width={20} height={18} />
          <CardStyle.BottomDateText>
            {daysPerWeek(data)}
          </CardStyle.BottomDateText>
        </Row>
        {data?.avg_minutes > 0 && (
          <CardStyle.BottomDateText>
            ~{data?.avg_minutes}mins
          </CardStyle.BottomDateText>
        )}
      </CardStyle.BottomContent>
    </CardBase>
  );
};

export const ProgramHeroCard = ({ data, enrolled }) => {
  const themeContext = useContext(ThemeContext);
  const progressBuildStyled = customBuildStyles(themeContext, "general");

  const onJoinClick = () => {
    history.push(`${ROUTES.PROGRAM}${ROUTES.ENROLL}/${data?.id}`);
  };

  return (
    <HeroBaseCardWrapper>
      {enrolled && <ProgramHeroOption id={data?.id} />}
      <Heading5 weight="700" $mb={4}>
        {data?.title}
      </Heading5>
      {enrolled ? (
        <BarChart
          data={data}
          label={`${data?.complete || 0} of ${
            data?.courses_total
          } classes complete`}
          lineValue={Math.floor(
            ((data?.complete || 0) / data?.courses_total) * 100
          )}
        />
      ) : (
        <>
          <CardStyle.ProgressContent>
            <CardStyle.ProgressView>
              <CircularProgressbarWithChildren
                value={0}
                maxValue={1}
                strokeWidth="12"
                styles={buildStyles(progressBuildStyled)}
              >
                <CardStyle.ProgressText>
                  {data?.courses_total}
                </CardStyle.ProgressText>
              </CircularProgressbarWithChildren>
            </CardStyle.ProgressView>
            <CardStyle.ProgressText>{`Classes`}</CardStyle.ProgressText>
          </CardStyle.ProgressContent>
          <Button dark fullWidth onClick={onJoinClick} $mt={3}>
            Join Program
          </Button>
        </>
      )}
    </HeroBaseCardWrapper>
  );
};

export default ProgramCard;
