import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";

import { authAxios, convertDuration, history } from "../../_utils";
import { ROUTES } from "../../_routes";
import { UserContext } from "../../_context/UserContext";
import { useLiveClassStatus } from "../_cards/_utils";

import {
  Paragraph2,
  Paragraph3,
  Paragraph4,
  Heading1,
  Heading4,
  Button,
  BUTTON_TYPE,
  Col,
  Row,
  ShareCourseLink,
} from "..";
import { LevelRating, LiveButton } from "../_cards/_components";
import { TitleContent } from "../_cards/ClassCard";
import { PageCard } from "..";
import { CategoriesMap } from "../Badge";
import FavoriteACourse from "../FavoriteACourse";
import Icon from "../Icon";
import { useCalendarsModal } from "../Modal/CalendarsModal";

export const HomeHeroCard = ({ course, onClick, isOverlayBar }) => {
  return (
    <HeroBaseCardWrapper $mb={0}>
      <LevelRating data={course} />
      <TitleContent
        tabIndex={0}
        $amt={2}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            onClick && onClick();
          }
        }}
      >
        {course?.title}
      </TitleContent>
      <CategoriesMap
        data={course?.categories}
        $my={6}
        limit={4}
        badgeEntityId={`hero-base-card-${
          isOverlayBar ? "overlay-bar" : "no-overlay-bar"
        }-${course?.id || null}`}
      />
      <Col $p={0}>
        <Paragraph3 weight="500" color="grey3">
          Instructor
        </Paragraph3>
        <Paragraph2 weight="700" $mt={1}>
          {course?.instructor?.first_name} {course?.instructor?.last_name}
        </Paragraph2>
      </Col>
    </HeroBaseCardWrapper>
  );
};

export const HeroBaseCardWrapper = styled(PageCard)`
  margin-bottom: 0px !important;
  width: ${({ $live }) => $live && "80% !important"};
  min-width: ${({ $large }) => $large && "300px"};
  max-width: 400px;
  margin-left: ${({ $live }) => $live && "32px"};
  @media screen and (max-width: 768px) {
    max-width: 100%;
    border-radius: 0px !important;
    width: 100% !important;
    margin-left: 0 !important;
    // z-index is set to 3 to make sure the card is on top of the video
    z-index: 3;
  }
`;

export const OptionsCard = ({ identifier, course, parentPage = "home" }) => {
  const handleJoinClass = (e) => {
    e?.stopPropagation();
    history.push({
      pathname: `${ROUTES.COURSE}/${course?.id}${ROUTES.VIDEO}`,
      state: { from: parentPage, identifier: identifier },
    });
  };

  const handlePreviewClass = (e) => {
    e?.stopPropagation();
    const breadcrumbs = history.location.pathname?.split("/") || [];
    history.push({
      pathname: `${ROUTES.COURSE}/${course?.id}`,
      state: { from: parentPage, identifier: identifier, breadcrumbs },
    });
  };
  return (
    <Row $align={"center"} justify={"space-between"}>
      <Button
        onClick={(e) => handlePreviewClass(e)}
        $mr={3}
        dark={false}
        bType={"outline"}
      >
        Class Info
      </Button>
      <Button onClick={(e) => handleJoinClass(e)}>I'm ready!</Button>
    </Row>
  );
};

export const CourseHeroCard = ({ course, logState }) => {
  const handleJoinClass = () => {
    history.push({
      pathname: `${ROUTES.COURSE}/${course?.id}${ROUTES.VIDEO}`,
      state: logState,
    });
  };

  const handleScheduleClass = () => {
    history.push(`${ROUTES.COURSE}/${course?.id}${ROUTES.SCHEDULE}`);
  };

  return (
    <HeroBaseCardWrapper title={course?.title} amt={2} ellipsis>
      <Button
        dark
        fullWidth
        onClick={handleJoinClass}
        my={3}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleJoinClass();
          }
        }}
      >
        Let's go!
      </Button>
      <Button
        dark
        fullWidth
        bType={BUTTON_TYPE.outline}
        onClick={handleScheduleClass}
        tabIndex={0}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            handleScheduleClass();
          }
        }}
      >
        Schedule
      </Button>
    </HeroBaseCardWrapper>
  );
};

export const LiveCourseHeroCard = ({
  course,
  logState,
  openCalendarsModal,
}) => {
  const live =
    (course?.type === "live" || course?.type === "live-video") &&
    course?.schedule?.id;
  return (
    <HeroBaseCardWrapper $live>
      <Row $align={"center"}>
        <LiveNowEllipsis mr={2}>•</LiveNowEllipsis>
        <Paragraph2>LIVE</Paragraph2>
      </Row>
      <Heading4 $amt={2} $ellipsis>
        {course?.title}
      </Heading4>
      <LiveButton
        live={live}
        course={course}
        logState={logState}
        openCalendarsModal={openCalendarsModal}
      />
    </HeroBaseCardWrapper>
  );
};

export const WebinarHeroCard = ({ webinar, webinar_id }) => {
  const [webinarUser, setWebinarUser] = useState(null);
  const canHopIn = useLiveClassStatus(webinar?.date_scheduled);

  const todaysDate = moment();
  // Difference between today (-10 minutes offset or duration of webinar) and the date of the course
  const difference =
    webinar?.duration_mins > 0 ? webinar.duration_mins * 60000 : 600000;
  const pastDue = todaysDate.diff(webinar?.date_scheduled) - difference > 0;

  const handleJoinClass = ({ link = webinar?.url_webinar, type }) => {
    const updateData = {
      webinar_id: webinar_id,
      [type]: true,
    };
    if (webinarUser?.id) {
      authAxios({
        method: "POST",
        url: `webinarusers/flags`,
        data: updateData,
      });
    }
    window.open(link);
  };

  useEffect(() => {
    if (webinar_id) {
      authAxios({
        method: "GET",
        url: `webinarusers/details/${webinar_id}`,
      }).then((res) => {
        setWebinarUser(res.data.data);
      });
    }
  }, [webinar_id]);

  return (
    <HeroBaseCardWrapper $large>
      <Row $align={"space-between"}>
        <Paragraph4 weight={700}>Event</Paragraph4>
        <EventTime weight={700} color={"primary"}>
          {moment(webinar?.date_scheduled).format("ddd, MM/DD • LT")}
        </EventTime>
      </Row>
      <Heading4 $amt={2} $ellipsis $my={2}>
        {webinar?.title}
      </Heading4>
      {webinar?.duration_mins > 0 && (
        <Paragraph3 color="gray">
          Duration: {convertDuration(webinar?.duration_mins)}
        </Paragraph3>
      )}

      <Row $mt={4}>
        {webinar?.url_registration && (
          <Button
            dark
            fullWidth
            $my={2}
            onClick={() =>
              handleJoinClass({
                type: "registered",
                link: webinar?.url_registration,
              })
            }
          >
            Take me to register!
          </Button>
        )}
        {!pastDue && canHopIn && webinar?.url_webinar && (
          <Button
            dark
            fullWidth
            onClick={() => handleJoinClass({ type: "joined" })}
          >
            Join Event
          </Button>
        )}
      </Row>
    </HeroBaseCardWrapper>
  );
};

export const OpenDoorCourseHeroCard = ({ course, logState, refetchCourse }) => {
  const { userData } = useContext(UserContext);
  const { id: userID } = userData;
  const isEventHost = course?.schedule?.host === userID;
  const eventId = course?.schedule?.id;
  const [canJoin, setCanJoin] = useState(true);
  const canHopIn = useLiveClassStatus(course?.schedule?.date_schedule);
  const showEventTime = !canHopIn && course?.schedule?.date_schedule;
  const {
    // Modal: CalendarsModal,
    openModal: openCalendarsModal,
  } = useCalendarsModal();

  const todaysDate = moment();
  const isOngoing = moment(course?.schedule?.date_eventends).isAfter(
    todaysDate
  );
  // Difference between today (-10 minutes offset) and the date of the course
  const pastDue = todaysDate.diff(course?.schedule?.date_schedule) - 600000 > 0;

  const handleJoinClass = (solo = false) => {
    history.push({
      pathname:
        solo || showEventTime
          ? `${ROUTES.COURSE}/${course?.id}${ROUTES.VIDEO}`
          : `${ROUTES.COURSE}${ROUTES.VIDEO}/${course?.url_slug}`,
      state: logState,
    });
  };

  const handleRedirectToCourse = () => {
    history.push(`${ROUTES.COURSE}/${course?.id}`);
  };

  const handleScheduleClass = () => {
    history.push(
      `${ROUTES.COURSE}/${course?.id}${ROUTES.SCHEDULE}/${course?.schedule?.id}`
    );
  };

  const handleJoinSchedule = () => {
    authAxios.post(`courses/liveschedule/${eventId}?type=enroll`).then(() => {
      setCanJoin(false);
    });
  };

  const handleJoinHopIn = () => {
    if (canHopIn) handleJoinClass();
    else handleJoinSchedule();
  };

  const handleOpenCalendarsModal = (e) => {
    e.stopPropagation();
    openCalendarsModal?.(course);
  };

  return (
    <HeroBaseCardWrapper $large>
      <Row $align={"space-between"}>
        <Icon name="open_door" height="1em" width="1em" />
        <Paragraph4 weight={700}>OPEN DOOR</Paragraph4>
        <EventTime weight={700} color={showEventTime ? "primary" : "red"}>
          {(pastDue && !isOngoing) || showEventTime ? (
            moment(course?.schedule?.date_schedule).format("ddd, MM/DD • LT")
          ) : (
            <>
              <Icon name="live" fill="liveRed" height="1em" width="1em" />
              NOW
            </>
          )}
        </EventTime>
      </Row>
      <Heading4 $amt={2} $ellipsis>
        {course?.title}
      </Heading4>
      {course?.host_name && (
        <Paragraph3 color="gray">
          Scheduled by {course?.host_name?.split("?")[1]}
        </Paragraph3>
      )}
      {pastDue && !isOngoing ? (
        <Button $mt={3} dark fullWidth onClick={handleRedirectToCourse}>
          View Course
        </Button>
      ) : isEventHost ? (
        <>
          <Button dark fullWidth onClick={() => handleJoinClass()} $my={3}>
            {canHopIn ? "Hop in" : "Let's go!"}
          </Button>
          {!canHopIn && (
            <ScheduleRow>
              <Button
                dark
                bType="outline"
                style={{ flex: 1 }}
                onClick={handleScheduleClass}
              >
                Edit Schedule
              </Button>
              {/* <IconButton
                name="calendar"
                fill="white"
                circle
                $ml={4}
                padding="8px"
                onClick={handleOpenCalendarsModal}
              /> */}
            </ScheduleRow>
          )}
        </>
      ) : (
        <Row>
          {(course?.schedule?.joined_class || !canJoin) && !canHopIn ? (
            <ScheduleRow>
              <Button bType="outline" style={{ flex: 1 }} $my={3}>
                <Icon name="check-circle" fill="green" height="1em" />
                Your spot is reserved!
              </Button>
              {/* <IconButton
                name="calendar"
                fill="white"
                circle
                $ml={4}
                padding="8px"
                onClick={handleOpenCalendarsModal}
              /> */}
            </ScheduleRow>
          ) : (
            <ScheduleRow>
              <Button
                dark
                style={{ flex: 1 }}
                onClick={handleJoinHopIn}
                $my={3}
              >
                {canHopIn ? "Hop in" : "Schedule"}
              </Button>
              {/* <IconButton
                name="calendar"
                fill="white"
                circle
                $ml={4}
                padding="8px"
                onClick={handleOpenCalendarsModal}
              /> */}
            </ScheduleRow>
          )}
          <Button
            dark
            fullWidth
            bType="outline"
            onClick={() => handleJoinClass(true)}
          >
            {canHopIn ? "Start as solo" : "Start now"}
          </Button>
        </Row>
      )}
      {/* <CalendarsModal onAddedToBurnAlongCalendar={refetchCourse} /> */}
    </HeroBaseCardWrapper>
  );
};

export const CourseOptionsCard = ({ course }) => {
  if (course.type === "live") return null;

  return (
    <Row>
      <FavoriteACourse course={course} />
      <ShareCourseLink course={course} />
    </Row>
  );
};

const LiveNowEllipsis = styled(Heading1)`
  color: ${(props) => props.theme.palette.liveRed};
  line-height: 16px;
`;

const EventTime = styled(Paragraph4)`
  margin-left: auto;
`;

const ScheduleRow = styled(Row)`
  justify-content: center;
  align-items: center;
  width: 100%;
`;
