import { zones } from ".";
import { ROUTES } from "../_routes";

export const breadcrumbHistory = ({
  breadcrumbs = [],
  eventId = false,
  categoryMap = [],
}) => {
  const categoryList = categoryMap?.flatMap((category) => category?.categories);
  const categoryDict = categoryList?.reduce(
    (a, x) => ({ ...a, [x?.id]: x }),
    {}
  );

  // New or Popular or Recommendations
  const fromNew = breadcrumbs.includes("new");
  const fromPopular = breadcrumbs.includes("popular");
  const fromRecommendations = breadcrumbs.includes("recommendations");

  // Public Live
  const fromLive =
    breadcrumbs.includes("live") && !breadcrumbs.includes("private");

  // Private Live or Private Videos
  const fromPrivateVideos = breadcrumbs.includes("private");
  const fromPrivateLive =
    breadcrumbs.includes("live") && breadcrumbs.includes("private");
  const fromPrivateLiveOpenDoor =
    breadcrumbs.includes("live-open-door") && breadcrumbs.includes("private");

  // Grabs category number and title
  const fromCategory = breadcrumbs.includes("category");
  const categoryNumber =
    fromCategory && breadcrumbs[breadcrumbs.indexOf("category") + 1];
  const categoryTitle = categoryNumber && categoryDict?.[categoryNumber]?.title;

  // Grabs zone number and title
  const fromZone = breadcrumbs.includes("zone");
  const zoneNumber = fromZone && breadcrumbs[breadcrumbs.indexOf("zone") + 1];
  const zoneTitle = zoneNumber && zones[zoneNumber]?.title;

  // Program Classes
  const fromProgram = breadcrumbs.includes("program");
  const programNumber =
    fromProgram && breadcrumbs[breadcrumbs.indexOf("program") + 1];
  const programTitle =
    (fromProgram && breadcrumbs[breadcrumbs.indexOf("programTitle") + 1]) ||
    "Program";

  // Playlist Classes
  const fromPlaylist = breadcrumbs.includes("playlist");
  const playlistNumber =
    fromPlaylist && breadcrumbs[breadcrumbs.indexOf("playlist") + 1];
  const playlistTitle =
    (fromPlaylist && breadcrumbs[breadcrumbs.indexOf("playlistTitle") + 1]) ||
    "Playlist";

  // Calendar
  const fromCalendar = breadcrumbs.includes("calendar");

  // MyProfile - MySchedule, MyPrograms, history, or favorites
  const fromSchedule = breadcrumbs.includes("schedule");
  const fromPrograms = breadcrumbs.includes("programcourses");
  const fromFavorites = breadcrumbs.includes("favorites");
  const fromHistory = breadcrumbs.includes("history");

  if (breadcrumbs.length === 0)
    return [
      {
        title: "Home",
        link: `${ROUTES.DASHBOARD}`,
      },
      {
        title: "Category",
        link: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
      },
    ];

  return [
    breadcrumbs.includes("") && {
      title: "Home",
      link: `${ROUTES.DASHBOARD}`,
    },
    // Classes
    fromPopular && {
      title: "Popular Classes",
      link: `${ROUTES.COURSES}${ROUTES.POPULAR}`,
    },
    fromNew && {
      title: "New Classes",
      link: `${ROUTES.COURSES}${ROUTES.NEW}`,
    },
    fromRecommendations && {
      title: "Recommended Classes",
      link: `${ROUTES.COURSES}${ROUTES.RECOMMENDATIONS}`,
    },
    fromLive && {
      title: "Live Classes",
      link: `${ROUTES.COURSES}${ROUTES.LIVE}`,
    },
    fromPrivateVideos &&
      !fromPrivateLive &&
      !fromPrivateLiveOpenDoor && {
        title: "Private Videos Classes",
        link: `${ROUTES.COURSES}${ROUTES.PRIVATE}`,
      },
    fromPrivateLive && {
      title: "Private Live Classes",
      link: `${ROUTES.COURSES}${ROUTES.PRIVATE}${ROUTES.LIVE}`,
    },
    fromPrivateLiveOpenDoor && {
      title: "Private Live Open Door Classes",
      link: `${ROUTES.COURSES}${ROUTES.PRIVATE}${ROUTES.LIVE_OPEN_DOOR}`,
    },
    fromSchedule &&
      eventId && {
        title: "Live Classes",
        link: `${ROUTES.COURSES}${ROUTES.LIVE}`,
      },
    // Categories
    fromCategory && {
      title: "Categories",
      link: `${ROUTES.COURSES}${ROUTES.CATEGORIES}`,
    },
    categoryTitle && {
      title: categoryTitle,
      link: `${ROUTES.COURSES}${ROUTES.CATEGORY}/${categoryNumber}`,
    },
    // Zones
    fromZone && {
      title: "Zones",
      link: `${ROUTES.COURSES}${ROUTES.ZONES}`,
    },
    zoneTitle && {
      title: zoneTitle,
      link: `${ROUTES.COURSES}${ROUTES.ZONE}/${zoneNumber}`,
    },
    // Program Classes
    fromProgram && {
      title: "Programs",
      link: `${ROUTES.PROGRAMS}`,
    },
    fromProgram && {
      title: programTitle,
      link: `${ROUTES.PROGRAM}/${programNumber}`,
    },
    // Playlist Classes
    fromPlaylist && {
      title: "My Playlists",
      link: `${ROUTES.PLAYLIST}`,
    },
    fromPlaylist && {
      title: playlistTitle,
      link: `${ROUTES.PLAYLIST}/${playlistNumber}`,
    },
    // Calendar
    fromCalendar && {
      title: "Burnalong Calendar",
      link: `${ROUTES.CALENDAR}`,
    },
    // My Profile - Schedule, Programs, history, or favorites
    (fromSchedule || fromPrograms || fromFavorites || fromHistory) && {
      title: "My Profile",
      link: `${ROUTES.PROFILE}`,
    },
    fromSchedule && {
      title: "My Schedule",
      link: `${ROUTES.SCHEDULE}`,
    },
    fromPrograms && {
      title: "My Programs",
      link: `${ROUTES.PROFILE}${ROUTES.SCHEDULE}`,
    },
    fromHistory && {
      title: "My History",
      link: `${ROUTES.HISTORY}`,
    },
    fromFavorites && {
      title: "My Favorites",
      link: `${ROUTES.FAVORITES}`,
    },
  ];
};
