import React from "react";
import Select from "react-select";
import styled from "styled-components";
import { baColor } from "../../_utils/color";
import { noMargin } from "../../_theme/general";

const BASelect = styled(Select)`
  & .Select__control {
    width: ${(props) => props.width};
    min-width: ${(props) => props.minWidth};
    background-color: ${({ controlBgColor, $form, theme }) =>
      $form
        ? theme.palette.neutralGrey
        : baColor(controlBgColor || "transparent", theme)};
    border-color: ${({ controlBorder, $form, theme, $dark }) =>
      $form
        ? theme.palette.grey002
        : baColor(
            controlBorder || $dark ? theme.palette.primaryBlue : "white",
            theme
          )};
    border-radius: ${({ $form }) => ($form ? "8px" : "24px")};
    border-width: ${({ $form }) => ($form ? ".25px" : "2px")} !important;
    cursor: pointer;
    padding-left: 16px;
    ${noMargin}
  }

  & .Select__control--is-focused {
    box-shadow: ${({ theme, $dark }) =>
      "inset 0 0 0 2px" + $dark ? theme.palette.primaryBlue : "white"};
    border-color: transparent;
    border-style: none;
  }

  & .Select__value-container--has-value {
    justify-content: ${({ $form }) => ($form ? "" : "center")};
  }

  & .Select__single-value {
    font-size: 18px;
    font-weight: ${({ $form }) => ($form ? 400 : 700)};
    color: ${({ theme, $dark }) =>
      $dark ? theme.palette.primaryBlue : "white"} !important;
  }

  & .Select__indicator-separator {
    display: none;
  }

  & .Select__dropdown-indicator {
    color: ${({ theme, $dark }) =>
      $dark ? theme.palette.primaryBlue : "white"} !important;
  }

  & .Select__menu-list {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: transparent;
    border-radius: 24px;
  }
  & .Select__menu {
    border-radius: 24px;
    border: none;
    box-shadow: 0px 18px 24px rgba(0, 0, 0, 0.17);
    transition: box-shadow 300ms ease;
  }

  & .Select__option {
    font-weight: 700;
    font-size: 16px;
    color: ${({ theme }) => theme.palette.primaryBlue};
    padding: 16px 24px;
  }

  & .Select__option--is-focused {
    background-color: ${({ theme }) => theme.palette.primaryBlue} !important;
    color: ${({ theme }) => theme.palette.neutralWhite};
    height: 50px;
    &:first-child {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
    &:last-child {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }
    &:active {
      background-color: ${({ theme }) => theme.palette.primaryBlue} !important;
      color: ${({ theme }) => theme.palette.neutralWhite};
    }
  }

  & .Select__option--is-selected {
    background-color: ${({ theme }) => theme.palette.primaryBlue} !important;
    color: ${({ theme }) => theme.palette.neutralWhite};
    height: 50px;
    &:first-child {
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
    }
    &:last-child {
      border-bottom-left-radius: 24px;
      border-bottom-right-radius: 24px;
    }
  }
`;

const Dropdown = ({ form, dark = true, onChange, ariaLabel, ...rest }) => {
  return (
    <BASelect
      classNamePrefix="Select"
      $form={form}
      $dark={dark}
      onChange={(e, action) => onChange && onChange(e, action)}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen && "rotate(180deg)",
        }),
      }}
      aria-label={ariaLabel || null}
      role="combobox"
      {...rest}
    />
  );
};

export default Dropdown;
