import React, { useState, useMemo, memo, useContext } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

import { ROUTES } from "../_routes";

import { Container } from "../_components/Layout";
import useIsMounted from "../_hooks/useIsMounted";
import PageLoader from "../_components/PageLoader";
import Footer from "./Shell/Footer";
import Header from "./Shell/Header";
import CoBrandBar from "./Shell/CoBrandBar";
import { UserContext } from "../_context/UserContext";
import {
  directPaidStr,
  plnStr,
  useOrgProFeatures,
} from "../_context/OrgProFeaturesContext";

export const AuthLayout = ({ children }) => {
  const isMounted = useIsMounted();
  const { userData, loading } = useContext(UserContext);
  const {
    orgProFeaturesList,
    loading: orgprofeaturesLoading,
  } = useOrgProFeatures();
  const [menuOpen, setMenuOpen] = useState(false);

  const {
    isAdmin,
    isInstructor,
    isManager,
    isOwner,
    isUser,
    is_active: isActive,
    organization,
    partner_registration_status,
    partner_onboarding_status,
  } = userData;
  const hasNotPaidForAccess = userData?.subscription?.type === "trial";

  // Checks if org has any of the paid features
  // directPaidStr imported from _components/_utils, values "DP"
  const orgHasPaidFeatures = orgProFeaturesList?.includes(directPaidStr);

  // Checks if user has a paid digital subscription
  const paidDigitalSub =
    userData?.new_subscription?.filter((sub) => sub.plan_type === "digital")
      .length > 0;

  const hasPLN = orgProFeaturesList?.includes(plnStr);
  // If org has paid features and user doesn't have a paid digital subscription
  // and user doesn't have PLN, user has to pay
  const userHasToPay = orgHasPaidFeatures && !paidDigitalSub && !hasPLN;

  const isOwnerPartner = isOwner && !partner_registration_status;
  const isInstructorPartner = isInstructor && !partner_registration_status;
  const isPartnerOnboarding =
    (isOwner || isInstructor) && !partner_onboarding_status;

  const isPssOnly = useMemo(
    () =>
      Boolean((isOwner || isInstructor || isManager) && !isUser && isActive),
    [isActive, isInstructor, isManager, isOwner, isUser]
  );
  const isAllowOnWell = useMemo(
    () => Boolean((isAdmin || isUser) && isActive),
    [isActive, isAdmin, isUser]
  );

  // make sure page doesn't execute on components until everything is mounted
  if (!isMounted) return null;
  return loading || !userData || orgprofeaturesLoading ? (
    <PageLoader height="100vh" />
  ) : isOwnerPartner || isInstructorPartner ? (
    <Redirect to={`${ROUTES.PARTNER}${ROUTES.NEW}`} />
  ) : isPartnerOnboarding ? (
    <Redirect to={ROUTES.ONBOARDING} />
  ) : userHasToPay ? (
    <Redirect to={ROUTES.SUBSCRIPTIONS_NEW} />
  ) : hasNotPaidForAccess && !paidDigitalSub ? (
    // TODO: Remove Old subscriptions? or do we need fallback for BT users who unsubbed
    <Redirect to={ROUTES.SUBSCRIPTIONS} />
  ) : // TODO: Do we want to redirect users to onboarding if not completed?
  isPssOnly ? (
    <Redirect to={ROUTES.PSS} />
  ) : isAllowOnWell ? (
    <>
      <Wrapper fluid $menuOpen={menuOpen}>
        <Header setMenuOpen={() => setMenuOpen()} />
        {organization?.show_banner && <CoBrandBar />}
        {children}
        <Footer />
      </Wrapper>
    </>
  ) : (
    <Redirect to={ROUTES.LOGIN} />
  );
};

const Wrapper = styled(Container)`
  padding: 0;
  background-color: ${({ theme }) => theme.palette.neutralGrey};
  position: fixed;
  height: 100%;
  overflow-y: ${({ $menuOpen }) => ($menuOpen ? "hidden" : "auto")};
`;

export default memo(AuthLayout);
