import { Row, Col, Paragraph2 } from "..";

const RowData = ({
  label,
  value,
  clickable,
  link,
  valueComp,
  labelSize = { md: 4, lg: 3 },
  valueSize = { md: 8, lg: 9 },
}) => {
  return (
    <Row $mb={3}>
      <Col
        md={labelSize.md}
        lg={labelSize.lg}
        color={value ? "primary" : "grey2"}
      >
        <Paragraph2
          weight="700"
          id={`${label.replaceAll(" ", "-").toLowerCase()}-label`}
        >
          {label}:
        </Paragraph2>
      </Col>
      <Col
        md={valueSize.md}
        lg={valueSize.lg}
        $mb={3}
        id={`${label.replaceAll(" ", "-").toLowerCase()}-value`}
      >
        {clickable && value ? (
          <a href={link}>{value}</a>
        ) : value ? (
          <Paragraph2 color={value ? "primary" : "grey2"}>
            {value || "None"}
          </Paragraph2>
        ) : valueComp ? (
          valueComp
        ) : null}
      </Col>
    </Row>
  );
};

export default RowData;
