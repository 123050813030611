import { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { Paragraph1 } from "../../_components";
import { GroupedQuestion } from "./Question";

const Assessment = ({
  assessment,
  question,
  onChangeSelectedValues = () => {},
}) => {
  const {
    form_layout = "horizontal_scale",
    title: questionTitle,
    values,
  } = question;

  const [selectedValues, selectValue] = useState([]);
  const onClickValue = useCallback((id) => {
    selectValue((values) => {
      if (values.find((v) => v === id)) {
        return [];
      }
      return [id];
    });
  }, []);

  useEffect(() => {
    onChangeSelectedValues(question.id, selectedValues);
  }, [question, onChangeSelectedValues, selectedValues]);

  const groupedValues = values?.reduce((acc, current) => {
    const index = acc.findIndex((c) => c[0]?.subtext === current.subtext);
    if (index >= 0) {
      acc[index].push(current);
      return [...acc];
    } else {
      acc.push([current]);
      return [...acc];
    }
  }, []);

  return (
    <StyledAssessmentContainer layout={form_layout}>
      <StyledQuestionTitle layout={form_layout}>
        {questionTitle}
        {!assessment.skippable ? "*" : ""}
      </StyledQuestionTitle>
      <StyledAssessmentContent layout={form_layout}>
        {groupedValues?.map((values, index) => (
          <GroupedQuestion
            key={index}
            layout={form_layout}
            values={values}
            selectedValues={selectedValues}
            onClickValue={onClickValue}
          />
        ))}
      </StyledAssessmentContent>
    </StyledAssessmentContainer>
  );
};

const StyledAssessmentContainer = styled.div`
  width: 100%;
`;

const StyledAssessmentContent = styled.div`
  display: flex;
  overflow: auto;
  ${({ layout }) => {
    if (layout === "horizontal_scale") {
      return `
        flex-direction: row;
        gap: 12px;
      `;
    } else if (layout === "vertical_scale") {
      return `
        flex-direction: column;
        gap: 8px;
        border-top: 1px solid;
        padding: 8px 0px;
      `;
    } else {
      return `
        flex-direction: row;
        gap: 12px;
      `;
    }
  }}
`;

const StyledQuestionTitle = styled(Paragraph1)`
  padding: 12px 0;
  ${({ layout }) => {
    if (layout === "horizontal_scale") {
      return `
        width: 100%;
        text-align: center;
      `;
    } else {
      return `
        font-size: 18px;
        font-weight: 700;
      `;
    }
  }}
`;

export default Assessment;
