import { useEffect } from "react";
import authAxios from "../_utils/authAxios";

/*
  clickFrom: [string] denoting the page the user is coming from
  clickTo: [string] denoting the page the user has landed on
  refire: [string] a variable the useEffect function watches for changes to refire

  The {refire} variable is used in situations where the component technically changes its API calls
  but doesn't remount. This could occur when the course details page is already loaded but the 
  user clicks a different class in feeds. This will change the course ID in the URL but the
  course details page remains mounted. Passing in the ID as the {refire} variable will
  allow this hook to catch the change. It is not required, however.
*/

export const useLogNavigation = (
  clickFrom,
  clickTo,
  appName,
  appId,
  identifier,
  displayIndex
) => {
  useEffect(() => {
    if (clickFrom && clickTo) {
      authAxios.post("logs/navigation", {
        clickFrom,
        clickTo,
        appName,
        appId,
        identifier,
        displayIndex,
      });
    }
  }, [clickFrom, clickTo, appName, appId, identifier, displayIndex]);
};
