import moment from "moment";
import { utcDateToDisplay } from "./utcDate";

export function canStreamCourse(date, duration, offset = 10) {
  const now = new Date();
  const minutesOffset = new Date(moment(now).add(offset, "minutes").format());
  const offsetTimeDiff = moment(minutesOffset).diff(
    moment(utcDateToDisplay(date)).format(),
    "minutes"
  );
  const nowTimeDiff = moment(moment(now).format()).diff(
    moment(utcDateToDisplay(date)).format(),
    "minutes"
  );
  const isWithinFiveMinutes = offsetTimeDiff >= 0;
  const isClassOver = nowTimeDiff > duration;

  return isWithinFiveMinutes && !isClassOver;
}

export default canStreamCourse;
