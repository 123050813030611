import { useEffect, useState } from "react";
import useAuthAxios from "./useAuthAxios";

const useGetSubuserContacts = (
  page = 1,
  term = "",
  pageSize = 12,
  activeOnly = true
) => {
  const [contacts, setContacts] = useState([]);
  const [pageCount, setPageCount] = useState();
  const [loading, setLoading] = useState(true);

  const [
    { data, loading: loadingPayload },
  ] = useAuthAxios(
    `subusers/search?page=${page}&page_size=${pageSize}&term=${encodeURIComponent(
      term
    )}`,
    { useCache: false }
  );

  useEffect(() => {
    if (data && data?.items) {
      const cleanContacts = activeOnly
        ? data.items.reduce((acc, current) => {
            if (current?.status === "active") acc.push(current?.subuser);
            return acc;
          }, [])
        : data.items.map((contact) => contact?.subuser || contact);
      setContacts(cleanContacts);
      setPageCount(data?.meta?.page_count || 1);
    }
    setLoading(false);
  }, [data, activeOnly]);

  return { contacts, loading: loading || loadingPayload, pageCount };
};

export default useGetSubuserContacts;
