import { useEffect, useState } from "react";
import authAxios from "../_utils/authAxios";
import {
  coursePrivacyOptions,
  courseTypeOptions,
  courseHighlightOptions,
} from "../PSS/_constants";

const createLookup = (data, field) =>
  (data &&
    field in data &&
    data[field].reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.title }),
      {}
    )) ||
  [];

const useGetCourseDetails = (id, fields, pss) => {
  const [courseDetails, setCourseDetails] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id && fields) {
      authAxios({
        method: "get",
        url: `courses/view/${id}${pss ? "?pss=True" : ""}`,
      })
        .then((response) => {
          let cloned = response.data?.data;
          //  console.log(cloned)
          let categories = cloned?.categories?.map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
          }));

          let goalLookup = createLookup(fields, "goals");
          let goals = cloned?.goals?.map((item) => ({
            value: item,
            label: goalLookup[item],
          }));
          let level = {
            label: cloned?.level,
            value: cloned?.level,
          };
          // let languageoptions = cloned?.zones?.map((item) => ({
          //   ...item,
          //   label: item.title,
          //   value: item.id,
          // }));
          let languageoptions = {
            label: cloned?.languageoptions?.title,
            value: cloned?.languageoptions?.id,
          };
          let zones = cloned?.zones?.map((item) => ({
            ...item,
            label: item.title,
            value: item.id,
          }));
          let instructor = {
            label: `${cloned?.instructor.first_name || ""} ${
              cloned?.instructor.last_name || ""
            }`,
            value: cloned?.instructor.id,
          };
          let equipmentLookup = createLookup(fields, "equipment");

          let equipment = cloned?.equipment?.map((item) => ({
            value: item.id,
            label: item.title,
          }));
          let optionalEquipment = cloned?.optional_equipment?.map((item) => ({
            value: item,
            label: equipmentLookup[item],
          }));
          let orgLookup = createLookup(fields, "organization");
          let organizations = cloned?.organizations?.map((item) => ({
            value: item,
            label: orgLookup[item],
          }))[0];
          const privacy = coursePrivacyOptions.filter(
            (item) => item.value === cloned?.privacy
          )[0];
          const filteredType = courseTypeOptions.filter(
            (item) => item.value === cloned?.type
          );
          const type =
            cloned?.type === "live-video"
              ? { value: "live", label: "Live" }
              : filteredType?.length > 0
              ? filteredType[0]
              : courseTypeOptions[0];
          const highlight =
            courseHighlightOptions.filter(
              (item) => item.value === cloned?.highlight
            ) || courseHighlightOptions[0];
          setCourseDetails({
            ...cloned,
            categories,
            equipment,
            goals,
            level,
            languageoptions,
            zones,
            instructor,
            privacy,
            organizations,
            optional_equipment: optionalEquipment,
            highlight,
            type,
          });
        })
        .finally(() => setLoading(false));
    }
  }, [id, pss, fields]);

  return { courseDetails, loading };
};

export default useGetCourseDetails;
